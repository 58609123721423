import { makeStyles } from "@material-ui/core";

export const useLoginFormStyles = makeStyles((theme) => ({
  LoginForm: {
    width: "350px",
    display: "felx",
    felxDirection: "column",
    tesxtAlign: "left"
  },
  LoginForm__title: {
    fontSize: "2em",
    marginBlock: "24px"
  },
  LoginForm__input: {
    border: "none",
    marginBottom: "24px",
    width: "100%"
  },
  LoginForm__submit: {
    marginBottom: "15px",
    width: "100%"
  },
  LoginForm__loading: {
    color: "white",
    height: "23.75px !important",
    width: "23.75px !important"
  },
  LoginForm__register: {
    fontSize: "1em",
    marginTop: "20px"
  }
}));
