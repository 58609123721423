import React from "react";

import { Toolbar, Typography, Button, TextField } from "@material-ui/core";
import { useToolbarStyles } from "./useToolbarStyles";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";

import "./EnhancedTableToolbar.scss";

interface EnhancedTableToolbarProps {
  title: string;
  createid: () => void;
  createNew: () => void;
  setSearchValue: (value: string) => void;
  searchValue: string;
  filterByCategory?: React.ReactNode;
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();

  const handleSearchValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => {
    props.setSearchValue(event.target.value);
  };

  return (
    <div className="EnhancedTableToolbar">
      <Toolbar className={clsx(classes.root)}>
        <Typography variant="h6" id="tableTitle" component="div">
          {props.title}
        </Typography>
        <div className="EnhancedTableToolbar__list">
          {props.filterByCategory && props.filterByCategory}
          <TextField
            variant="outlined"
            color="primary"
            onChange={(e) => handleSearchValue(e, props.searchValue)}
            placeholder={`Search ${props.title.toLowerCase()}...`}
            InputProps={{ startAdornment: <SearchIcon color="primary" /> }}
          />
          {props.title !== "Prices" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.createNew();
                props.createid();
              }}
            >
              Create new
            </Button>
          )}
        </div>
      </Toolbar>
    </div>
  );
};
