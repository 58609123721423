import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { LoginFields } from "../LoginForm";

interface UseLoginFormOptions {
  initialValues?: LoginFields;
  onSubmit: (
    values: LoginFields,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>;
}

export const useLoginFormik = (options: UseLoginFormOptions) => {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("signin.invalidemail")
      .required("signin.emailrequired"),
    password: Yup.string()
      .min(6, "signin.minpassword")
      .required("signin.passwordrequired")
  });

  return useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type LoginFormik = ReturnType<typeof useLoginFormik>;
