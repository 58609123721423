import React, { useState } from "react";

import { SnackBar } from "components/SnackBar/SnackBar";

import { useMutation, useQuery } from "react-query";
import * as API from "../../../api/Api";
import {
  TaxResponse,
  Tax,
  TaxDelete,
  TaxUpdate,
  TaxCreate
} from "../../../api/Api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTaxFormik } from "components/Forms/Tax/lib/useTaxFormik";
import { taxColums } from "components/Forms/Tax/taxColums";
import { TaxForm } from "components/Forms/Tax/TaxForm";
import { useUIContext } from "lib/context/UIContext/UIContext";

import SaleTable from "components/SaleHistoryTable/SaleTable/SaleTable";
import LoadTable from "components/LoadingComponent/LoadTable";
export const Taxes = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState<Tax[]>();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);
  const [modalData, setModalData] = useState<Tax>();
  const [selectedModalType, setSelectedModalType] = useState("");
  const [search, setSearch] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<{
    rowName: string;
    rowId: string;
  }>({
    rowName: "",
    rowId: ""
  });

  const { data, isLoading, refetch, isFetching } = useQuery<TaxResponse, Error>(
    "tax",
    async () => {
      return await API.getAllTaxes();
    }
  );
  const editTax = useMutation((editTax: TaxUpdate) => API.updateTax(editTax));

  const deleteTax = useMutation((deleteTax: TaxDelete) =>
    API.deleteTax(deleteTax)
  );
  const createTax = useMutation((createTax: TaxCreate) =>
    API.createTax(createTax)
  );
  const getRow = (row: Tax[]) => {
    setRowData(row);
  };

  const createFormik = useTaxFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        createTax.mutate(
          {
            name: values.name,
            percentage: values.percentage
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch (e) {}
    }
  });

  const handleOnClick = (type: string, row: Tax) => {
    uiCtx.toggleModal();
    setSelectedModalType(type);
    setModalData(row);
  };

  const confirmModalProps = (
    closeModal: boolean,
    rowName: string,
    rowId: string
  ) => {
    setOpenConfirmModal(closeModal);
    setDeleteData({
      rowName,
      rowId
    });
  };
  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };
  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }

  const editFormik = useTaxFormik({
    initialValues: {
      name: editFormInitialValues[1],
      percentage: editFormInitialValues[3],
      id: editFormInitialValues[0]
    },
    onSubmit: async (values, formikHelper) => {
      try {
        editTax.mutate(
          {
            taxId: editFormInitialValues[0],
            name: values.name,
            percentage: values.percentage
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch {}
    }
  });

  const filteredCategoryData = data?.taxes?.map((tax: Tax) => {
    return {
      id: tax.id,
      name: tax.name,
      percentage: tax.percentage,

      actions: (
        <>
          <EditIcon onClick={() => handleOnClick("edit", tax)} />
          <DeleteIcon
            color="secondary"
            onClick={() => confirmModalProps(true, tax.name, tax.id)}
          />
        </>
      )
    };
  });
  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;

  return (
    <div className="Tax">
      {isLoading ? (
        <LoadTable />
      ) : (
        <>
          <SaleTable
            tableTitle="Tax"
            columns={taxColums}
            rows={filteredCategoryData}
            selectedRow={getRow}
            deleteMutation={deleteTax}
            setValue={setSearch}
            editForm={
              <TaxForm
                title="Edit Tax"
                formik={editFormik}
                serverError={serverError}
              />
            }
            createForm={
              <TaxForm
                title="Create Tax"
                formik={createFormik}
                serverError={serverError}
              />
            }
            createFormik={createFormik}
            editFormik={editFormik}
            isSaving={createTax.isLoading || editTax.isLoading}
            refetchTable={refetch}
            page={page}
            isLastPage={!data?.hasMore}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPage={handleRowsPerPage}
            isFetching={isFetching}
            selectType={selectedModalType}
            setSelectType={setSelectedModalType}
            rowData={modalData}
            openConfirmModal={openConfirmModal}
            deleteData={deleteData}
            handleCloseModal={handleCloseModal}
          />
          <SnackBar
            openSnackBar={openSnackBar}
            closeSnackBar={() => setOpenSnackBar(false)}
            snackBarMessage="Your changes have been successfully saved"
            snackBarType="success"
          />
        </>
      )}
    </div>
    // <div className="Products">
    //   {isLoading ? (
    //     "Loading..."
    //   ) : (
    //     <>
    //       <EnhancedTable
    //         tableTitle="Products"
    //         columns={taxColums}
    //         rows={filteredCategoryData}
    //         selectedRow={getRow}
    //         setValue={setSearch}
    //         editForm={
    //           <TaxForm
    //             title="Edit Sale"
    //             formik={editFormik}
    //             serverError={serverError}
    //           />
    //         }
    //         createForm={
    //           <TaxForm
    //             title="Create Sale"
    //             formik={createFormik}
    //             serverError={serverError}
    //           />
    //         }
    //         createFormik={createFormik}
    //         editFormik={editFormik}
    //         isSaving={createTax.isLoading || editTax.isLoading}
    //         refetchTable={refetch}
    //         page={page}
    //         isLastPage={!data?.hasMore}
    //         handlePreviousPage={handlePreviousPage}
    //         handleNextPage={handleNextPage}
    //         handleRowsPerPage={handleRowsPerPage}
    //         isFetching={isFetching}
    //       />
    //       <SnackBar
    //         openSnackBar={openSnackBar}
    //         closeSnackBar={() => setOpenSnackBar(false)}
    //         snackBarMessage="Your changes have been successfully saved"
    //         snackBarType="success"
    //       />
    //     </>
    //   )}
    // </div>
  );
};
