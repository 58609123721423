import { apiRequest } from "./Api";

export interface Customers {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  address: string;
  discount: string;
  is_business: boolean;
  business_number: string;
  tax_number: string;
  apartament: string;
  city: string;
  state: string;
  region: string;
  postcode: string;
  active: boolean;
  optional_notes: string;
}

export interface CustomerMutate {
  buyer_id?: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  address: string;
  discount: string;
  is_business: boolean;
  business_number: string;
  tax_number: string;
}

export interface CustomersDelete {
  id: string;
}

export interface CustomersResponse {
  hasMore: boolean;
  buyers: Customers[];
}

interface CustomersParams {
  page: number;
  rows: number;
}
interface CustomerssParams {
  name?: string;
}

export const getAllCustomers = async (data: CustomersParams) =>
  apiRequest<undefined, CustomersResponse>(
    "get",
    `api/buyer/all?page=${data.page}&rows=${data.rows}`
  );

  export const getAllCustomerss = async (data: CustomerssParams) =>
  apiRequest<undefined, CustomersResponse>(
    "get",
    `api/buyer/all${data.name && `?first_name=${data.name}`}`
  );

export const createCustomer = async (data: CustomerMutate) =>
  apiRequest<CustomerMutate, CustomersResponse>(
    "post",
    "api/buyer/register",
    data
  );

export const editCustomer = async (data: CustomerMutate) =>
  apiRequest<CustomerMutate, CustomersResponse>(
    "put",
    "api/buyer/update",
    data
  );

export const deleteCustomers = async (data: CustomersDelete) =>
  apiRequest<CustomersDelete, CustomersResponse>(
    "delete",
    `api/buyer/delete?buyer_id=${data.id}`
  );
