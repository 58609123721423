export const salesHistoryColumns = [
  {
    key: "created_at",
    header: "DateDocument date"
  },
  {
    key: "client",
    header: "Client"
  },
  {
    key: "payment_type",
    header: "Payment Type"
  },
  {
    key: "generated_sale_id",
    header: "Document number"
  },
  {
    key: "total",
    header: "Total"
  },
  {
    key: "status",
    header: "Status"
  },
  {
    key: "actions",
    header: "Actions"
  }
];
