import { SaleItem } from "api/SalesHistory";
import React, { useContext } from "react";

export interface SaleContextType {
  checkedRefund: SaleItem[];

  refundEditQuantity: (item: SaleItem, quantity: string) => void;
  handleCheckRefund: (item: SaleItem) => void;
}

const SaleContextValues: SaleContextType = {
  checkedRefund: [],
  refundEditQuantity: () => {},
  handleCheckRefund: () => {}
};

export const SaleContext =
  React.createContext<SaleContextType>(SaleContextValues);

export const useSaleContext = () => useContext(SaleContext);
