import { apiRequest } from "./Api";

export interface SalesHistory {
  sale_id: string;
  store_name: string;
  store_id: string;
  cash_register_name: string;
  cashier_first_name: string;
  cashier_last_name: string;
  client_first_name: string;
  client_last_name: string;
  created_at: string;
  generated_sale_id: string;
  payment_name: string;
  amount_total: string;
  status: string;
  saleData: SaleItem[];
}
export interface SalesHistoryResponse {
  allSales: {
    hasMore: boolean;
    sale: SalesHistory[];
  };
}

export interface SaleItem {
  amount_tax_per_product: string;
  amount_total_per_product: string;
  amount_untaxed_per_product: string;
  price: string;
  product_name: string;
  product_unit_discount: string;
  unit_name: string;
  sku_barcode: string;
  quantity: string;
  max_quantity?: string;
  unit_id: string;
  index?: number;
}
export interface SalesHistoryParams {
  store_id?: string;
  sale_id?: string;
  page?: number;
  search?: string;
  rows?: number;
  starting_date?: string;
  ending_date?: string;
}
export interface PrintSale {
  sale_id: string;
}
export interface PrintSaleResponse {
  raport: { url: string };
}

export interface SaleProduct {
  sku_barcode: string;
  quantity: string;
  unit_id: string;
  returned: boolean;
}

export interface exChangeSalesHistory {
  buyer_id?: string;
  store_id?: string;
  cash_register_id?: string;
  payment_id?: string;
  discount?: number;
  sale_id?: string;
  saleData: SaleProduct[] | undefined;
}
// export interface SalesHistoryEdit {
//   sale_id: string;
//   buyer_id: string;
//   store_id?: string;
//   cash_register_id: string;
//   payment_id?: string;
//   discount: string;
//   saleData: SaleData[] | undefined;
// }

// export interface SalesHistoryDelete {
//   id: string;
// }
export const getAllSalesHistory = async (data: SalesHistoryParams) => {
  return apiRequest<undefined, SalesHistoryResponse>(
    "get",
    `api/sale/all?store_id=${data.store_id}&page=${data.page}&rows=${data.rows}&search=${data.search}&starting_date=${data.starting_date}&ending_date=${data.ending_date}`
  );
};

export const getSaleById = async (data: SalesHistoryParams) =>
  apiRequest<undefined, SalesHistoryResponse>(
    "get",
    `api/sale/all?store_id=${data.store_id}&sale_id=${data.sale_id}`
  );
export const exChange = async (data: exChangeSalesHistory) =>
  apiRequest<exChangeSalesHistory, SalesHistoryParams>(
    "post",
    `api/sale/exchange?sale_id=${data.sale_id}`,
    data
  );
// export const editSaleById = async (data: SalesHistoryEdit) =>
//   apiRequest<SalesHistoryEdit, SalesHistoryParams>(
//     "put",
//     "api/sale/update",
//     data
//   );

// export const deleteSalesHistory = async (data: SalesHistoryDelete) =>
//   apiRequest<SalesHistoryDelete, SalesHistoryResponse>(
//     "delete",
//     `api/sale/delete?sale_id=${data.id}`
//   );
export const printSale = async (data: PrintSale) =>
  apiRequest<undefined, PrintSaleResponse>(
    "get",
    `api/sale/sale-pdf?sale_id=${data.sale_id}`
  );
