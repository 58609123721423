import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { useQueryParameters } from "lib/hooks/useQueryParameters";
import { useRouter } from "lib/hooks/useRouter";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import * as API from "../../api/Api";
import { Store, StoreResponse } from "../../api/Api";
import "./FilterByDate.scss";
import qs from "query-string";
interface FilterByStoreProps {
  onChange?: (e: any) => void;
  setStore?: (value: string) => void;
  className?: string;
}

export const FilterByStore = (props: FilterByStoreProps) => {
  const [age, setAge] = React.useState("");
  const router = useRouter();
  const { getUrlWithQueryParams } = useQueryParameters();
  const stores = useQuery<StoreResponse, Error>("stores", async () => {
    return await API.allStores();
  });
  useEffect(() => {
    const queryParams = qs.parse(router.location.search) as { store: string };
    props.setStore?.(queryParams.store);
    if (queryParams.store && age === "") {
      setAge(queryParams.store);
    }
  }, []);

  const handleOnChange = (e: any) => {
    setAge(e.target.value);
    const urlParams = getUrlWithQueryParams("store", e.target.value);
    router.history.push(`${urlParams}`);
    props.setStore?.(e.target.value);
  };

  return (
    <>
      <FormControl className={props.className}>
        <Select
          value={age}
          onChange={handleOnChange}
          variant="outlined"
          id="store_id"
          name="store_id"
          displayEmpty
          MenuProps={{
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                marginTop: 50
              }
            }
          }}
        >
          <MenuItem value="">All Store</MenuItem>
          {stores.data?.stores?.map((store: Store) => (
            <MenuItem key={store.store_id} value={store.store_id}>
              {store.store_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
