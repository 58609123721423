export const teamRaportColumns = [
  {
    key: "first_name",
    header: "Member"
  },

  {
    key: "amount_total",
    header: "Sales"
  }
];
