import React from "react";

import { Grid, TextField } from "@material-ui/core";
import { Input } from "components/shared/Input/Input";
import { useCategoryFormStyles } from "./useCategoryFormStyles";

import { useQuery } from "react-query";
import * as API from "../../../api/Api";
import { Category, CategoryResponse } from "api/Categories";

interface CategoryFormProps {
  title: string;
  formik: any;
  serverError: string;
}

export interface CategoryFields {
  name: string;
  slug: string;
  parent_id: any;
}

export const CategoryForm = (props: CategoryFormProps) => {
  const classes = useCategoryFormStyles();

  const categories = useQuery<CategoryResponse, Error>(
    "categories",
    async () => {
      return await API.getAllCategories({ page: 1, rows: 20 });
    }
  );
 
  return (
    <div className={classes.CategoryForm}>
      <h1 className={classes.title}>{props.title}</h1>
      <form
        onSubmit={props.formik.handleSubmit}
        className={classes.CategoryForm__form}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              className="CategoryForm__nameInput"
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.name}
              error={props.formik.touched.name && !!props.formik.errors.name}
              helperText={props.formik.touched.name && props.formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.SelectInput}
              select
              id="parent_id"
              name="parent_id"
              label="Parent category"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.parent_id || ""}
              error={
                props.formik.touched.parent_id &&
                !!props.formik.errors.parent_id
              }
              helperText={
                props.formik.touched.parent_id && props.formik.errors.parent_id
              }
              SelectProps={{
                native: true
              }}
              InputLabelProps={{ shrink: true }}
            >
              <option>none</option>

              {categories.data?.categories?.map((category: Category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} className={classes.ServerError}>
            {props.serverError}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
