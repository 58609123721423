import { makeStyles } from "@material-ui/core";

export const useEmployeeFormStyles = makeStyles({
  EmployeeForm: {
    width: "25vw"
  },
  EmployeeForm__form: {
    width: "80%",
    margin: "auto"
  },
  title: {
    fontSize: "23px",
    margin: "0 auto 30px",
    width: "fit-content"
  },
  EmployeeForm__selectInput: {
    width: "100%"
  },
  EmployeeForm__storeInput: {
    width: "100%",
    marginTop: "25px",
    marginBottom: "20px"
  },
  ServerError: {
    color: "red"
  }
});
