import React from "react";

import "./SaleTableBody.scss";

import {
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel
} from "@material-ui/core";

interface SaleTableBodyProps {
  rows: TableRow[];
  columns: TableColumn[];
  tableOrder: string;
  tableOrderBy: string;
  onRequestSort: (property: string) => void;
  dynamicInput?: Array<number>;
  editFormik: any;
}

export interface TableColumn {
  key: string;
  header: string;
}
export interface TableRow {
  [key: string]: any;
}

function stableSort(array: TableRow[], comparator: any) {
  const stabilizedThis = array?.map((el: any, index: number) => [el, index]);
  stabilizedThis !== undefined &&
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis?.map((el: TableRow[]) => el[0]);
}

function descendingComparator(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return 10;
  }
  if (b[orderBy] > a[orderBy]) {
    return 12;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: {}, b: {}) => descendingComparator(a, b, orderBy)
    : (a: {}, b: {}) => -descendingComparator(a, b, orderBy);
}

export const SaleTableBody = (props: SaleTableBodyProps) => {
  const { columns, onRequestSort, tableOrder, tableOrderBy } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(property);
  };
  return (
    <>
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={`Table-${index}-${column.key}`} align="left">
              <TableSortLabel
                active={tableOrderBy === column.key}
                direction={
                  tableOrderBy === column.key
                    ? tableOrder !== "asc"
                      ? "desc"
                      : "asc"
                    : "asc"
                }
                onClick={createSortHandler(column.key)}
              >
                {column.header}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {stableSort(props.rows, getComparator(tableOrder, tableOrderBy))?.map(
          (row: TableRow, index: number) => {
            return (
              <TableRow
                // onClick={() => {
                //   props.onRowClick && props.onRowClick("edit", row);
                // }}
                tabIndex={-1}
                key={index}
                className={"SaleTableBody__table_row"}
              >
                {props.columns.map((rowColumn, idx) => {
                  return (
                    <TableCell key={idx}>{row[rowColumn["key"]]}</TableCell>
                  );
                })}
              </TableRow>
            );
          }
        )}
      </TableBody>
    </>
  );
};
