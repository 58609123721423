import { apiRequest } from "./Api";

export interface Payment {
  id: string;
  name: string;
  type: string;
}

export interface PaymentResponse {
  hasMore: boolean;
  payments: Payment[];
}

export interface PaymentDelete {
  id: string;
}
export interface PaymentCreate {
  name: string;
  type: string;
}
export interface PaymentUpdate {
  paymentId: string;
  name: string;
  type: string;
}
export const getAllPayments = async () =>
  apiRequest<undefined, PaymentResponse>("get", "api/admin/payment/all");

export const deletePayment = async (data: PaymentDelete) =>
  apiRequest<PaymentDelete, PaymentResponse>(
    "delete",
    `api/admin/payment/delete?paymentId=${data.id}`,
    data
  );
export const createPayment = async (data: PaymentCreate) =>
  apiRequest<PaymentCreate, PaymentResponse>(
    "post",
    `api/admin/payment/create`,
    data
  );

export const updatePayment = async (data: PaymentUpdate) =>
  apiRequest<PaymentUpdate, PaymentResponse>(
    "put",
    "api/admin/payment/update",
    data
  );
