import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { useLanguageContext } from "lib/context/LanguageContext/LanguageContext";

import { LanguageSwitcher } from "components/LanguageSwitcher/LanguageSwitcher";

import { Toolbar, Button, Menu, MenuItem, Avatar } from "@material-ui/core";

//styles
import "./Header.scss";

export const Header = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="Header">
      <Toolbar>
        <div className="Header__menu">
          <LanguageSwitcher />
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Avatar src="/broken-image.jpg" />
            <h4>
              {authContext.user?.first_name} {authContext.user?.last_name}
            </h4>
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              component={NavLink}
              to={`${langPrefix}/my-profile`}
            >
              {t("profile.myProfile")}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              {t("profile.myPreference")}
            </MenuItem>
            <MenuItem onClick={authContext.logout}>
              {t("profile.logout")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </div>
  );
};
