export const taxColums = [
  {
    key: "name",
    header: "Name"
  },
  {
    key: "percentage",
    header: "Percentage"
  },
  {
    key: "actions",
    header: "Actions"
  }
];
