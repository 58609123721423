import { makeStyles } from "@material-ui/core";

export const useTableModalStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.1) !important"
  },
  elevation16: {
    boxShadow: "none !important",
    width: "fit-content",
    display: "flex",
    flexDirection: "column"
  },
  TableModal__Header: {
    "& .TableModal__close": {
      // float: "right",
      // margin: "15px 20px 0 0",
      position: "absolute",
      right: "0",

      "&:hover": {
        opacity: "0.5"
      }
    }
  },

  TableModal__Footer: {
    width: "85%",
    margin: "auto auto 25px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    bottom: "0",
    "& .TableModal__Footer_Save": {
      width: "200px",
      marginRight: "10px"
    },
    "& .TableModal__Footer_Cancel": {
      width: "200px",
      marginLeft: "10px"
    }
  }
}));
