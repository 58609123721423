import React, { useContext } from "react";

const defaultContext = {
  isOpen: false,
  confirm: () => Promise // tslint:disable-line: no-empty
  // confirm: (text?: string) => Promise,
};
export interface ConfirmationContextType {
  isOpen: boolean;
  confirm: (text?: string) => any;
}

export const ConfirmationContext = React.createContext<ConfirmationContextType>(
  defaultContext
);

export function useConfirmation() {
  return useContext(ConfirmationContext);
}
