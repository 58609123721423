import { apiRequest } from "./Api";

export interface Product {
  id: string;
  product_name: string;
  sku_barcode: string;
  category_name: string;
  retail_price: string;
  supply_price: string;
  description: string;
  media: string;
}

export interface ProductCreate {
  name: string;
  tax_id: string;
  sku_barcode: string;
  category_id: string;
  retail_price: string;
  supply_price: string;
  image: any;
  unitData: {
    unit_id: string;
    discount: string;
  };

  stores: Array<{ store_id: string }>;
  use_price: boolean;
  variations: string;
  sku_code: string;
  description: string;
}

export interface ProductUpdate {
  product_id: string;
  name: string;
  tax_id: string;
  sku_barcode: string;
  category_id: string;
  unitData: {
    unit_id: string;
    discount: string;
  };

  retail_price: string;
  supply_price: string;
  variations: string;
  sku_code: string;
  description: string;
}

export interface ProductDelete {
  id: string;
}

export interface Search {
  name?: string;
}

export interface ProductResponse {
  hasMore: boolean;
  products: Product[];
}

interface ProductsParams {
  page: number;
  rows: number;
  search: string;
}

export const getAllProducts = async (data: ProductsParams) =>
  apiRequest<undefined, ProductResponse>(
    "get",
    `api/admin/product/all?name=${data.search}&page=${data.page}&rows=${data.rows}`
  );

export const getAllProductsPerStore = async (data: Search) =>
  apiRequest<undefined, Product[]>(
    "get",
    `api/admin/product/all-product-store?${
      data.name && `search=${data.name}&`
    }store_id=28f68fef-868b-47f3-87aa-be8b1f7c3655`
  );

export const createProduct = async (data: ProductCreate) =>
  apiRequest<ProductCreate, ProductResponse>(
    "post",
    "api/admin/product/create",
    data
  );

export const updateProduct = async (data: ProductUpdate) =>
  apiRequest<ProductUpdate, ProductResponse>(
    "put",
    "api/admin/product/update",
    data
  );

export const deleteProduct = async (data: ProductDelete) =>
  apiRequest<ProductDelete, ProductResponse>(
    "delete",
    `api/admin/product/delete?product_id=${data.id}`,
    data
  );
