import { Result } from "api/Dashboard";
import LoadPieChart from "components/LoadingComponent/LoadPieChart";
import { NoDataMessage } from "components/SaleHistoryTable/NoDataMessage";
import React, { useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend
} from "recharts";

interface ProductTypeChart {
  raportResult?: Result[] | undefined;
  setTypes?: (value: string) => void;
  loading: boolean;
}

const COLORS: any = [
  // "#0D193F",
  // "#926EB3",
  // "#19254B",
  // "#1F2B50",
  // "#A98FC3",
  // "#2C3658",
  // "#662D91",
  // "#8B94A8",
  // "#BFB0D5",
  // "#808FB2",
  // "#707991",
  // "#58688C",
  // "#1C1C4E",
  // "#A1A9B9"
];
let count = 0;
const colorTotal = 100;
function rand(min: any, max: any) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
while (count < colorTotal) {
  COLORS.push("hsl(" + 210 + ", " + rand(10, 90) + "%, " + rand(10, 90) + "%)");
  count++;
}

export const ProductTypeChart = (props: ProductTypeChart) => {
  const { setTypes } = props;
  useEffect(() => {
    setTypes?.("products");
  }, []);

  if (props.loading) return <LoadPieChart />;
  if (!props.raportResult) {
    return <NoDataMessage message="ERROR" />;
  }
  if (props.raportResult?.length === 0) {
    return <NoDataMessage message="Nuk ka shitje" />;
  }
  return (
    <ResponsiveContainer width="100%" height={600}>
      <PieChart margin={{ bottom: 30 }}>
        <Pie
          isAnimationActive={false}
          data={props.raportResult}
          cx="50%"
          cy="50%"
          innerRadius={140}
          outerRadius={180}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="sum"
          label={(value) => "€" + value.sum}
        >
          {props.raportResult.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
