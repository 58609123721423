import { makeStyles } from "@material-ui/core";

export const useTaxStyle = makeStyles((theme) => ({
  Tax: {
    width: "25vw"
  },
  Tax__form: {
    width: "80%",
    margin: "auto"
  },
  Tax__title: {
    fontSize: "23px",
    margin: "0 auto 30px",
    width: "fit-content"
  },
  ServerError: {
    color: "red"
  }
}));
