import React from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { Input } from "components/shared/Input/Input";
import { usePaymentsTypeFormStyle } from "./usePaymentsTypeFormStyle";

interface PaymentTypeProps {
  title: string;
  formik: any;
  serverError: string;
}
export interface PaymentFields {
  id: string;
  name: string;
  type: string;
}
export const PaymentTypeForm = (props: PaymentTypeProps) => {
  const classes = usePaymentsTypeFormStyle();
  return (
    <div className={classes.PaymentType}>
      <h1 className={classes.title}>{props.title}</h1>

      <form className={classes.PaymentType__form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              className="PaymentType__nameInput"
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.name}
              error={props.formik.touched.name && !!props.formik.errors.name}
              helperText={props.formik.touched.name && props.formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="PaymentType__typeInput"
              id="type"
              name="type"
              label="Type"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.type}
              error={props.formik.touched.type && !!props.formik.errors.type}
              helperText={props.formik.touched.type && props.formik.errors.type}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
