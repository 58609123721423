import { Typography } from "@material-ui/core";
import { FilterByDate } from "components/Filters/FilterByDate";
import { Tab, TabPanel } from "components/shared/Tabs/TabPanel";
import { Tabs } from "components/shared/Tabs/Tabs";
import { SaleChart } from "pages/Charts/SaleChart";
import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import "./useDashboardStyle.ts";
import { useDashBoardStyles } from "./useDashboardStyle";
import { DashboardCart } from "./DashboardCart";
import { ProductTypeChart } from "pages/Charts/ProductTypeChart";
import { DaysChart } from "pages/Charts/DaysChart";
import { TimeChart } from "pages/Charts/TimeChart";
import { useQuery } from "react-query";
import { RapportResponse } from "../../api/Api";
import { FilterByStore } from "components/Filters/FilterByStore";
import qs from "query-string";
import { useRouter } from "lib/hooks/useRouter";
import { useQueryParameters } from "lib/hooks/useQueryParameters";
import { TeamRaport } from "pages/Charts/TeamRaport/TeamRaport";
import startOfDay from "date-fns/startOfDay";
import { addDays, endOfDay } from "date-fns";

export const Dashboard = () => {
  const [starting_date, setStarting_date] = useState("");
  const [ending_date, setEnding_date] = useState("");
  const classes = useDashBoardStyles();
  const [type, setType] = useState("sales");
  const [store_id, setStore_id] = useState("");
  const { getUrlWithQueryParams } = useQueryParameters();
  const startDay = startOfDay(addDays(new Date(), 0))
    .toLocaleString("en-GB")
    .replace(",", "");
  const endDay = endOfDay(new Date()).toLocaleString("en-GB").replace(",", "");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);

  const { data, isLoading } = useQuery<RapportResponse>(
    ["rapport", starting_date, ending_date, type, store_id, page, rows],
    async () => {
      return await API.getSaleRapport({
        store_id: store_id || "",
        type: type,
        page: page,
        rows: rows,
        starting_date: starting_date || startDay,
        ending_date: ending_date || endDay
      });
    }
  );
  const raportResult = data?.raport?.results?.sales?.sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
  const hasMore = data?.raport?.results.hasMore;
  const sortTime = data?.raport?.results?.sales.sort((a, b): number => {
    return a.date_part - b.date_part;
  });

  const positiveValue = data?.raport?.results?.sales.filter(
    (value) => value.sum > 0
  );
  const kpiResult = data?.raport?.kpi;
  const newCostumers = data?.raport.newCostumers.count;
  const router = useRouter();
  useEffect(() => {
    const params = getUrlWithQueryParams({
      start: starting_date,
      end: ending_date
    });
    router.history.push(`${params}`);
  }, [starting_date, ending_date]);

  const queryParams = qs.parse(router.location.search) as {
    date: string;
    end: string;
    start: string;
  };

  useEffect(() => {
    setStarting_date(queryParams.start);
    setEnding_date(queryParams.end);
  }, []);
  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };
  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <div className="Dashboard">
      <div className={classes.Dashboard_Info}>
        <Typography variant="h6" className={classes.Dashboard_h6}>
          Dashboard
        </Typography>
        <div className={classes.Dashboard__filter}>
          <FilterByDate
            className="Form-control"
            setStartDate={(value: string) => setStarting_date(value)}
            setEndDate={(value: string) => setEnding_date(value)}
          />
          <FilterByStore
            className="Form-control"
            setStore={(value: string) => setStore_id(value)}
          />
        </div>
      </div>
      <div>
        <DashboardCart
          kpi={kpiResult}
          newCostumers={newCostumers}
          loading={isLoading}
        />
      </div>
      <div className={classes.Dashboard_Chart}>
        <Tabs>
          <Tab id="Sale">Sale</Tab>
          <Tab id="ProductTypes">Product Types</Tab>
          <Tab id="Days">Days</Tab>
          <Tab id="Time">Time</Tab>
          <Tab id="Team">Team</Tab>
          <TabPanel id="Sale">
            <SaleChart
              raportResult={raportResult}
              setTypes={(value: string) => setType(value)}
              loading={isLoading}
            />
          </TabPanel>
          <TabPanel id="ProductTypes">
            <ProductTypeChart
              raportResult={positiveValue}
              setTypes={(value: string) => setType(value)}
              loading={isLoading}
            />
          </TabPanel>
          <TabPanel id="Days">
            <DaysChart
              raportResult={positiveValue}
              setTypes={(value: string) => setType(value)}
              loading={isLoading}
            />
          </TabPanel>
          <TabPanel id="Time">
            <TimeChart
              raportResult={sortTime}
              setTypes={(value: string) => setType(value)}
              loading={isLoading}
            />
          </TabPanel>
          <TabPanel id="Team">
            <TeamRaport
              raportResult={raportResult}
              setTypes={(value: string) => setType(value)}
              page={page}
              handleRowsPerPage={handleRowsPerPage}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
              hasMore={hasMore}
              loading={isLoading}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
