import React, { useState } from "react";

import { useUIContext } from "lib/context/UIContext/UIContext";

import {
  QueryObserverResult,
  RefetchOptions,
  UseMutationResult
} from "react-query";
import { debounce } from "lodash";
import { TableModal } from "components/shared/Modal/TableModal/TableModal";
import { SaleTableBody, TableColumn } from "../SaleTableBody/SaleTableBody";
import { SaleTableToolbar } from "../SaleTableToolbar/SaleTableToolbar";
import { Paper, TableContainer, Table } from "@material-ui/core";
import "./SaleTable.scss";
import { NoDataMessage } from "../NoDataMessage";
import { SaleTableFooter } from "../SaleTableFooter/SaleTableFooter";
import { useSaleTableStyles } from "./useSaleTableStyles";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

interface RowProps {
  sale_id?: string;
  generated_sale_id?: string;
  created_at?: string;
  client?: string;
  payment_type?: string;
  amount_total?: string;
  status?: string;
  product_id?: string;
  product_name?: string;
  sku_barcode?: string;
  unit?: string;
  store?: string | undefined;
  supply_price?: string;
  mark_up?: string;
  untaxed_retail_price?: string;
  tax_rate?: string;
  retail_price?: string;
  category_id?: string;
  name?: string;
  slug?: string;
  parent_id?: any;
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  role_name?: string;
  store_name?: string;
  pos_pin?: string;
  left_job?: string;
}

interface SaleTableProps {
  rows: RowProps[] | undefined;
  columns: TableColumn[];
  editFormik?: any;
  createFormik?: any;
  selectedRow?: any;
  tableTitle: string;
  editForm?: React.ReactNode;
  createForm?: React.ReactNode;
  isSaving?: boolean;
  setValue?: any;
  refetchTable?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<any, Error>>;
  deleteMutation?: UseMutationResult<any, unknown, any, unknown>;
  handlePreviousPage?: () => void;
  handleNextPage?: () => void;
  handleRowsPerPage?: (e: any) => void;
  page?: number;
  isLastPage?: boolean;
  isFetching?: boolean;
  dynamicInput?: any;
  filterByCategory?: React.ReactNode;
  selectType?: string;
  setSelectType?: React.Dispatch<React.SetStateAction<string>>;
  rowData?: any;
  openConfirmModal?: boolean;
  deleteData?: { rowName: string; rowId: string };
  handleCloseModal?: () => void;
  showToolBar?: boolean | undefined;
  displayCreate?: boolean | undefined;
}

export default function SaleTable(props: SaleTableProps) {
  const uiCtx = useUIContext();
  const classes = useSaleTableStyles();
  const [tableOrder, setTableOrder] = useState("asc");
  const [tableOrderBy, setTableOrderBy] = useState("");

  const [searchValue, setSearchValue] = useState("");

  const handleRequestSort = (property: string) => {
    const isAsc = tableOrderBy === property && tableOrder === "asc";
    setTableOrder(isAsc ? "desc" : "asc");
    setTableOrderBy(property);
  };

  const handleClose = () => {
    uiCtx.toggleModal();
  };

  const handleCancel = () => {
    uiCtx.toggleModal();
    props?.createFormik?.resetForm();
    props.editFormik.resetForm();
  };
  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    const deb = debounce(() => props.setValue(value), 500);
    deb();
  };

  return (
    <>
      <div className="Sale_Table">
        <div className={classes.root}>
          <Paper>
            {props.rows !== undefined ? (
              <>
                <SaleTableToolbar
                  title={props.tableTitle}
                  createNew={() => uiCtx.toggleModal()}
                  createid={() =>
                    props.setSelectType && props.setSelectType("create")
                  }
                  setSearchValue={handleSearchValue}
                  searchValue={searchValue}
                  filterByCategory={props.filterByCategory}
                  showToolBar={props.showToolBar}
                  displayCreate={props.displayCreate}
                />
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    aria-label="Sale table"
                    onClick={props.selectedRow(props.rowData)}
                  >
                    <SaleTableBody
                      rows={props.rows}
                      columns={props.columns}
                      onRequestSort={handleRequestSort}
                      tableOrder={tableOrder}
                      tableOrderBy={tableOrderBy}
                      dynamicInput={props.dynamicInput}
                      editFormik={props.editFormik}
                    />
                  </Table>
                </TableContainer>
                <SaleTableFooter
                  page={props.page}
                  isFetching={props.isFetching}
                  isLastPage={props.isLastPage}
                  handleNextPage={props.handleNextPage}
                  handleRowsPerPage={props.handleRowsPerPage}
                  handlePreviousPage={props.handlePreviousPage}
                />
              </>
            ) : (
              <NoDataMessage
                message={`Nuk ka te dhena per ${props.tableTitle}`}
                buttonMessage={`Create ${props.tableTitle}`}
              />
            )}
          </Paper>
          <TableModal
            open={uiCtx.isModalOpen}
            close={handleClose}
            cancel={handleCancel}
            handleSubmit={() => {
              props.selectType === "create"
                ? props.createFormik.handleSubmit()
                : props.editFormik.handleSubmit();
            }}
            isSaving={props.isSaving}
          >
            {props.selectType === "create" ? props.createForm : props.editForm}
          </TableModal>
          <ConfirmModal
            deleteMutation={props.deleteMutation}
            open={props.openConfirmModal}
            close={() => {
              if (props.handleCloseModal) {
                props.handleCloseModal();
              }
            }}
            deleteData={props.deleteData}
            refetchTable={props.refetchTable}
          />
        </div>
      </div>
    </>
  );
}
