import React, { useState } from "react";

import { SnackBar } from "components/SnackBar/SnackBar";

import { useMutation, useQuery } from "react-query";
import * as API from "../../../api/Api";
import {
  Payment,
  PaymentResponse,
  PaymentUpdate,
  PaymentCreate,
  PaymentDelete
} from "../../../api/Api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { usePaymentFormik } from "components/Forms/PaymentType/lib/usePaymentFormik";
import { paymentColums } from "components/Forms/PaymentType/paymentColums";
import { useUIContext } from "lib/context/UIContext/UIContext";
import SaleTable from "components/SaleHistoryTable/SaleTable/SaleTable";
import { PaymentTypeForm } from "components/Forms/PaymentType/PaymentTypeForm";
import LoadTable from "components/LoadingComponent/LoadTable";
export const PaymentType = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState<Payment[]>();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);
  const [modalData, setModalData] = useState<Payment>();
  const [selectedModalType, setSelectedModalType] = useState("");
  const [search, setSearch] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<{
    rowName: string;
    rowId: string;
  }>({
    rowName: "",
    rowId: ""
  });

  const { data, isLoading, refetch, isFetching } = useQuery<
    PaymentResponse,
    Error
  >("payment", async () => {
    return await API.getAllPayments();
  });

  const editPayment = useMutation((editPayment: PaymentUpdate) =>
    API.updatePayment(editPayment)
  );

  const deletePayment = useMutation((deletePayment: PaymentDelete) =>
    API.deletePayment(deletePayment)
  );
  const createPayment = useMutation((createPayment: PaymentCreate) =>
    API.createPayment(createPayment)
  );
  const getRow = (row: Payment[]) => {
    setRowData(row);
  };

  const createFormik = usePaymentFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        createPayment.mutate(
          {
            name: values.name,
            type: values.type
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch (e) {}
    }
  });

  const handleOnClick = (type: string, row: Payment) => {
    uiCtx.toggleModal();
    setSelectedModalType(type);
    setModalData(row);
  };

  const confirmModalProps = (
    closeModal: boolean,
    rowName: string,
    rowId: string
  ) => {
    setOpenConfirmModal(closeModal);
    setDeleteData({
      rowName,
      rowId
    });
  };
  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };
  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }
  const editFormik = usePaymentFormik({
    initialValues: {
      name: editFormInitialValues[1],
      type: editFormInitialValues[2],
      id: editFormInitialValues[0]
    },
    onSubmit: async (values, formikHelper) => {
      try {
        editPayment.mutate(
          {
            paymentId: editFormInitialValues[0],
            name: values.name,
            type: values.type
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch {}
    }
  });

  const filteredCategoryData = data?.payments?.map((payment: Payment) => {
    return {
      id: payment.id,
      name: payment.name,
      type: payment.type,

      actions: (
        <>
          <EditIcon onClick={() => handleOnClick("edit", payment)} />

          <DeleteIcon
            color="secondary"
            onClick={() => confirmModalProps(true, payment.name, payment.id)}
          />
        </>
      )
    };
  });
  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;
  return (
    <div className="Category">
      <>
        <SaleTable
          tableTitle="Payment Type"
          columns={paymentColums}
          rows={filteredCategoryData}
          selectedRow={getRow}
          deleteMutation={deletePayment}
          setValue={setSearch}
          editForm={
            <PaymentTypeForm
              title="edit Payment"
              formik={editFormik}
              serverError={serverError}
            />
          }
          createForm={
            <PaymentTypeForm
              title="Create Payment"
              formik={createFormik}
              serverError={serverError}
            />
          }
          createFormik={createFormik}
          editFormik={editFormik}
          isSaving={createPayment.isLoading || editPayment.isLoading}
          refetchTable={refetch}
          page={page}
          isLastPage={!data?.hasMore}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPage={handleRowsPerPage}
          isFetching={isFetching}
          selectType={selectedModalType}
          setSelectType={setSelectedModalType}
          rowData={modalData}
          openConfirmModal={openConfirmModal}
          deleteData={deleteData}
          handleCloseModal={handleCloseModal}
        />
        <SnackBar
          openSnackBar={openSnackBar}
          closeSnackBar={() => setOpenSnackBar(false)}
          snackBarMessage="Your changes have been successfully saved"
          snackBarType="success"
        />
      </>
    </div>
  );
};
