import { makeStyles } from "@material-ui/core";

export const useHandleLoadingStateStyles = makeStyles((theme) => ({
  loading: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    width: "150px",
    height: "150px"
  }
}));
