import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";
import { PaymentFields } from "../PaymentTypeForm";


interface UsePaymentFormOptions {
    initialValues?: PaymentFields;
    onSubmit: (
      values: PaymentFields,
      formikHelpers: FormikHelpers<PaymentFields>
    ) => Promise<any>;
  }



export const usePaymentFormik = (options:UsePaymentFormOptions) => {
    const  PaymentSchema = Yup.object().shape({
        //TODO: Add validations
      });
      return useFormik({
        initialValues: {
         id: "",
          name: "",
          type: "",
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: PaymentSchema,
        ...options,
        onSubmit: async (values, formikHelpers) => {
          await options.onSubmit(values, formikHelpers);
        }
      });
}
export type PaymentFormik = ReturnType<typeof usePaymentFormik>;