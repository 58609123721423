import { useFormik, FormikHelpers } from "formik";

import { ProductFields } from "../ProductForm";

interface UseProductFormOptions {
  initialValues?: ProductFields;
  onSubmit: (
    values: ProductFields,
    formikHelpers: FormikHelpers<ProductFields>
  ) => Promise<any>;
}

export const useProductFormik = (options: UseProductFormOptions) => {
  // const ProductSchema = Yup.object().shape({
  //   // product_name: Yup.string().required("Product name is required!"),
  //   // sku_barcode: Yup.string().required("SKU barcode is required!"),
  //   // category_name: Yup.string().required("Category name is required!"),
  //   // unit: Yup.string().required("Unit is required!"),
  //   // tax: Yup.string().required("Tax is required!")
  //   // retail_price: Yup.string().required("Retail price is required!"),
  //   // supply_price: Yup.string().required("Supply price is required!"),
  //   // description: Yup.string().required("Description is required!")
  // });

  return useFormik({
    initialValues: {
      id: "",
      product_name: "",
      sku_barcode: "",
      category_name: "",
      unit: "",
      discount: "0",

      tax: "",
      retail_price: "",
      supply_price: "",
      variations: "",
      sku_code: "",
      description: "",
      media: ""
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    // validationSchema: ProductSchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type ProductFormik = ReturnType<typeof useProductFormik>;
