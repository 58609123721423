import { apiRequest } from "./Api";

export interface Category {
  id: string;
  name: string;
  slug: string;
  parent_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface CategoryCreate {
  name: string;
  parent_id: string;
}

export interface CategoryUpdate {
  category_id: string;
  name: string;
  parent_id: string;
}

export interface CategoryDelete {
  id: string;
}

export interface CategoryPaginate {
  page: number;
  rows: number;
}

export interface AllCategories {
  rows: number;
}

export interface CategoryResponse {
  hasMore: boolean;
  categories: Category[];
}

export const getAllCategories = async (data: CategoryPaginate) => {
  return apiRequest<undefined, CategoryResponse>(
    "get",
    `api/admin/category/all?rows=${data.rows}&page=${data.page}`
  );
};

export const getCategories = async (data: AllCategories) => {
  return apiRequest<undefined, CategoryResponse>(
    "get",
    `api/admin/category/all?rows=${data.rows}`
  );
};

export const createCategory = async (data: CategoryCreate) =>
  apiRequest<CategoryCreate, CategoryResponse>(
    "post",
    "api/admin/category/create",
    data
  );

export const updateCategory = async (data: CategoryUpdate) =>
  apiRequest<CategoryUpdate, CategoryResponse>(
    "put",
    "api/admin/category/update",
    data
  );

export const deleteCategory = async (data: CategoryDelete) => {
  return apiRequest<CategoryDelete, CategoryResponse>(
    "delete",
    `api/admin/category/delete?category_id=${data.id}`,
    data
  );
};
