import React from "react";

import { RegisterForm } from "../../components/RegisterForm/RegisterForm";

export const Register = () => {
  return (
    <div className="Register">
      <RegisterForm />
    </div>
  );
};
