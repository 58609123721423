import { apiRequest } from "./Api";

export interface Employee {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
  store_id: string;
  pos_pin: string;
}

export interface EmployeeCreate {
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
  store_id: string;
  pos_pin: string;
  status: string;
}

export interface EmployeeUpdate {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
  store_id: string;
  pos_pin: string;
  status: string;
}

export interface EmployeeDelete {
  id: string;
}

export interface EmployeeResponse {
  hasMore: boolean;
  users: Employee[];
}

interface EmployeePagination {
  page: number;
  rows: number;
}

interface EmployeeParams {
  name?: string;
}

export const listAllEmployees = async (data: EmployeePagination) =>
  apiRequest<undefined, EmployeeResponse>(
    "get",
    `api/admin/users/all${data.page && `?page=${data.page}&rows=${data.rows}`}`
  );

export const getAllEmployees = async (data: EmployeeParams) =>
  apiRequest<undefined, EmployeeResponse>(
    "get",
    `api/admin/users/all`
  );

export const createEmployee = async (data: EmployeeCreate) =>
  apiRequest<EmployeeCreate, EmployeeResponse>(
    "post",
    "api/admin/users/register",
    data
  );

export const updateEmployee = async (data: EmployeeUpdate) =>
  apiRequest<EmployeeUpdate, EmployeeResponse>(
    "put",
    "api/admin/users/update",
    data
  );

export const deleteEmployee = async (data: EmployeeDelete) =>
  apiRequest<EmployeeDelete, EmployeeResponse>(
    "delete",
    `api/admin/users/delete?user_id=${data.id}`,
    data
  );
