import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import { SwipeableDrawer, Button } from "@material-ui/core";
import { useTableModalStyles } from "./useTableModalStyles";

interface TableModalProps {
  close: () => void;
  open: boolean;
  title?: string;
  isSaving: boolean | undefined;
  cancel?: () => void;
  handleSubmit: () => void;
  children?: React.ReactNode;
}

export const TableModal = (props: TableModalProps) => {
  const classes = useTableModalStyles();
  const handleClickAway = () => {
    props.close();
  };
  return (
    <div className="TableModal">
      <SwipeableDrawer
        anchor="right"
        open={props.open}
        onClose={handleClickAway}
        onOpen={() => console.log("onOpen")}
      >
        <div className={classes.TableModal__Header}>
          <CloseIcon onClick={props.close} className={"TableModal__close"} />
        </div>

        <div className="TableModal__Body">{props.children}</div>

        <div className={classes.TableModal__Footer}>
          <Button
            className={"TableModal__Footer_Save"}
            color="primary"
            variant="contained"
            onClick={props.handleSubmit}
            disabled={props.isSaving}
          >
            Save
          </Button>
          <Button
            className={"TableModal__Footer_Cancel"}
            variant="contained"
            color="default"
            onClick={props.cancel}
          >
            Cancel
          </Button>
        </div>
      </SwipeableDrawer>
    </div>
  );
};
