import React, { useContext } from "react";
import * as API from "../../../api/Api";
import { LoginRegisterResponse } from "../../../api/Api";

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: API.User;
  error?: string | { [key: string]: string };
  checkAuthentication: () => void;
  login: (credentials: LoginRegisterResponse) => void;
  logout: () => void;
}

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  checkAuthentication: () => {},
  login: () => {},
  logout: () => {}
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
