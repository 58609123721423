import { Result } from "api/Dashboard";
import LoadChart from "components/LoadingComponent/LoadChart";
import { NoDataMessage } from "components/SaleHistoryTable/NoDataMessage";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  ReferenceLine,
  ComposedChart,
  BarChart
} from "recharts";

interface TimeChart {
  raportResult: Result[] | undefined;
  setTypes: (value: string) => void;
  loading: boolean;
}

export const TimeChart = (props: TimeChart) => {
  const { setTypes } = props;
  useEffect(() => {
    setTypes?.("time");
  }, []);

  if (props.loading) return <LoadChart />;

  if (!props.raportResult) {
    return <NoDataMessage message="ERROR" />;
  }
  if (props.raportResult?.length === 0) {
    return <NoDataMessage message="Nuk ka shitje" />;
  }
  const sum = props.raportResult.map((value) => value.sum);
  const maxSum = Math.max(...sum);
  return (
    <ResponsiveContainer width="95%" height={600}>
      <BarChart
        data={props.raportResult}
        margin={{
          left: 50,
          top: 50,
          bottom: 30
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          interval={0}
          dataKey="date_part"
          tickFormatter={(str) => {
            return str + "h";
          }}
        />
        <YAxis
          tickFormatter={(value) => {
            return new Intl.NumberFormat("en", {
              //@ts-ignore
              notation: "compact",
              compactDisplay: "short"
            }).format(parseFloat(value));
          }}
          domain={["auto", maxSum]}
        />
        <Tooltip content={CustomTooltip} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="sum" barSize={20} fill="#0D1940" />
      </BarChart>
    </ResponsiveContainer>
  );
};

function CustomTooltip({ active, payload, label }: any) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>€{payload[0]?.value}</h4>
        <p>Time:{label}h</p>
      </div>
    );
  }
  return null;
}
