import { tabContext } from "lib/context/TabContext.tsx/TabContext";
import { useQueryParameters } from "lib/hooks/useQueryParameters";
import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useRouter } from "lib/hooks/useRouter";
const { Provider } = tabContext;

interface Props {
  children: React.ReactNode[];
}

export const Tabs = ({ children }: Props) => {
  const { getUrlWithQueryParams } = useQueryParameters();

  const [activeId, setActiveId] = useState("Sale");
  const router = useRouter();

  useEffect(() => {
    const queryParams = qs.parse(router.location.search) as { tab: string };
    if (queryParams.tab && activeId === "Sale") {
      setActiveId(queryParams.tab);
    }
  }, []);
  const handleClick = (tab: string) => {
    const urlParams = getUrlWithQueryParams("tab", tab);
    router.history.push(`${urlParams}`);
    setActiveId(tab);
  };
  return <Provider value={{ activeId, handleClick }}>{children}</Provider>;
};
