import { makeStyles } from "@material-ui/core";

export const useConfirmModalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    "&:focus": {
      outline: "0"
    }
  },
  buttonArea: {
    float: "right",
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  button: {
    width: "45%",
    cursor: "pointer"
  }
}));
