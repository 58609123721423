import React from "react";
import LogoSVG from "../../assets/images/numinco-logo-light.svg";
import { useHandleLoadingStateStyles } from "./useHandleLoadingStateStyles";

interface Props {
  loading?: boolean;
  children: React.ReactNode;
}

export const HandleLoadingState = (props: Props) => {
  const classes = useHandleLoadingStateStyles();
  if (!props.loading) {
    return <>{props.children}</>;
  }
  return (
    <div className={classes.loading}>
      <img className={classes.img} src={LogoSVG} alt="Numinco Logo" />
    </div>
  );
};
