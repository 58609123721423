import React, { useEffect, useState } from "react";

import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import "../EnhancedTableBody/EnhancedTableBody.scss";

import {
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Button,
  FormHelperText,
  Checkbox
} from "@material-ui/core";

interface EnhancedTableBodyProps {
  rows: {}[];
  columns: {}[];
  tableOrder: string;
  tableOrderBy: string;
  onRequestSort: (property: string) => void;
  onRowClick: (type: string, row: {}) => void;
  confirmModalProps: (
    closeModal: boolean,
    deletedRowName: string,
    deletedRowId: string
  ) => void;
  dynamicInput?: Array<number>;
  editFormik: any;
}

function stableSort(array: {}[], comparator: any) {
  const stabilizedThis = array?.map((el: object, index: number) => [el, index]);
  stabilizedThis !== undefined &&
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis?.map((el: (number | object)[]) => el[0]);
}

function descendingComparator(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: {}, b: {}) => descendingComparator(a, b, orderBy)
    : (a: {}, b: {}) => -descendingComparator(a, b, orderBy);
}

export const EnhancedTableBody = (props: EnhancedTableBodyProps) => {
  const {
    columns,
    tableOrder,
    tableOrderBy,
    onRequestSort,
    onRowClick,
    dynamicInput,
    editFormik
  } = props;
  const [rows, setRows] = useState(props.rows);
  const [selectedRow, setSelectedRow] = useState("");

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const handleOnClick = (type: string, row: {}) => {
    onRowClick(type, row);
  };

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
         
          {columns.map((column: any, index: number) => (
            <TableCell key={index} align="left">
              <TableSortLabel
                active={tableOrderBy === column.id}
                direction={
                  tableOrderBy === column.id
                    ? tableOrder !== "asc"
                      ? "desc"
                      : "asc"
                    : "asc"
                }
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {stableSort(rows, getComparator(tableOrder, tableOrderBy))?.map(
          (row: any, rowIndex: any) => {
            const rowId = Object.values(row)[0];

            // if (
            //   JSON.stringify(Object.values(row))
            //     // .replaceAll("null", "")
            //     .replaceAll(",", "")
            //     .toLowerCase()
            //     .includes(filterBySearchValue.toLowerCase())
            // ) {
            return (
              <TableRow
                tabIndex={-1}
                key={rowIndex}
                className={`EnhancedTableBody__table_row ${
                  selectedRow === rowId ? "EnhancedTableBody__active_row" : ""
                }`}
              >
                {Object.values(row)
                  .slice(1)
                  .map((rowData: any, index: number) => {
                    const inputId = `${
                      Object.keys(editFormik.values)[index + 1]
                    }`;
                    const inputError = `${Object.values(editFormik.errors)[0]}`;
                    const inputValue = `${
                      Object.values(editFormik.values)[index + 1]
                    }`;
                    return (
                      <TableCell align="left" key={index}>
                        {dynamicInput?.includes(index) &&
                        selectedRow === rowId ? (
                          <>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                editFormik.handleSubmit();
                              }}
                            >
                              <input
                                className="EnhancedTableBody__table_input"
                                type="text"
                                id={inputId}
                                name={inputId}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                value={inputValue}
                              />
                              <FormHelperText error>
                                {inputError &&
                                  inputError !== "undefined" &&
                                  inputError}
                              </FormHelperText>
                            </form>
                          </>
                        ) : (
                          <p>{rowData}</p>
                        )}
                      </TableCell>
                    );
                  })}

                <TableCell
                  align="left"
                  className="EnhancedTableBody__table_cell"
                >
                  {dynamicInput ? (
                    <>
                      {selectedRow !== rowId ? (
                        <Button
                          className="EnhancedTableBody__table_row_edit"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setSelectedRow(rowId as string);
                            handleOnClick("inlineEdit", row);
                          }}
                        >
                          Ndrysho
                        </Button>
                      ) : (
                        <div className="EnhancedTableBody__table_edit_actions">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setSelectedRow("");
                              editFormik.handleSubmit();
                            }}
                          >
                            <DoneIcon fontSize="small" />
                          </Button>
                          <Button
                            variant="contained"
                            color="default"
                            size="small"
                            onClick={() => {
                              setSelectedRow("");
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <EditIcon onClick={() => handleOnClick("edit", row)} />
                      <DeleteIcon
                        color="secondary"
                        onClick={() =>
                          props.confirmModalProps(
                            true,
                            row[Object.keys(row)[1]],
                            Object.values(row)[0] as string
                          )
                        }
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </>
  );
};
