import { makeStyles } from "@material-ui/core";

export const useLoginStyles = makeStyles((theme) => ({
  Login: {
    width: "100%",
    height: "75vh",
    display: "flex",
    marginTop: "40px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start"
  }
}));
