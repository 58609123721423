import { Result } from "api/Dashboard";
import LoadChart from "components/LoadingComponent/LoadChart";
import { NoDataMessage } from "components/SaleHistoryTable/NoDataMessage";
import { format, parseISO } from "date-fns";
import React, { useEffect } from "react";

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

interface SaleChart {
  raportResult?: Result[] | undefined;
  setTypes?: (value: string) => void;
  loading: boolean;
}

export const SaleChart = (props: SaleChart) => {
  const { setTypes } = props;
  useEffect(() => {
    setTypes?.("sales");
  }, []);
  if (props.loading) return <LoadChart />;

  if (props.raportResult?.length === 0) {
    return <NoDataMessage message="Nuk ka shitje" />;
  }

  return (
    <ResponsiveContainer width="95%" height={600}>
      <ComposedChart
        margin={{ left: 50, top: 50, bottom: 30 }}
        data={props.raportResult}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="created_at"
          tickFormatter={(str) => {
            const created_at = parseISO(str);
            if (created_at.getDate()) {
              return format(created_at, "MMM, d");
            }
            return "";
          }}
        />
        <YAxis
          dataKey="sum"
          tickFormatter={(value) => {
            return new Intl.NumberFormat("en", {
              //@ts-ignore
              notation: "compact",
              compactDisplay: "short"
            }).format(parseFloat(value));
          }}
        />

        <Tooltip content={CustomTooltip} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="sum" barSize={20} fill="#0D1940" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

function CustomTooltip({ active, payload, label }: any) {
  if (active) {
    return <h4>€{payload?.[0].value} </h4>;
  }

  return null;
}
