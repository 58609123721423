import { apiRequest } from "./Api";

export interface Store {
  store_id: string;
  store_name: string;
  address: string;
  phone: string;
  email: string;
  negative_inventory: boolean;
}

export interface StoreCreation {
  store_name: string;
  address: string;
  phone: string;
  email: string;
  negative_inventory: boolean;
}

export interface StoreDeletion {
  id: string;
}

export interface StoreResponse {
  hasMore: boolean;
  stores: Store[];
}

interface StoresParams {
  page: number;
  rows: number;
}

export const getAllStores = async (data: StoresParams) =>
  apiRequest<undefined, StoreResponse>(
    "get",
    `api/admin/store/all?page=${data.page}&rows=${data.rows}`
  );

export const allStores = async () =>
  apiRequest<undefined, StoreResponse>("get", "api/admin/store/all");

export const createStore = async (data: StoreCreation) =>
  apiRequest<StoreCreation, StoreResponse>(
    "post",
    "api/admin/store/create",
    data
  );

export const updateStore = async (data: Store) =>
  apiRequest<Store, StoreResponse>("put", "api/admin/store/update", data);

export const deleteStore = async (data: StoreDeletion) =>
  apiRequest<StoreDeletion, StoreResponse>(
    "delete",
    `api/admin/store/delete?store_id=${data.id}`,
    data
  );
