export const employeeColumns = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: true,
    label: "Surname"
  },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "role_name", numeric: true, disablePadding: false, label: "Role" },
  { id: "store_name", numeric: true, disablePadding: false, label: "Store" },
  { id: "pos_pin", numeric: true, disablePadding: false, label: "POS Pin" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "actions", numeric: true, disablePadding: false, label: "Actions" }
];
