import { apiRequest } from "./Api";

export interface Role {
  id: string;
  name: string;
}

export interface RoleResponse {
  roles: Role[];
}
export const getAllRole = async () =>
  apiRequest<undefined, RoleResponse>("get", "api/admin/role/all");

export const createRole = async (data: Role) =>
  apiRequest<Role, RoleResponse>("post", "api/admin/role/create", data);

export const updateRole = async (data: Role) =>
  apiRequest<Role, RoleResponse>("put", "api/admin/role/update", data);

export const deleteRole = async (id: Role) =>
  apiRequest<Role, RoleResponse>("put", "api/admin/role/delete", id);
