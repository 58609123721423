import React, { useState } from "react";

import { Store, StoreCreation, StoreResponse } from "api/Stores";
import * as API from "../../../api/Api";

import { useMutation, useQuery } from "react-query";
import { StoreForm } from "components/Forms/Store/StoreForm";
import { useUIContext } from "lib/context/UIContext/UIContext";
import { storeColumns } from "components/Forms/Store/storeColums";
import EnhancedTable from "components/Table/EnhancedTable/EnhancedTable";
import { useStoreFormik } from "components/Forms/Store/lib/useStoreFormik";
import { SnackBar } from "components/SnackBar/SnackBar";
import LoadTable from "components/LoadingComponent/LoadTable";

export const Stores = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState<Store[]>();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);

  const { data, isLoading, refetch, isFetching } = useQuery<
    StoreResponse,
    Error
  >(
    ["stores", page, rows],
    async () => {
      return await API.getAllStores({
        page: page,
        rows: rows
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  const createStore = useMutation((createStore: StoreCreation) =>
    API.createStore(createStore)
  );

  const updateStore = useMutation((updateStore: Store) =>
    API.updateStore(updateStore)
  );

  const deleteStore = useMutation((deleteStore: any) =>
    API.deleteStore(deleteStore)
  );

  const getRow = (row: Store[]) => {
    setRowData(row);
  };

  const createFormik = useStoreFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        createStore.mutate(
          {
            store_name: values.name,
            address: values.address,
            phone: values.phone,
            email: values.email,
            negative_inventory: values.negative_inventory
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch (e) {}
    }
  });

  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }

  const editFormik = useStoreFormik({
    initialValues: {
      name: editFormInitialValues[1],
      address: editFormInitialValues[2],
      phone: editFormInitialValues[3],
      email: editFormInitialValues[4],
      negative_inventory: editFormInitialValues[5] === "Yes" ? true : false
    },
    onSubmit: async (values, formikHelper) => {
      try {
        updateStore.mutate(
          {
            store_id: editFormInitialValues[0],
            store_name: values.name,
            address: values.address,
            phone: values.phone,
            email: values.email,
            negative_inventory: values.negative_inventory
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch (e) {}
    }
  });

  const filteredStoreData = data?.stores?.map((store: Store) => {
    return {
      store_id: store.store_id,
      store_name: store.store_name,
      address: store.address,
      phone: store.phone,
      email: store.email,
      negative_inventory: store.negative_inventory === true ? "Yes" : "No"
    };
  });

  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;
  return (
    <div className="UsersList">
      {isLoading ? (
        <LoadTable />
      ) : (
        <>
          <EnhancedTable
            tableTitle="Stores"
            columns={storeColumns}
            rows={filteredStoreData}
            selectedRow={getRow}
            deleteMutation={deleteStore}
            createForm={
              <StoreForm
                title="Create Store"
                formik={createFormik}
                serverError={serverError}
              />
            }
            editForm={
              <StoreForm
                title="Edit Store"
                formik={editFormik}
                serverError={serverError}
              />
            }
            createFormik={createFormik}
            editFormik={editFormik}
            isSaving={createStore.isLoading || updateStore.isLoading}
            refetchTable={refetch}
            page={page}
            isLastPage={!data?.hasMore}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPage={handleRowsPerPage}
            isFetching={isFetching}
          />
          <SnackBar
            openSnackBar={openSnackBar}
            closeSnackBar={() => setOpenSnackBar(false)}
            snackBarMessage="Your changes have been successfully saved"
            snackBarType="success"
          />
        </>
      )}
    </div>
  );
};
