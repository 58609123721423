import React, { useState } from "react";

import { useUIContext } from "lib/context/UIContext/UIContext";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { useEnhancedTableStyles } from "./useEnhancedTableStyles";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutationResult
} from "react-query";
import { debounce } from "lodash";
import { TableModal } from "components/shared/Modal/TableModal/TableModal";
import { EnhancedTableBody } from "../EnhancedTableBody/EnhancedTableBody";
import { EnhancedTableToolbar } from "../EnhancedTableToolbar/EnhancedTableToolbar";

import { Paper, TableContainer, Table } from "@material-ui/core";

import "./EnhancedTable.scss";
import { NoDataMessage } from "../NoDataMessage";
import { EnhancedTableFooter } from "../EnhancedTableFooter/EnhancedTableFooter";

interface RowProps {
  sale_id?: string;
  generated_sale_id?: string;
  created_at?: string;
  client?: string;
  payment_type?: string;
  amount_total?: string;
  status?: string;
  product_id?: string;
  product_name?: string;
  sku_barcode?: string;
  unit?: string;
  store?: string | undefined;
  supply_price?: string;
  mark_up?: string;
  untaxed_retail_price?: string;
  tax_rate?: string;
  retail_price?: string;
  category_id?: string;
  name?: string;
  slug?: string;
  parent_id?: any;
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  role_name?: string;
  store_name?: string;
  pos_pin?: string;
  left_job?: string;
}

interface EnhancedTableProps {
  rows: RowProps[] | undefined;
  columns: {}[];
  editFormik: any;
  createFormik?: any;
  selectedRow?: any;
  tableTitle: string;
  editForm?: React.ReactNode;
  createForm?: React.ReactNode;
  isSaving?: boolean;
  setValue?: any; //
  refetchTable: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<any, Error>>;
  deleteMutation?: UseMutationResult<any, unknown, any, unknown>;
  handlePreviousPage?: () => void;
  handleNextPage?: () => void;
  handleRowsPerPage?: (e: any) => void;
  page?: number;
  isLastPage?: boolean;
  isFetching?: boolean;
  dynamicInput?: any;
  filterByCategory?: React.ReactNode;
}

export default function EnhancedTable(props: EnhancedTableProps) {
  const uiCtx = useUIContext();
  const classes = useEnhancedTableStyles();

  const [tableOrder, setTableOrder] = useState("asc");
  const [tableOrderBy, setTableOrderBy] = useState("");

  const [modalData, setModalData] = useState({});
  const [selectedModalType, setSelectedModalType] = useState("");

  const [searchValue, setSearchValue] = useState("");

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<{
    rowName: string;
    rowId: string;
  }>({
    rowName: "",
    rowId: ""
  });
  const handleRequestSort = (property: string) => {
    const isAsc = tableOrderBy === property && tableOrder === "asc";
    setTableOrder(isAsc ? "desc" : "asc");
    setTableOrderBy(property);
  };

  const handleRowClick = (type: string, row: {}) => {
    uiCtx.toggleModal();
    setSelectedModalType(type);
    setModalData(row);
  };

  const handleClose = () => {
    uiCtx.toggleModal();
  };

  const handleCancel = () => {
    uiCtx.toggleModal();
    props.createFormik.resetForm();
    props.editFormik.resetForm();
  };

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    const deb = debounce(() => props.setValue(value), 500);
    deb();
  };

  const confirmModalProps = (
    closeModal: boolean,
    rowName: string,
    rowId: string
  ) => {
    setOpenConfirmModal(closeModal);
    setDeleteData({
      rowName,
      rowId
    });
  };

  return (
    <div className="Enhanced_Table">
      <div className={classes.root}>
        <Paper>
          {props.rows !== undefined ? (
            <>
              <EnhancedTableToolbar
                title={props.tableTitle}
                createNew={() => uiCtx.toggleModal()}
                createid={() => setSelectedModalType("create")}
                setSearchValue={handleSearchValue}
                searchValue={searchValue}
                filterByCategory={props.filterByCategory}
              />
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  onClick={props.selectedRow(modalData)}
                >
                  <EnhancedTableBody
                    rows={props.rows}
                    columns={props.columns}
                    onRowClick={handleRowClick}
                    onRequestSort={handleRequestSort}
                    tableOrder={tableOrder}
                    tableOrderBy={tableOrderBy}
                    confirmModalProps={confirmModalProps}
                    dynamicInput={props.dynamicInput}
                    editFormik={props.editFormik}
                  />
                </Table>
              </TableContainer>
              <EnhancedTableFooter
                page={props.page}
                isFetching={props.isFetching}
                isLastPage={props.isLastPage}
                handleNextPage={props.handleNextPage}
                handleRowsPerPage={props.handleRowsPerPage}
                handlePreviousPage={props.handlePreviousPage}
              />
            </>
          ) : (
            <NoDataMessage
              message={`Nuk ka te dhena per ${props.tableTitle}`}
              buttonMessage={`Create ${props.tableTitle}`}
            />
          )}
        </Paper>
        <TableModal
          open={
            uiCtx.isModalOpen &&
            (selectedModalType === "create" || selectedModalType === "edit")
          }
          close={handleClose}
          cancel={handleCancel}
          handleSubmit={() => {
            selectedModalType === "create"
              ? props.createFormik.handleSubmit()
              : props.editFormik.handleSubmit();
          }}
          isSaving={props.isSaving}
        >
          {selectedModalType === "create" ? props.createForm : props.editForm}
          boss
        </TableModal>

        <ConfirmModal
          deleteMutation={props.deleteMutation}
          open={openConfirmModal}
          close={() => setOpenConfirmModal(false)}
          deleteData={deleteData}
          refetchTable={props.refetchTable}
        />
      </div>
    </div>
  );
}
