export const productColumns = [
  {
    key: "product_name",
    header: "Item"
  },
  {
    key: "sku_barcode",
    header: "SKU"
  },
  {
    key: "category_name",
    header: "Category"
  },
  {
    key: "retail_price",
    header: "Retail price"
  },
  {
    key: "supply_price",
    header: "Supply price"
  },
  {
    key: "description",
    header: "Description"
  },
  { key: "actions", header: "Actions" }
];
