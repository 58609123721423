import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { SalesHistoryFields } from "../SalesHistoryForm";

interface UseSalesHistoryFormOptions {
  initialValues?: SalesHistoryFields;
  onSubmit: (
    values: SalesHistoryFields,
    formikHelpers: FormikHelpers<SalesHistoryFields>
  ) => Promise<any>;
}

export const useSalesHistoryFormik = (options: UseSalesHistoryFormOptions) => {
  const SalesHistorySchema = Yup.object().shape({
    //TODO: Add validation
  });

  return useFormik({
    initialValues: {
      sale_id: "",
      store_name: "",
      cash_register_name: "",
      cashier_first_name: "",
      cashier_last_name: "",
      client_first_name: "",
      client_last_name: "",
      generated_sale_id: "",
      created_at: "",
      payment_type: "",
      amount_total: "",
      status: "",
      store_id: "",
      buyer_id: "",
      cash_register_id: "",
      payment_id: "",
      discount: "",
      sale_date: ""
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SalesHistorySchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type CategoryFormik = ReturnType<typeof useSalesHistoryFormik>;
