import React from "react";

import { TextField, TextFieldProps } from "@material-ui/core";

import "./Input.scss";

export const Input = (props: TextFieldProps) => {
  return (
    <TextField
      className="Input"
      id={props.id}
      type={props.type}
      value={props.value}
      label={props.label}
      error={props.error}
      select={props.select}
      onBlur={props.onBlur}
      variant={props.variant}
      onChange={props.onChange}
      helperText={props.helperText}
      InputProps={props.InputProps}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      size={props.size}
    />
  );
};
