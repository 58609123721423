export const CustomersColumns = [
  {
    key: "first_name",
    numeric: true,
    disablePadding: false,
    header: "First name"
  },
  {
    key: "last_name",
    numeric: true,
    disablePadding: false,
    header: "Last Name"
  },
  {
    key: "phone_number",
    numeric: true,
    disablePadding: false,
    header: "Phone Number"
  },
  { key: "email", numeric: true, disablePadding: false, header: "Email" },
  { key: "address", numeric: true, disablePadding: false, header: "Address" },
  {
    key: "discount",
    numeric: false,
    disablePadding: true,
    header: "Discount"
  },
  {
    key: "is_business",
    numeric: false,
    disablePadding: true,
    header: "Is Business"
  },
  {
    key: "business_number",
    numeric: false,
    disablePadding: true,
    header: "Business Number"
  },
  {
    key: "tax_number",
    numeric: false,
    disablePadding: true,
    header: "Tax Number"
  },
  { key: "actions", numeric: true, disablePadding: false, header: "Actions" }
];
