import { Result } from "api/Dashboard";
import LoadTable from "components/LoadingComponent/LoadTable";
import { NoDataMessage } from "components/SaleHistoryTable/NoDataMessage";
import SaleTable from "components/SaleHistoryTable/SaleTable/SaleTable";
import React, { useEffect, useState } from "react";
import { teamRaportColumns } from "./TeamRaportColumns";
interface TeamRaport {
  raportResult?: Result[] | undefined;
  setTypes?: (value: string) => void;
  page: number;
  handleRowsPerPage: (e: any) => void;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  hasMore?: boolean;
  loading: boolean;
}

export const TeamRaport = (props: TeamRaport) => {
  const [rowData, setRowData] = useState<Result[]>();
  const { setTypes } = props;
  useEffect(() => {
    setTypes?.("team");
  }, []);

  const getRow = (row: Result[]) => {
    setRowData(row);
  };
  if (props.loading) return <LoadTable />;

  if (!props.raportResult) {
    return <NoDataMessage message="ERROR" />;
  }

  const filteredCategoryData = props.raportResult.map((raport: Result) => {
    return {
      first_name: raport.first_name + " " + raport.last_name,
      amount_total: "€" + raport.amount_total
    };
  });
  return (
    <div className="Team">
      <SaleTable
        tableTitle="Team raport"
        columns={teamRaportColumns}
        rows={filteredCategoryData}
        selectedRow={getRow}
        isLastPage={!props.hasMore}
        page={props.page}
        showToolBar={false}
        displayCreate={false}
        handleRowsPerPage={props.handleRowsPerPage}
        handlePreviousPage={props.handlePreviousPage}
        handleNextPage={props.handleNextPage}
      />
    </div>
  );
};
