import React from "react";

import { useQuery } from "react-query";
import * as API from "../../../api/Api";
import { Role, RoleResponse, Store, StoreResponse } from "../../../api/Api";

import { Input } from "../../shared/Input/Input";
import { useEmployeeFormStyles } from "./useEmployeeFormStyles";
import { Grid, InputLabelProps, TextField } from "@material-ui/core";

interface EmployeeFormProps {
  title: string;
  formik: any;
  shrink?: InputLabelProps;
  serverError: string;
}

export interface EmployeeFields {
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
  store_id: string;
  pos_pin: string;
  status: string;
}

export const EmployeeForm = (props: EmployeeFormProps) => {
  const classes = useEmployeeFormStyles();

  const stores = useQuery<StoreResponse, Error>("stores", async () => {
    return await API.allStores();
  });

  const roles = useQuery<RoleResponse, Error>("roles", async () => {
    return await API.getAllRole();
  });

  const selectRole = () => {
    if (roles.data !== undefined) {
      return (
        <>
          {roles.data.roles?.map((role: Role) => (
            <option
              key={role.id}
              // selected={props.formik.values.role_id === role.name}
              value={role.id}
            >
              {role.name}
            </option>
          ))}
        </>
      );
    }
  };

  const selectStore = () => {
    if (stores.data !== undefined) {
      return (
        <>
          {stores.data.stores?.map((store: Store) => (
            <option
              key={store.store_id}
              // selected={props.formik.values.store_id === store.store_name}
              value={store.store_id}
            >
              {store.store_name}
            </option>
          ))}
        </>
      );
    }
  };

  return (
    <div className={classes.EmployeeForm}>
      <h1 className={classes.title}>{props.title}</h1>
      <form
        onSubmit={props.formik.handleSubmit}
        className={classes.EmployeeForm__form}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              className="EmployeeForm__firstNameInput"
              id="first_name"
              name="first_name"
              label="First_name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.first_name}
              error={
                props.formik.touched.first_name &&
                !!props.formik.errors.first_name
              }
              helperText={
                props.formik.touched.first_name &&
                props.formik.errors.first_name
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="EmployeeForm__lastNameInput"
              id="last_name"
              name="last_name"
              label="Last name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.last_name}
              error={
                props.formik.touched.last_name &&
                !!props.formik.errors.last_name
              }
              helperText={
                props.formik.touched.last_name && props.formik.errors.last_name
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="EmployeeForm__emailInput"
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.email}
              error={props.formik.touched.email && !!props.formik.errors.email}
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.EmployeeForm__selectInput}
              select
              id="role_id"
              name="role_id"
              label="Role"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.role_id}
              error={
                props.formik.touched.role_id && !!props.formik.errors.role_id
              }
              helperText={
                props.formik.touched.role_id && props.formik.errors.role_id
              }
              SelectProps={{
                native: true
              }}
              InputLabelProps={props.shrink}
            >
              <option></option>
              {selectRole()}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.EmployeeForm__storeInput}
              select
              id="store_id"
              name="store_id"
              label="Store"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.store_id}
              error={
                props.formik.touched.store_id && !!props.formik.errors.store_id
              }
              helperText={
                props.formik.touched.store_id && props.formik.errors.store_id
              }
              SelectProps={{
                native: true
              }}
              InputLabelProps={props.shrink}
            >
              <option></option>
              {selectStore()}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Input
              className="EmployeeForm__posPinInput"
              id="pos_pin"
              name="pos_pin"
              label="Pos_pin"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.pos_pin}
              error={
                props.formik.touched.pos_pin && !!props.formik.errors.pos_pin
              }
              helperText={
                props.formik.touched.pos_pin && props.formik.errors.pos_pin
              }
            />
          </Grid>
          {props.title === "Edit" ? (
            <Grid item xs={12}>
              <TextField
                className={classes.EmployeeForm__selectInput}
                select
                id="status"
                name="status"
                label="Status"
                variant="outlined"
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.status}
                error={
                  props.formik.touched.status && !!props.formik.errors.status
                }
                helperText={
                  props.formik.touched.status && props.formik.errors.status
                }
                SelectProps={{
                  native: true
                }}
                InputLabelProps={props.shrink}
              >
                <option value="1">Active</option>
                <option value="0">Deactive</option>
              </TextField>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} className={classes.ServerError}>
            {props.serverError}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
