import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { CustomerFields } from "../CustomerForm";

interface UseCustomerFormOptions {
  initialValues?: CustomerFields;
  onSubmit: (
    values: CustomerFields,
    formikHelpers: FormikHelpers<CustomerFields>
  ) => Promise<any>;
}

export const useCustomerFormik = (options: UseCustomerFormOptions) => {
  const CustomerSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required!"),
    last_name: Yup.string().required("Last name is required!"),
    phone_number: Yup.string()
      .matches(
        /^(038|043|044|045|046|048|049)[0-9]{6}$/,
        "Enter a local phone number"
      )
      .required("Phone number is required!"),
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required!"),
    discount: Yup.string().required("Discount is required!")
  });

  return useFormik({
    initialValues: {
      id: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      address: "",
      discount: "",
      is_business: false,
      business_number: "",
      tax_number: ""
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CustomerSchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type CategoryFormik = ReturnType<typeof useCustomerFormik>;
