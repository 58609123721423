import { makeStyles } from "@material-ui/core";

export const useNoDataMessageStyles = makeStyles((theme) => ({
  root: {
    "& .NoDataMessage": {
      margin: "auto",
      padding: "100px",
      width: "fit-content",
      "& .NoDataMessage__info": {
        display: "flex",
        flexDirection: "column"
      },
      "& .NoDataMessage__info_logo": {
        fontSize: "100px",
        margin: "0 auto 20px"
      },
      "& .NoDataMessage__info_message": {
        fontSize: "25px"
      },
      "& .NoDataMessage__create": {
        width: "100%",
        marginTop: "20px"
      }
    }
  }
}));
