import { apiRequest } from "./Api";

export interface Unit {
  id: string;
  name: string;
}

export interface UnitResponse {
  units: Unit[];
}

export const getAllUnits = async () =>
  apiRequest<undefined, UnitResponse>("get", "api/admin/unit/all");
