import React, { useContext } from "react";

export interface UIContextType {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  toggleDrawer: () => void;

  isModalOpen: boolean;
  toggleModal: () => void;
}

const UIContextValues: UIContextType = {
  isDrawerOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  toggleDrawer: () => {},

  isModalOpen: false,
  toggleModal: () => {}
};

export const UIContext = React.createContext<UIContextType>(UIContextValues);

export const useUIContext = () => useContext(UIContext);
