import { FilterByStore } from "components/Filters/FilterByStore";
import { usePriceFormik } from "components/Forms/Price/lib/usePriceFormik";
import { priceColumns } from "components/Forms/Price/priceColumns";
import LoadTable from "components/LoadingComponent/LoadTable";
import { SnackBar } from "components/SnackBar/SnackBar";
import EnhancedTable from "components/Table/EnhancedTable/EnhancedTable";
import { useUIContext } from "lib/context/UIContext/UIContext";
import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import * as API from "../../api/Api";
import { PriceUpdate, AllPricesResponse, ProductType } from "../../api/Api";

import "./Price.scss";

export const Price = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState<ProductType[]>();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);
  const [store_id, setStore_id] = useState("");

  const { data, isLoading, refetch, isFetching } = useQuery<
    AllPricesResponse,
    Error
  >(
    ["prices", page, rows, store_id],
    async () => {
      return await API.getAllPrices({
        page: page,
        rows: rows,
        store_id: store_id
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  const updatePrice = useMutation((updatePrice: PriceUpdate) =>
    API.updatePrice(updatePrice)
  );

  const getRow = (row: ProductType[]) => {
    setRowData(row);
  };

  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }

  const editFormik = usePriceFormik({
    initialValues: {
      product_id: editFormInitialValues[0],
      product_name: editFormInitialValues[1],
      sku_barcode: editFormInitialValues[2],
      unit: editFormInitialValues[3],
      store: editFormInitialValues[4],
      supply_price: editFormInitialValues[5],
      mark_up: editFormInitialValues[6],
      untaxed_retail_price: editFormInitialValues[7],
      tax_rate: editFormInitialValues[8],
      retail_price: editFormInitialValues[9]
    },
    onSubmit: async (values, formikHelper) => {
      try {
        const untaxed_retail_price = (
          parseFloat(values.supply_price) +
          (parseFloat(values.supply_price) * parseFloat(values.mark_up)) / 100
        ).toFixed(2);
        updatePrice.mutate(
          {
            products: [
              {
                product_id: editFormInitialValues[0],
                retail_price: (
                  parseFloat(untaxed_retail_price) +
                  (parseFloat(untaxed_retail_price) *
                    parseFloat(values.tax_rate)) /
                    100
                ).toFixed(2)
              }
            ]
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch {}
    }
  });

  const filteredCategoryData = () => {
    if (!data?.prices?.products) return [];
    return data?.prices?.products?.map((price) => {
      const mark_up = (
        ((parseFloat(price.untaxed_retail_price) -
          parseFloat(price.supply_price)) *
          100) /
        parseFloat(price.supply_price)
      ).toFixed(2);
      return {
        product_id: price.product_id,
        product_name: price.product_name,
        sku_barcode: price.sku_barcode,
        unit: "cotpe",
        store: price.store || price.store_name,
        supply_price: price.supply_price,
        mark_up: mark_up,
        untaxed_retail_price: price.untaxed_retail_price,
        tax_rate: price.tax,
        retail_price: price.retail_price
      };
    });
  };
  if (isLoading) return <LoadTable />;
  // ================================= List Store ====================================================

  return (
    <div className="Category">
      <>
        <EnhancedTable
          tableTitle="Prices"
          columns={priceColumns}
          rows={filteredCategoryData()}
          selectedRow={getRow}
          editFormik={editFormik}
          isSaving={updatePrice.isLoading}
          refetchTable={refetch}
          page={page}
          isLastPage={!data?.prices?.hasMore}
          handlePreviousPage={() => {
            setPage((prev) => Math.max(prev - 1, 0));
          }}
          handleNextPage={() => {
            setPage((prev) => prev + 1);
          }}
          handleRowsPerPage={(e: any) => {
            setRows(e);
            setPage(1);
          }}
          isFetching={isFetching}
          dynamicInput={[5]}
          filterByCategory={
            <FilterByStore
              onChange={(e) => {
                setStore_id(e.target.value);
                setPage(1);
              }}
            />
          }
        />
        <SnackBar
          openSnackBar={openSnackBar}
          closeSnackBar={() => setOpenSnackBar(false)}
          snackBarMessage="Your changes have been successfully saved "
          snackBarType="success"
        />
      </>
    </div>
  );
};
