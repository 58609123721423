import React from "react"

import { SidebarList } from "../SidebarList/SidebarList"
import { SidebarRoutes } from "../../../routes/SidebarRoutes"

import Drawer from "@material-ui/core/Drawer"

interface Props {
  setCurrentObjectPath: (id: string) => void
  returnToFirstLevel: () => void
}

export const FirstLevelSidebar = (props: Props) => {
  const { setCurrentObjectPath, returnToFirstLevel } = props

  const filteredSidebarRoutes = SidebarRoutes.map((x) => {
    if (x.hasSecondDrawer) {
      return {
        ...x,
        children: []
      }
    }
    return x
  })

  return (
    <Drawer variant="permanent" className="Sidebar__drawer">
      <SidebarList
        returnToFirstLevel={returnToFirstLevel}
        list={filteredSidebarRoutes}
        setCurrentObjectPath={(path) => setCurrentObjectPath(path)}
      />
    </Drawer>
  )
}
