import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { StoreFields } from "../StoreForm";

interface UseStoreFormOptions {
  initialValues?: StoreFields;
  onSubmit: (
    values: StoreFields,
    formikHelpers: FormikHelpers<StoreFields>
  ) => Promise<any>;
}

export const useStoreFormik = (options: UseStoreFormOptions) => {
  const StoreSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Enter a longer store name!")
      .required("Store name is required!"),
    address: Yup.string().required("Adress is required"),
    phone: Yup.string()
      .matches(
        /^(038|043|044|045|046|048|049)[0-9]{6}$/,
        "Enter a local phone number"
      )
      .required("Phone number is required!"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required!"),
    negative_inventory: Yup.boolean().required(
      "Negative inventory is required!"
    )
  });

  return useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      email: "",
      negative_inventory: false
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: StoreSchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type StoreFormik = ReturnType<typeof useStoreFormik>;
