import { RouteProps } from "react-router-dom";

import { Login } from "../pages/Login/Login";
import { Profile } from "../pages/Profile/Profile";
import { Register } from "../pages/Register/Register";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED
}
interface AppRoute extends RouteProps {
  type?: RouteType;
}

// const sidebarRoutes = SidebarRoutes.map((items) => items)

export const AppRoutes: AppRoute[] = [
  // Restricted Routes`
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "login",
    component: Login
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "register",
    component: Register
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "reset-password",
    component: ResetPassword
  },
  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: "my-profile",
    component: Profile
  }
  //change from public to private
  // Public Routes
];
