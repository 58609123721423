import React, { useState } from "react";
import { useUIContext } from "lib/context/UIContext/UIContext";
import { CategoryForm } from "components/Forms/Category/CategoryForm";
import EnhancedTable from "components/Table/EnhancedTable/EnhancedTable";
import { useCategoryFormik } from "components/Forms/Category/lib/useCategoryFormik";
import { categoryColumns } from "components/Forms/Category/categoryColumns";
import { SnackBar } from "components/SnackBar/SnackBar";

import { useMutation, useQuery } from "react-query";
import * as API from "../../api/Api";
import {
  Category,
  CategoryCreate,
  CategoryResponse,
  CategoryUpdate
} from "../../api/Api";
import LoadTable from "components/LoadingComponent/LoadTable";

export const Categories = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState<Category[]>();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);

  const { data, isLoading, refetch, isFetching } = useQuery<
    CategoryResponse,
    Error
  >(
    ["categories", page, rows],
    async () => {
      return await API.getAllCategories({ page: page, rows: rows });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  const allCategories = useQuery<CategoryResponse, Error>(
    ["categories", rows],
    async () => {
      return await API.getCategories({ rows: 100 });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  const createCategory = useMutation((createCategory: CategoryCreate) =>
    API.createCategory(createCategory)
  );

  const updateCategory = useMutation((updateCategory: CategoryUpdate) =>
    API.updateCategory(updateCategory)
  );

  const deleteCategory = useMutation((deleteCategory: any) =>
    API.deleteCategory(deleteCategory)
  );

  const getRow = (row: Category[]) => {
    setRowData(row);
  };

  const createFormik = useCategoryFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        createCategory.mutate(
          {
            name: values.name,
            parent_id: values.parent_id
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch (e) {}
    }
  });

  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }
  const editFormik = useCategoryFormik({
    initialValues: {
      name: editFormInitialValues[1],
      slug: editFormInitialValues[2],
      parent_id: editFormInitialValues[3]
    },
    onSubmit: async (values, formikHelper) => {
      try {
        updateCategory.mutate(
          {
            category_id: editFormInitialValues[0],
            name: values.name,
            parent_id: values.parent_id
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch {}
    }
  });
  const filteredCategoryData = data?.categories.map((category: Category) => {
    const categoryParent = allCategories?.data?.categories
      ?.map((categoryParent: Category) => {
        if (categoryParent.id === category.parent_id) {
          return categoryParent.name;
        }
      })
      .filter(Boolean);
    return {
      category_id: category.id,
      name: category.name,
      slug: category.slug,
      parent_id: categoryParent?.length === 0 ? "-" : categoryParent
    };
  });
  // let number = history.location.state as number;
  // useEffect(() => {
  //   if (number !== undefined) {
  //     setPage(number);
  //     setParamPage(number);
  //   }
  // }, [history.location.state]);

  return (
    <div className="Category">
      {isLoading ? (
        <LoadTable />
      ) : (
        <>
          <EnhancedTable
            tableTitle="Categories"
            columns={categoryColumns}
            rows={filteredCategoryData}
            selectedRow={getRow}
            deleteMutation={deleteCategory}
            createForm={
              <CategoryForm
                title="Create Category"
                formik={createFormik}
                serverError={serverError}
              />
            }
            editForm={
              <CategoryForm
                title="Edit Category"
                formik={editFormik}
                serverError={serverError}
              />
            }
            createFormik={createFormik}
            editFormik={editFormik}
            isSaving={createCategory.isLoading || updateCategory.isLoading}
            refetchTable={refetch}
            page={page}
            isLastPage={!data?.hasMore}
            handlePreviousPage={() => {
              setPage((prev) => Math.max(prev - 1, 0));
              // history.push({
              //   pathname: `/products/category?page=${paramPage - 1}`,
              //   search: `${paramPage - 1}`,
              //   state: paramPage - 1
              // });
            }}
            handleNextPage={() => {
              setPage((prev) => prev + 1);
              // history.push({
              //   pathname: `/products/category?page=${paramPage + 1}`,
              //   search: `${paramPage - 1}`,
              //   state: paramPage + 1
              // });
            }}
            handleRowsPerPage={(e: any) => {
              setRows(e);
              setPage(1);
              // history.push({
              //   pathname: `/products/category/page/1`,
              //   state: 1
              // });
            }}
            isFetching={isFetching}
          />
          <SnackBar
            openSnackBar={openSnackBar}
            closeSnackBar={() => setOpenSnackBar(false)}
            snackBarMessage="Your changes have been successfully saved"
            snackBarType="success"
          />
        </>
      )}
    </div>
  );
};
