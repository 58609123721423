import { apiRequest } from "./Api";

export interface Tax {
  id: string;
  name: string;
  slug: string;
  percentage: string;
  add_tax_to_item_price: boolean;
  include_tax_in_item_price: boolean;
  enable_tax: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface TaxResponse {
  hasMore: boolean;
  taxes: Tax[];
}

export interface TaxDelete {
  id: string;
}
export interface TaxCreate {
  name: string;
  percentage: string;
}
export interface TaxUpdate {
  taxId: string;
  name: string;
  percentage: string;
}

export const getAllTaxes = async () =>
  apiRequest<undefined, TaxResponse>("get", `api/admin/tax/all`);

export const deleteTax = async (data: TaxDelete) =>
  apiRequest<TaxDelete, TaxResponse>(
    "delete",
    `api/admin/tax/delete?taxId=${data.id}`,
    data
  );
export const createTax = async (data: TaxCreate) =>
  apiRequest<TaxCreate, TaxResponse>("post", `api/admin/tax/create`, data);

export const updateTax = async (data: TaxUpdate) =>
  apiRequest<TaxUpdate, TaxResponse>("put", "api/admin/tax/update", data);
