import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  message?: string
}

export const TranslateErrorText = (props: Props) => {
  const { t } = useTranslation()
  return <>{props.message !== undefined ? t(props.message) : ""}</>
}
