import { makeStyles } from "@material-ui/core";

export const useSaleTableStyles = makeStyles((theme) => ({
  // SaleTable: {
  //   width: "fit-content"
  // },
  root: {
    width: "100%",
    "& .tableFooter": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    "& .rowsPerPage": {
      margin: "8px 0 0 8px"
    },
    "& .Sale_Table_no_data": {
      margin: "auto",
      padding: "100px",
      width: "fit-content",
      "& .Sale_Table_no_data_notify": {
        // padding: "auto"
        display: "flex",
        flexDirection: "column"
      },
      "& .Sale_Table_no_data_icon": {
        fontSize: "100px",
        margin: "0 auto 20px"
      },
      "& .Sale_Table_no_data_title": {
        fontSize: "25px"
      },
      "& .Sale_Table_no_data_create": {
        width: "100%",
        marginTop: "20px"
      }
    }
  },
  // paper: {
  //   width: "100%",
  //   marginBottom: theme.spacing(2)
  // },
  // visuallyHidden: {
  //   border: 0,
  //   clip: "rect(0 0 0 0)",
  //   height: 1,
  //   margin: -1,
  //   overflow: "hidden",
  //   padding: 0,
  //   position: "absolute",
  //   top: 20,
  //   width: 1
  // },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
}));
