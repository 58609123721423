import React from "react";

import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import { Input } from "components/shared/Input/Input";
import { FileUpload } from "components/FileUpload/FileUpload";

import { useQuery } from "react-query";
import * as API from "../../../api/Api";
import {
  Category,
  CategoryResponse,
  Tax,
  TaxResponse,
  Unit,
  UnitResponse,
  Variation
} from "../../../api/Api";
import { useProductFormStyles } from "./useProductFormStyles";

import "./ProductForm.scss";
interface ProductFormProps {
  title: string;
  formik: any;
  shrink?: any;
}

export interface ProductFields {
  id: string;
  product_name: string;
  sku_barcode: string;
  category_name: string;
  unit: string;
  discount: string;
  tax: string;
  retail_price: string;
  supply_price: string;
  variations: string;
  sku_code: string;
  description: string;
  media: any;
}


export const ProductForm = (props: ProductFormProps) => {

  const classes = useProductFormStyles();

  const units = useQuery<UnitResponse, Error>("units", async () => {
    return await API.getAllUnits();
  });

  const taxes = useQuery<TaxResponse, Error>("taxes", async () => {
    return await API.getAllTaxes();
  });

  const { data } = useQuery<Variation[], Error>("variations", async () => {
    return await API.getAllVariations();
  });
  const categories = useQuery<CategoryResponse, Error>(
    "categories",
    async () => {
      return await API.getCategories({ rows: 100 });
    }
  );
  // const handleUnitChange = (index: number, field: string, value: string) => {
  //   let unitArr = [...props.formik.values.unit];
  //   const newUnitObj = { ...unitArr[index], [field]: value };
  //   unitArr[index] = newUnitObj;

  //   props.formik.setFieldValue("unit", unitArr);
  // };

  return (
    <div className={classes.ProductForm}>
      <h1 className={classes.title}>{props.title}</h1>
      <form
        onSubmit={props.formik.handleSubmit}
        className={classes.ProductForm__form}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Details</h1>
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <Input
                className="ProductForm__nameInput"
                id="product_name"
                name="product_name"
                label="Name"
                variant="outlined"
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.product_name}
                error={
                  props.formik.touched.product_name &&
                  !!props.formik.errors.product_name
                }
                helperText={
                  props.formik.touched.product_name &&
                  props.formik.errors.product_name
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                className="ProductForm__skuBarcodeInput"
                id="sku_barcode"
                name="sku_barcode"
                label="SKU Barcode"
                variant="outlined"
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.sku_barcode}
                error={
                  props.formik.touched.sku_barcode &&
                  !!props.formik.errors.sku_barcode
                }
                helperText={
                  props.formik.touched.sku_barcode &&
                  props.formik.errors.sku_barcode
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.FileUpload}>
            <FileUpload
              className="test"
              onFileSelect={(file: any[]) =>
                props.formik.setFieldValue("media", file[0], true)
              }
              onFileDelete={() => props.formik.setFieldValue("media", [], true)}
            />
          </Grid>

          <Grid item xs={6} className={classes.Input}>
            <TextField
              className={classes.SelectInput}
              select
              id="category_name"
              name="category_name"
              label="Category"
              variant="outlined"
              value={props.formik.values.category_name}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.category_name &&
                !!props.formik.errors.category_name
              }
              helperText={
                props.formik.touched.category_name &&
                props.formik.errors.category_name
              }
              SelectProps={{
                native: true
              }}
            >
              <option></option>
              {categories.data?.categories.map((category: Category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} className={classes.Input}>
            <TextField
              className={classes.SelectInput}
              select
              id="tax"
              name="tax"
              label="Tax"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.tax}
              error={props.formik.touched.tax && props.formik.errors.tax}
              helperText={props.formik.touched.tax && props.formik.errors.tax}
              SelectProps={{
                native: true
              }}
            >
              <option></option>
              {taxes.data?.taxes?.map((tax: Tax) => (
                <option
                  key={tax.id}
                  // selected={props.formik.values.tax === tax.id}
                  value={tax.id}
                >
                  {tax.name}
                </option>
              ))}
            </TextField>
          </Grid>

          {/* {props.formik.values.unit.map((unit: any, index: number) => ( */}
          <React.Fragment>
            <Grid item xs={4}>
              <TextField
                className={classes.SelectInput}
                select
                id="unit"
                name="unit"
                label="Unit"
                variant="outlined"
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                value={props.formik.values.unit}
                error={props.formik.touched.unit && !!props.formik.errors.unit}
                helperText={
                  props.formik.touched.unit && props.formik.errors.unit
                }
                SelectProps={{
                  native: true
                }}
                InputLabelProps={props.shrink}
              >
                <option></option>
                {units.data?.units?.map((unit: Unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </React.Fragment>
          <Grid item xs={4}>
            <Input
              className="ProductForm__discount"
              id="discount"
              name="discount"
              label="Discount"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.discount}
              error={
                props.formik.touched.discount && !!props.formik.errors.discount
              }
              helperText={
                props.formik.touched.discount && props.formik.errors.discount
              }
            />
          </Grid>
          {/* // ))} */}

          <Grid item xs={12} className={classes.Switcher}>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" />}
              label="Track stock"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.OptionalDetails}>
          <Grid item xs={12}>
            <h1>Optional details</h1>
          </Grid>
          <Grid item xs={6} className={classes.Input}>
            <Input
              className="ProductForm__retailPriceInput"
              id="retail_price"
              name="retail_price"
              label="Retail Price"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.retail_price}
              error={
                props.formik.touched.retail_price &&
                !!props.formik.errors.retail_price
              }
              helperText={
                props.formik.touched.retail_price &&
                props.formik.errors.retail_price
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.Input}>
            <Input
              className="ProductForm__supplyPriceInput"
              id="supply_price"
              name="supply_price"
              label="Supply Price"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.supply_price}
              error={
                props.formik.touched.supply_price &&
                !!props.formik.errors.supply_price
              }
              helperText={
                props.formik.touched.supply_price &&
                props.formik.errors.supply_price
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.Input}>
            <TextField
              className={classes.SelectInput}
              select
              id="variation"
              name="variation"
              label="Variation"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.variation}
              error={
                props.formik.touched.variation &&
                !!props.formik.errors.variation
              }
              helperText={
                props.formik.touched.variation && props.formik.errors.variation
              }
              SelectProps={{
                native: true
              }}
            >
              <option></option>
              {data?.map((variation: Variation, index: number) => (
                <option key={index}>{variation.variation_name}</option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} className={classes.Input}>
            <Input
              className="ProductForm__skuCodeInput"
              id="sku_code"
              name="sku_code"
              label="SKU Code"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.sku_code}
              error={
                props.formik.touched.sku_code && !!props.formik.errors.sku_code
              }
              helperText={
                props.formik.touched.sku_code && props.formik.errors.sku_code
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.Input}>
            <Input
              className="ProductForm__descriptionInput"
              id="description"
              name="description"
              label="Description"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.description}
              error={
                props.formik.touched.description &&
                !!props.formik.errors.description
              }
              helperText={
                props.formik.touched.description &&
                props.formik.errors.description
              }
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
