export const storeColumns = [
  {
    id: "store_name",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  { id: "address", numeric: true, disablePadding: false, label: "Address" },
  { id: "phone", numeric: true, disablePadding: false, label: "Phone" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  {
    id: "negative_inventory",
    numeric: true,
    disablePadding: false,
    label: "Negative Inventory"
  },
  { id: "actions", numeric: true, disablePadding: false, label: "Actions" }
];
