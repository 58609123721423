import React, { useEffect, useState } from "react";
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { useMutation, useQuery } from "react-query";
import * as API from "../../api/Api";
import { FilterByPage } from "components/Filters/FilterByPage";
import "../SettingsEcommerce/SettingsEcommerce.scss";
import draftToHtml from "draftjs-to-html";
import { Button } from "@material-ui/core";

export interface SettingsProps {
  id: number;
  key: string;
  slug_key: string;
  content: object;
  created_at: number;
  updated_at: object;
  deleted_at: object;
  media: object;
}

export const SettingsEcommerce = () => {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [id, setId] = useState("");
  const { data } = useQuery<any, Error>(
    ["settings-id", id],
    async () => {
      return await API.getPageById({
        id: id || "c3e5b84c-4951-4f51-a025-541d620d9285"
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  useEffect(() => {
    if (data?.setting.content) {
      const blocksFromHTML = htmlToDraft(data?.setting.content);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
      // const noLink = data?.settings?.filter((item: any) =>
      //   item.slug_key.includes("link") ? item : "test"
      // );
    }
  }, [id, data]);

  const updateDraft = useMutation((updateDraft: any) =>
    API.editPageById(updateDraft)
  );

  const handleMutate = () => {
    let formData = new FormData();
    formData.append("settingId", id);
    formData.append(
      "content",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    const data = formData;
    updateDraft.mutate(data);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setId(e.target.value);
  };

  const uploadCallback = (file: File) => {
    return new Promise((resolve, reject) => {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          resolve({ data: { link: e?.target?.result } });
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const link = data?.setting?.slug_key.includes("link");

  return (
    <div>
      <FilterByPage onChange={handleOnChange} page={id} />
      {link ? (
        <div>
          <label htmlFor="Input">Link</label>
          <input type="text"></input>
        </div>
      ) : (
        <DraftEditor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
          toolbar={{
            image: {
              urlEnabled: false,
              uploadEnabled: true,
              alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
              uploadCallback: uploadCallback,
              previewImage: true,
              inputAccept: "image/*",
              alt: { present: false, mandatory: false }
            }
          }}
        />
      )}

      <Button type="submit" onClick={() => handleMutate()}>
        Edit
      </Button>
    </div>
  );
};
