import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { useSalesHistoryFormStyles } from "./useSalesHistoryFormStyles";

import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import DeleteIcon from "@material-ui/icons/Delete";
// import AddIcon from "@material-ui/icons/Add";
import { useQuery } from "react-query";
import * as API from "../../../api/Api";
// import { SearchProducts } from "./SearchProducts";
import { Input } from "components/shared/Input/Input";
// import FormHelperText from "@material-ui/core/FormHelperText";
import { SalesHistoryResponse, SaleItem } from "../../../api/Api";
import { useSaleContext } from "lib/context/SaleContext/SaleContext";
// import { loadavg } from "os";

interface SaleBillProps {
  sale_id: string;
  store_id: string;
  formik: any;
  displayRefund: boolean;
  discount: string;
}

export const SaleBill = (props: SaleBillProps) => {
  const classes = useSalesHistoryFormStyles();
  const billCtx = useSaleContext();
  const [quantity, setQuantity] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState(-1);

  const [billData, setBillData] = useState<SaleItem[]>();
  // const [selectedItem, setSelectedItem] = useState<Object>();
  // const [error, setError] = useState("");

  const { isLoading } = useQuery<SalesHistoryResponse, Error>(
    ["saleById", props.sale_id, props.store_id],
    async () => {
      const res = await API.getSaleById({
        sale_id: props.sale_id,
        store_id: props.store_id
      });

      const items = res.allSales.sale[0].saleData.map((item, index) => ({
        ...item,
        max_quantity: item.quantity,
        index: index
      }));

      setBillData(items);
      return res;
    },
    { refetchOnWindowFocus: false }
  );

  const calculateSum = (column: string) => {
    let total = 0;
    billData?.map((item: any) => {
      total += parseFloat(item[column]);
    });
    return total;
  };

  const handleOnChange = (event: any, item: SaleItem) => {
    if (!item.max_quantity) {
      return;
    }
    if (!event && !item) {
      return;
    }
    if (event.target.value.includes("-")) {
      return;
    }
    if (
      parseInt(event.target.value) > parseInt(item.max_quantity) ||
      parseInt(event.target.value) < 1
    ) {
      return;
    }
    setQuantity(event.target.value || item.quantity);
  };

  const handleDone = (item: SaleItem) => {
    if (billData) {
      setBillData((prev) => {
        return prev?.map((product) => {
          return product.index === item.index
            ? { ...product, quantity: quantity }
            : product;
        });
      });
    }
    setSelectedRow(-1);

    billCtx.refundEditQuantity(item, quantity);
  };
  const getTotalRefund = (colum: string) => {
    let totali = 0;

    billCtx.checkedRefund?.map((item: any) => {
      totali += parseFloat(item[colum]);
    });
    return totali;
  };
  const totalPrice = parseFloat(
    getTotalRefund("amount_total_per_product").toFixed(2)
  );
  let refundTotal =
    totalPrice - (totalPrice * parseFloat(props.discount)) / 100;

  const priceInvoice = parseFloat(
    calculateSum("amount_total_per_product").toFixed(2)
  );
  let invoiceTotal =
    priceInvoice - (priceInvoice * parseFloat(props.discount)) / 100;

  return (
    <>
      <>
        <div className={classes.Bill}>
          <Grid item xs={12}>
            <h3 className={classes.SalesHistory__title}>Bill Information </h3>
          </Grid>
          <TableContainer className={classes.Table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Untaxed Price</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Total Price</TableCell>
                  {!props.displayRefund && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {billData?.map((item: any, index: number) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <p>{item.product_name}</p>
                      </TableCell>

                      <TableCell>
                        {selectedRow === index ? (
                          <Input
                            size="small"
                            variant="outlined"
                            type="number"
                            value={quantity}
                            onChange={(e) => {
                              handleOnChange(e, item);
                            }}
                          />
                        ) : (
                          <p>{item.quantity}</p>
                        )}
                      </TableCell>

                      <TableCell>
                        <p>{item.unit_name}</p>
                      </TableCell>
                      <TableCell>
                        <p>{item.price}</p>
                      </TableCell>

                      <TableCell>
                        <p>{item.product_unit_discount}</p>
                      </TableCell>

                      <TableCell>
                        <p>
                          {
                            (item.amount_untaxed_per_product = (
                              item.quantity * item.price -
                              item.product_unit_discount -
                              item.amount_tax_per_product
                            ).toFixed(2))
                          }
                        </p>
                      </TableCell>

                      <TableCell>
                        <p>{item.amount_tax_per_product}</p>
                      </TableCell>

                      <TableCell>
                        <p>
                          {
                            (item.amount_total_per_product = (
                              item.quantity * item.price -
                              item.product_unit_discount
                            ).toFixed(2))
                          }
                        </p>
                      </TableCell>
                      {!props.displayRefund && parseInt(item.quantity) > 0 && (
                        <TableCell>
                          {selectedRow === -1 || selectedRow !== index ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row"
                              }}
                            >
                              <IconButton size="small">
                                <EditIcon
                                  color="inherit"
                                  onClick={() => {
                                    setSelectedRow(index);
                                    setQuantity(item.quantity);
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => billCtx.handleCheckRefund(item)}
                              >
                                {!billCtx.checkedRefund.find(
                                  (element) => element.index === item.index
                                ) ? (
                                  <CheckBoxOutlineBlankIcon />
                                ) : (
                                  <CheckBoxIcon />
                                )}
                              </IconButton>
                            </div>
                          ) : (
                            <IconButton size="small" type="submit">
                              <DoneIcon onClick={(e) => handleDone(item)} />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12} className={classes.TableFooter}>
            <div></div>

            <div className={classes.TableFooterInfo}>
              <div>
                <h1>Vlera pa tvsh:</h1>
                <h1>Tvsh:</h1>
                <h1>Zbritje:</h1>
                <h1>Totali per pagese:</h1>
              </div>

              <div>
                {!props.displayRefund ? (
                  <>
                    <p>
                      {getTotalRefund("amount_untaxed_per_product").toFixed(2)}€
                    </p>
                    <p>
                      {getTotalRefund("amount_tax_per_product").toFixed(2)} €
                    </p>
                    <p>{props.discount}%</p>
                    <p>{refundTotal.toFixed(2)}</p>
                  </>
                ) : (
                  <>
                    <p>
                      {calculateSum("amount_untaxed_per_product").toFixed(2)}€
                    </p>
                    <p>{calculateSum("amount_tax_per_product").toFixed(2)} €</p>
                    <p>{props.discount} %</p>
                    <p>{invoiceTotal.toFixed(2)} €</p>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </>
    </>
  );
};
