import { makeStyles } from "@material-ui/core";
import { Opacity } from "@material-ui/icons";

export const useSalesHistoryFormStyles = makeStyles((theme) => ({
  SalesHistoryForm: {
    width: "70vw"
  },
  SalesHistory__title: {
    color: "black",
    fontSize: "18px",
    marginBottom: "20px",
    fontWeight: 400
  },
  Bill: {
    marginTop: "30px"
  },
  SalesHistoryForm__header: {
    width: "70vw",
    backgroundColor: "white",
    display: "flex",
    padding: "20px 20px",
    borderBottom: "1px solid #e5e5e5",
    position: "fixed",
    top: "0",
    zIndex: 10
  },
  SalesHistory__info: {
    fontSize: "15px",
    fontWeight: 500,
    marginBottom: "10px"
  },
  SalesHistoryForm__container: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "20px",
    marginBottom: "20px"
  },

  TableModal__Header_button: {
    margin: "0 20px",
    padding: "8px 14px 7px !important",
    fontSize: " 12px"
  },
  SalesHistoryForm__body: {
    marginTop: "88px",
    marginBottom: "10px",

    backgroundColor: "#e5e5e5",

    padding: "20px 20px"
  },
  title: {
    fontSize: "23px",
    margin: "0 auto 30px",
    width: "fit-content"
  },
  input: {
    border: "none",
    backgroundColor: "#ebf1f7",
    height: "30px",
    width: "100%"
  },
  SelectInput: {
    width: "100%",
    "& .MuiAutocomplete-root": {
      width: "100% !important"
    }
  },
  ServerError: {
    color: "red"
  },
  Table: {
    marginBottom: "20px",
    "& .MuiTable-root": {
      border: "1px solid lightgray",
      borderBottom: "none"
    },

    "& .MuiTableCell-head": {
      minWidth: "100px !important",
      maxWidth: "100px !important"
    },
    "& .MuiTableCell-body": {
      minWidth: "100px !important",
      maxWidth: "100px !important",
      height: "75px",
      "& .Input": {
        marginBottom: "0px !important"
      }
    }
  },
  BillAddNewProduct: {},
  TableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  TableFooterInfo: {
    width: "25%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "gray"
  },
  FormHelperText: {
    position: "absolute",
    marginLeft: "2px !important",
    color: "red"
  }
}));
