import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSettingsFormik } from "./lib/useSettingsFormik";

import classNames from "classnames";

import { FileUpload } from "../FileUpload/FileUpload";
import { Input } from "../shared/Input/Input";
import { TranslateErrorText } from "../shared/TranslateErrorText/TranslateErrorText";

import { Button, Divider, Grid, Paper } from "@material-ui/core";

import "./SettingsForm.scss";
import { FileUploadd } from "components/FileUploadd/FileUploadd";
import { FilterColor } from "components/Filters/FilterColor";

export interface SettingsFields {
  name: string;
  email: string;
  businessNumber: string;
  taxNumber: string;
  phone: string;
  web: string;
  address: string;
  logo: any;
}

export const SettingsFrom = () => {
  const [settings, setSettings] = useState<SettingsFields>({
    name: "Kutia",
    email: "Kutia@gmail.com",
    businessNumber: "74747474788388",
    taxNumber: "1123557",
    phone: "0445859330",
    web: "www.kutia.net",
    address: "Prishtin e poshtme",
    logo: "values.logo"
  });
  const { t } = useTranslation();

  const formik = useSettingsFormik({
    initialValues: {
      name: settings?.name,
      email: settings?.email,
      businessNumber: settings?.businessNumber,
      taxNumber: settings?.taxNumber,
      phone: settings?.phone,
      web: settings?.web,
      address: settings?.address,
      logo: settings?.logo
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        let formData = new FormData();
        formData.append("assets", JSON.stringify(values.logo[0].name));
        const object = {
          name: values.name,
          email: values.email,
          businessNumber: values.businessNumber,
          taxNumber: values.taxNumber,
          phone: values.phone,
          web: values.web,
          address: values.address,
          logo: values.logo
        };
        setSettings(object);
        formikHelpers.resetForm();
      } catch (e) {}
    }
  });
  return (
    <div className="SettingsFrom">
      <Paper elevation={5}>
        <p className="SettingsFrom__title">{t("companySettings.title")}</p>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <h3 className="SettingsFrom__details">YOUR STORE DETAILS</h3>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                id="name"
                type="text"
                label={t("companySettings.name")}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name && (
                    <TranslateErrorText message={formik.errors.name} />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="email"
                type="email"
                label={t("companySettings.email")}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email && (
                    <TranslateErrorText message={formik.errors.email} />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="businessNumber"
                type="number"
                label={t("companySettings.businessNumber")}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.businessNumber}
                error={
                  formik.touched.businessNumber &&
                  !!formik.errors.businessNumber
                }
                helperText={
                  formik.touched.businessNumber && (
                    <TranslateErrorText
                      message={formik.errors.businessNumber}
                    />
                  )
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Input
                id="taxNumber"
                type="text"
                label={t("companySettings.taxNumber")}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.taxNumber}
                error={formik.touched.taxNumber && !!formik.errors.taxNumber}
                helperText={
                  formik.touched.taxNumber && (
                    <TranslateErrorText message={formik.errors.taxNumber} />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="phone"
                type="number"
                label={t("companySettings.phone")}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={
                  formik.touched.phone && (
                    <TranslateErrorText message={formik.errors.phone} />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="web"
                type="text"
                label={"Web"}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.web}
                error={formik.touched.web && !!formik.errors.web}
                helperText={
                  formik.touched.web && (
                    <TranslateErrorText message={formik.errors.web} />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id="address"
                type="textpassword"
                label={t("companySettings.address")}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && !!formik.errors.address}
                helperText={
                  formik.touched.address && (
                    <TranslateErrorText message={formik.errors.address} />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FilterColor />
            </Grid>

            <Grid item xs={6}>
              <div
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classNames({
                  FileUpload__error: formik.errors.logo && formik.touched.logo
                })}
              >
                {/* <div className="FileUpload__label">Logo:</div> */}
                <FileUploadd />
                {/* <FileUpload
                  onFileSelect={(file: any[]) =>
                    formik.setFieldValue("logo", file, true)
                  }
                  onFileDelete={() => formik.setFieldValue("logo", [], true)}
                /> */}
                <div className="FileUpload__error-label">
                  {/* {formik.touched.logo && formik.errors.logo && (
                    <TranslateErrorText message={formik.errors.logo} />
                  )} */}
                </div>
              </div>
            </Grid>

            <Grid className="SettingsForm__submition" item xs={6}>
              <div className="Settings__form-submition">
                <Button type="button" variant="contained" color="default">
                  {t("companySettings.cancel")}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("companySettings.save")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
