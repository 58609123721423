import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";
import { useUIContext } from "../../../lib/context/UIContext/UIContext";

import { isActiveRoute } from "../../../lib/helpers/isActiveRoute";

//styles
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  SvgIconTypeMap
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

import "./SidebarItem.scss";

interface Props {
  onCollapse: (id: string) => void;
  listIsOpen: boolean;
  item: Item;
  setCurrentObjectPath?: (x: any) => void;
}

//make interface

export interface Item {
  id: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  hasSecondDrawer: boolean;
  children?: Children[];
  route: { path: string };
}

export interface Children {
  id: string;
  title: string;
  route: { path: string };
}

export const SidebarItem = (props: Props) => {
  const hasChildren =
    props.item.children?.length !== 0 && props.item.children !== undefined;
  const hasSecondDrawer = props.item.hasSecondDrawer;
  const { t } = useTranslation();
  const langCtx = useLanguageContext();
  const uiCtx = useUIContext();

  const langPrefix = langCtx.getLangPrefix();
  const routePath = useLocation().pathname;
  const activeClass = "SidebarItem--selected";
  const isActive = isActiveRoute(routePath, [props.item.route.path]);

  const [linkProps] = useState(() => {
    if (!hasChildren && !hasSecondDrawer) {
      return {
        component: NavLink,
        to: `${langPrefix}/${props.item.route?.path}`
      };
    }

    return {
      key: props.item.id,
      onClick: () =>
        hasSecondDrawer ? getObjectPath() : props.onCollapse(props.item.id)
    };
  });

  const getObjectPath = () => {
    if (props.setCurrentObjectPath) {
      props.setCurrentObjectPath(props.item.route.path);
    }
  };

  const ToggleArrow = () => {
    if (!hasChildren || hasSecondDrawer) {
      return <></>;
    }

    return props.listIsOpen ? <ExpandLess /> : <ExpandMore />;
  };

  return (
    <>
      <div onClick={uiCtx.openDrawer}>
        <ListItem {...linkProps} button className={isActive ? activeClass : ""}>
          <ListItemIcon>
            <SvgIcon
              component={props.item.icon}
              className="SidebarItem--icon"
            />
          </ListItemIcon>
          <ListItemText primary={t(props.item.title)} />
          <ToggleArrow />
        </ListItem>
      </div>

      {props.item.children?.map((children) => {
        const isActive = isActiveRoute(routePath, [
          props.item.route.path,
          children.route.path
        ]);
        return (
          <Collapse
            in={props.listIsOpen || (isActive && uiCtx.isDrawerOpen)}
            unmountOnExit
            key={children.id}
          >
            <List>
              <ListItem
                button
                className={isActive && uiCtx.isDrawerOpen ? activeClass : ""}
                key={children.id}
                component={NavLink}
                to={`${langPrefix}/${props.item.route?.path}/${children.route.path}`}
              >
                <ListItemText
                  className="Collapse-list-text"
                  primary={t(children.title)}
                />
              </ListItem>
            </List>
          </Collapse>
        );
      })}
    </>
  );
};
