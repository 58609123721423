import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useUIContext } from "../../../lib/context/UIContext/UIContext";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

import { IconButton, List, ListSubheader } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { SidebarItem } from "../SidebarItem/SidebarItem";
import { SidebarRoute } from "../../../routes/SidebarRoutes";
import { ReactComponent as LogoSVG } from "../../../assets/images/numinco-logo-dark.svg";

//styles
import "./SidebarList.scss";

interface Props {
  list: SidebarRoute[];
  showPrevButton?: boolean;
  setCurrentObjectPath?: (x: any) => void;
  returnToFirstLevel: () => void;
}

export const SidebarList = (props: Props) => {
  const { list, showPrevButton, returnToFirstLevel, setCurrentObjectPath } =
    props;
  const [openList, setOpenList] = useState("");
  const uiCtx = useUIContext();
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  const handleToggle = (id: string) => {
    setOpenList((prev) => (id === prev ? "" : id));
  };

  return (
    <div className="SidebarList">
      <List>
        <ListSubheader
          className="Sidebarlist-subheader"
          disableSticky={false}
          onClick={returnToFirstLevel}
        >
          {showPrevButton && uiCtx.isDrawerOpen && (
            <IconButton
              className="sidebar-back-button"
              color="inherit"
              aria-label="upload picture"
              component="span"
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <Link
            className="Sidebarlist-subheader-logo"
            to={`${langPrefix}/dashboard`}
          >
            <LogoSVG />
          </Link>
        </ListSubheader>
        {list.map((value: any) => {
          return (
            <SidebarItem
              key={value.id}
              item={value}
              onCollapse={handleToggle}
              listIsOpen={value.id === openList && uiCtx.isDrawerOpen}
              setCurrentObjectPath={setCurrentObjectPath}
            />
          );
        })}
      </List>
    </div>
  );
};
