import React, { useState } from "react";

import * as API from "../../api/Api";
import { useMutation, useQuery } from "react-query";
import {
  EmployeeCreate,
  EmployeeDelete,
  EmployeeResponse,
  EmployeeUpdate
} from "../../api/Employees";

import { useUIContext } from "lib/context/UIContext/UIContext";

import { SnackBar } from "components/SnackBar/SnackBar";
import { EmployeeForm } from "components/Forms/Employee/EmployeeForm";
import EnhancedTable from "components/Table/EnhancedTable/EnhancedTable";
import { employeeColumns } from "../../components/Forms/Employee/employeeColumns";
import { useEmployeeFormik } from "components/Forms/Employee/lib/useEmployeeFormik";
import LoadTable from "components/LoadingComponent/LoadTable";

export const UsersList = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);

  const { data, isLoading, isFetching, refetch } = useQuery<
    EmployeeResponse,
    Error
  >(
    ["employees", page, rows],
    async () => {
      return await API.listAllEmployees({
        page: page,
        rows: rows
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  const createEmployee = useMutation((createEmployee: EmployeeCreate) =>
    API.createEmployee(createEmployee)
  );

  const updateEmployee = useMutation((updateEmployee: EmployeeUpdate) =>
    API.updateEmployee(updateEmployee)
  );

  const deleteEmployee = useMutation((deleteEmployee: EmployeeDelete) =>
    API.deleteEmployee(deleteEmployee)
  );

  const getRow = (row: {}) => {
    setRowData(row);
  };

  const createFormik = useEmployeeFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        createEmployee.mutate(
          {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            role_id: values.role_id,
            store_id: values.store_id,
            pos_pin: values.pos_pin,
            status: values.status
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch (e) {}
    }
  });

  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }

  const editFormik = useEmployeeFormik({
    initialValues: {
      first_name: editFormInitialValues[1],
      last_name: editFormInitialValues[2],
      email: editFormInitialValues[3],
      role_id: editFormInitialValues[4],
      store_id: editFormInitialValues[5],
      pos_pin: editFormInitialValues[6],
      status: editFormInitialValues[7] === "Active" ? "1" : "0"
    },
    onSubmit: async (values, formikHelper) => {
      try {
        updateEmployee.mutate(
          {
            user_id: editFormInitialValues[0],
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            role_id: values.role_id,
            store_id: values.store_id,
            pos_pin: values.pos_pin,
            status: values.status
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch (e) {}
    }
  });

  const filteredEmpoloyeeData = data?.users?.map((employee: any) => {
    return {
      id: employee.id,
      first_name: employee.first_name,
      last_name: employee.last_name,
      email: employee.email,
      role_name: employee.role_name,
      store_name: employee.store_name || "-",
      pos_pin: employee.pos_pin || "-",
      left_job: employee.left_job === null ? "Active" : "Deactive"
    };
  });

  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;
  return (
    <div className="UsersList">
      <>
        <EnhancedTable
          tableTitle="Users"
          columns={employeeColumns}
          rows={filteredEmpoloyeeData}
          selectedRow={getRow}
          deleteMutation={deleteEmployee}
          createForm={
            <EmployeeForm
              title="Create user"
              formik={createFormik}
              serverError={serverError}
            />
          }
          editForm={
            <EmployeeForm
              title="Edit user"
              formik={editFormik}
              shrink={{ shrink: true }}
              serverError={serverError}
            />
          }
          createFormik={createFormik}
          editFormik={editFormik}
          isSaving={createEmployee.isLoading || updateEmployee.isLoading}
          refetchTable={refetch}
          page={page}
          isLastPage={!data?.hasMore}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPage={handleRowsPerPage}
          isFetching={isFetching}
        />
        <SnackBar
          openSnackBar={openSnackBar}
          closeSnackBar={() => setOpenSnackBar(false)}
          snackBarMessage="Your changes have been successfully saved"
          snackBarType="success"
        />
      </>
    </div>
  );
};
