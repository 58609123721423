import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { SettingsFields } from "../SettingsFrom";

interface UseSettingsFormOptions {
  initialValues?: SettingsFields;
  onSubmit: (
    values: SettingsFields,
    formikHelpers: FormikHelpers<SettingsFields>
  ) => Promise<any>;
}

export const useSettingsFormik = (options: UseSettingsFormOptions) => {
  const SettingsSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "companySettings.invalidName")
      .required("companySettings.nameRequired"),
    email: Yup.string()
      .email("companySettings.invalidEmail")
      .required("companySettings.emailRequired"),
    businessNumber: Yup.number()
      .typeError("companySettings.invalidBusinessNumber")
      .min(3, "companySettings.invalidBusinessNumber")
      .max(12, "companySettings.invalidBusinessNumber")
      .required("companySettings.businessNumberRequired"),
    taxNumber: Yup.number()
      .typeError("companySettings.invalidBusinessNumber")
      .min(3, "companySettings.invalidTaxNumber")
      .max(10, "companySettings.invalidTaxNumber")
      .required("companySettings.taxNumberRequired"),
    phone: Yup.string()
      // .min(9, "companySettings.invalidPhone")
      // .max(15, "companySettings.invalidPhone")
      // .matches(
      //   /^038|044|045|046|048|049[0-9]{6}$/,
      //   "companySettings.invalidPhone"
      // )
      .required("companySettings.phoneRequired"),
    web: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "companySettings.invalidWeb"
      )
      .required("companySettings.webRequired"),
    address: Yup.string().required("companySettings.addressRequired"),
    logo: Yup.string().required("companySettings.logoRequired")
  });

  return useFormik({
    initialValues: {
      name: "",
      email: "",
      businessNumber: "",
      taxNumber: "",
      phone: "",
      web: "",
      address: "",
      logo: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    // validationSchema: SettingsSchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type SettingsFormik = ReturnType<typeof useSettingsFormik>;
