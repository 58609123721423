import { FormControl, MenuItem, Select, Box, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { startOfDay, endOfDay, addDays, subDays, subMonths } from "date-fns";
import "./FilterByDate.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./FilterByDate.scss";
import { useQueryParameters } from "lib/hooks/useQueryParameters";
import { useRouter } from "lib/hooks/useRouter";
import qs from "query-string";

interface FilterProps {
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  className: string;
  handleChange?: (event: any) => void;
}
export const FilterByDate = (props: FilterProps) => {
  const [age, setAge] = React.useState("Today");
  const { setStartDate, setEndDate } = props;
  const [startSelectDate, setStartSelectDate] = useState<Date>();
  const [endSelectDate, setEndSelectDate] = useState<Date>();
  const [isOpen, setIsOpen] = useState(false);
  const { getUrlWithQueryParams } = useQueryParameters();
  const router = useRouter();
  const queryParams = qs.parse(router.location.search) as {
    start: string;
    end: string;
    date: string;
  };
  useEffect(() => {
    if (queryParams.date && age === "") {
      setAge(queryParams.date);
    }
  }, []);

  const handleChange = (event: any) => {
    const params = getUrlWithQueryParams("date", event.target.value);
    router.history.push(`${params}`);
    setAge(event.target.value);
  };

  const yesterday = () => {
    setEndDate(
      endOfDay(subDays(new Date(), 1)).toLocaleString("en-GB").replace(",", "")
    );
    setStartDate(
      startOfDay(subDays(new Date(), 1))
        .toLocaleString("en-GB")
        .replace(",", "")
    );
  };

  const updateDate = (addDay: any, day: number) => {
    setStartDate(
      startOfDay(addDay(new Date(), day))
        .toLocaleString("en-GB")
        .replace(",", "")
    );
    setEndDate(endOfDay(new Date()).toLocaleString("en-GB").replace(",", ""));
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartSelectDate(start);
    setEndSelectDate(end);
    setStartDate(start?.toLocaleString("en-GB").replace(",", ""));
    setEndDate(end?.toLocaleString("en-GB").replace(",", ""));
  };

  const startDate = queryParams.start?.split(" ")[0];
  const endDate = queryParams.end?.split(" ")[0];
  return (
    <div>
      <Box>
        <FormControl className={props.className}>
          <Select
            value={age}
            onChange={handleChange}
            variant="outlined"
            id="store_id"
            name="store_id"
            displayEmpty
            MenuProps={{
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  marginTop: 50
                }
              }
            }}
          >
            <MenuItem value="Today" onClick={() => updateDate(addDays, 0)}>
              Today
            </MenuItem>

            <MenuItem value="Yesterday" onClick={yesterday}>
              Yesterday
            </MenuItem>

            <MenuItem value="Last-week" onClick={() => updateDate(subDays, 6)}>
              Last Week
            </MenuItem>

            <MenuItem
              value="Last-month"
              onClick={() => updateDate(subMonths, 1)}
            >
              Last Month
            </MenuItem>

            <MenuItem value="Calendar" onClick={() => setIsOpen(!isOpen)}>
              {queryParams.start &&
              queryParams.end &&
              queryParams.date === "Calendar"
                ? `${startDate}-${endDate}`
                : "Custom Range"}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {isOpen && (
        <div className="calendar">
          <DatePicker
            selected={startSelectDate}
            onChange={onChange}
            startDate={startSelectDate}
            endDate={endSelectDate}
            selectsRange
            inline
            maxDate={new Date()}
            onClickOutside={() => setIsOpen(!isOpen)}
          />
          <Button
            variant="text"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
};
