import _ from "lodash"

export const isActiveRoute = (currentPath: string, itemRoute: string[]) => {
  const splitCurrentPath = currentPath.split("/").filter((x) => x && x !== "en")
  const slicedItemPath = itemRoute[0].split("/").filter((x) => x)
  if (slicedItemPath.length > 1) {
    return _.isEqual(splitCurrentPath, slicedItemPath)
  }
  return _.isEqual(splitCurrentPath, itemRoute)
}
