import React, { useState } from "react";
import ReactDOM from "react-dom";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "./FileUploadd.scss";
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePondFile, FilePondInitialFile } from "filepond";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const FileUploadd = () => {
  const [files, setFiles] = useState<any>([]);

  return (
    <div className="App">
      <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={false}
        labelIdle='Drag & Drop your Logo or <span class="filepond--label-action">Browse</span>'
        maxFiles={1}
        onupdatefiles={(files: FilePondFile[]) => setFiles(files)}
      />
    </div>
  );
};
