import React from "react"

import { SettingsFrom } from "../../../components/SettingsForm/SettingsFrom"

export const General = () => {
  return (
    <div className="Settings">
      <SettingsFrom />
    </div>
  )
}
