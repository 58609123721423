/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import classNames from "classnames";

import { useUIContext } from "../lib/context/UIContext/UIContext";
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";

import { PrivateRoute } from "./PrivateRoute";
import { AppRoutes, RouteType } from "./Routes";
import { Header } from "../layout/Header/Header";
import { Sidebar } from "../layout/Sidebar/Sidebar";
import { SidebarRoute, SidebarRoutes } from "./SidebarRoutes";
import { Children } from "../layout/Sidebar/SidebarItem/SidebarItem";

export const AuthenticatedRoutes = () => {
  const uiCtx = useUIContext();
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  const getSidebarRoutes = () => {
    const childLessRoutes = SidebarRoutes.filter(
      (x) => x.children.length === 0
    ).map((x) => x.route);

    const childrenRoutes = SidebarRoutes.filter(
      (parent) => parent.children.length > 0
    ).flatMap((value: SidebarRoute) => {
      return value.children.map((x: Children) => ({
        ...x.route,
        path: value.route?.path + "/" + x.route.path
      }));
    });

    return [...childLessRoutes, ...childrenRoutes];
  };

  const sidebarRoutes = React.useMemo(() => getSidebarRoutes(), []);

  const authRoutes = [...AppRoutes, ...sidebarRoutes].filter(
    (appRoutes) => appRoutes.type === RouteType.PRIVATE
  );

  return (
    <>
      <div
        className={classNames({
          Container: !uiCtx.isDrawerOpen,
          ContainerWithDrawer: uiCtx.isDrawerOpen
        })}
      >
        <Header />
        <Sidebar />
        <div className="Content">
          <Switch>
            {authRoutes.map((route, index) => (
              <PrivateRoute
                {...route}
                path={`${langPrefix}/${route?.path}`}
                key={`${route?.path}-${index}`}
              />
            ))}
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </div>
    </>
  );
};
