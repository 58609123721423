import React from "react";
import { Toolbar } from "@material-ui/core";
import LogoSVG from "../../assets/images/numinco-logo-light.svg";

//styles
import "./RestrictedHeader.scss";

export const RestrictedHeader = () => {
  return (
    <div className="RestrictedHeader">
      <Toolbar>
        <div className="RestrictedHeader__logo">
          <img src={LogoSVG} alt="Numinco Logo" />
        </div>
      </Toolbar>
    </div>
  );
};
