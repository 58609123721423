import React from "react";
import { Button, Grid } from "@material-ui/core";
// import { Input } from "components/shared/Input/Input";
import { useSalesHistoryFormStyles } from "./useSalesHistoryFormStyles";
import { SaleBill } from "./SaleBill";
import { useQuery } from "react-query";
import * as API from "../../../api/Api";
import {
  Payment,
  PaymentResponse,
  PrintSale,
  PrintSaleResponse
} from "../../../api/Api";
import { SearchProducts } from "./SearchProducts";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
// import { useSaleContext } from "lib/context/SaleContext/SaleContext";

interface SalesHistoryFormProps {
  title: string;
  formik: any;
  serverError: string;
  displayRefund: boolean;
  setDisplayRefund: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface SalesHistoryFields {
  sale_id?: string;
  store_name?: string;
  store_id?: string;
  cash_register_name?: string;
  cashier_first_name?: string;
  cashier_last_name?: string;
  client_first_name?: string;
  client_last_name?: string;
  generated_sale_id?: string;
  created_at?: string;
  payment_type?: string;
  amount_total?: string;
  status?: string;
  payment_id?: string;
  discount?: string;
}

export const SalesHistoryForm = (props: SalesHistoryFormProps) => {
  const authContext = useAuthContext();

  const classes = useSalesHistoryFormStyles();
  // const [selectedItem, setSelectedItem] = useState<any>();

  // const [payments, setPayments] = useState("");

  // if (!payments) {
  //   setPayments("526f0739-9373-4040-a7b8-ceda75b2aab3");
  // }
  const {
    client_first_name,
    client_last_name,
    generated_sale_id,
    created_at,
    payment_type,
    sale_id,
    status,
    discount
  } = props.formik.values;

  const payment = useQuery<PaymentResponse, Error>("payment", async () => {
    return await API.getAllPayments();
  });
  const { data } = useQuery<PrintSaleResponse, Error>(
    ["print", sale_id],
    async () => {
      return await API.printSale({
        sale_id: sale_id || ""
      });
    }
  );
  // const handleOnChange = (e: any) => {
  //   setPayments(e.target.value);
  // };

  // const key = {
  //   first_name: "No Options",
  //   last_name: "No Options"
  // };

  return (
    <div className={classes.SalesHistoryForm}>
      <div className={classes.SalesHistoryForm__header}>
        <Grid item xs={12}>
          <h1>View Sale</h1>
          <br />
          <p>
            User:{authContext.user?.first_name}
            {authContext.user?.last_name}
          </p>
        </Grid>

        <Button
          className={classes.TableModal__Header_button}
          color="primary"
          variant="outlined"
          onClick={() => window.open(`http://${data?.raport}`)}
        >
          Print
        </Button>
        <Button
          className={classes.TableModal__Header_button}
          color="primary"
          variant="outlined"
          disabled={status === "completed" ? false : true}
          onClick={() => {
            props.setDisplayRefund(false);
          }}
        >
          Refund
        </Button>
      </div>

      <div className={classes.SalesHistoryForm__body}>
        <div className={classes.SalesHistoryForm__container}>
          <h3 className={classes.SalesHistory__title}>
            Sale History Information{" "}
          </h3>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <p className={classes.SalesHistory__info}>Document Number: </p>
              <p>{generated_sale_id}</p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.SalesHistory__info}>Created at: </p>
              <p>{created_at}</p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.SalesHistory__info}>Cashier:</p>
              <p>
                {authContext.user?.first_name +
                  " " +
                  authContext.user?.last_name}
              </p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.SalesHistory__info}>Client: </p>
              <p>{client_first_name + " " + client_last_name}</p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.SalesHistory__info}>Payment Type: </p>
              <p>{payment_type}</p>
            </Grid>
            <Grid item xs={2}>
              <p className={classes.SalesHistory__info}>Discount: </p>
              <p>{discount}</p>
            </Grid>
          </Grid>

          <SaleBill
            sale_id={props.formik.values.sale_id}
            store_id={props.formik.values.store_id}
            formik={props.formik}
            displayRefund={props.displayRefund}
            discount={discount}
          />
          <Grid item xs={12} className={classes.ServerError}>
            {props.serverError}
          </Grid>
          {/* <form
            onSubmit={props.formik.handleSubmit}
            className={classes.SalesHistoryForm__form}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  className="SalesHistoryForm__documentNumberInput"
                  id="generated_sale_id"
                  name="generated_sale_id"
                  label="Document Number"
                  variant="outlined"
                  onChange={props.formik.handleChange}
                  onBlur={props.formik.handleBlur}
                  value={generated_sale_id}
                  error={
                    props.formik.touched.generated_sale_id &&
                    !!props.formik.errors.generated_sale_id
                  }
                  helperText={
                    props.formik.touched.generated_sale_id &&
                    props.formik.errors.generated_sale_id
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="SalesHistoryForm__documentDateInput"
                  id="created_at"
                  name="created_at"
                  label="Document Date"
                  variant="outlined"
                  onChange={props.formik.handleChange}
                  onBlur={props.formik.handleBlur}
                  value={created_at}
                  error={
                    props.formik.touched.created_at &&
                    !!props.formik.errors.created_at
                  }
                  helperText={
                    props.formik.touched.created_at &&
                    props.formik.errors.created_at
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="SalesHistoryForm__cashierFirstNameInput"
                  id="cashier_first_name"
                  name="cashier_first_name"
                  label="Cashier Name"
                  variant="outlined"
                  disabled
                  value={
                    authContext.user?.first_name +
                    " " +
                    authContext.user?.last_name
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <SearchProducts
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  formik={props.formik}
                  request={API.getAllCustomerss}
                  name="Client Name"
                  obj={key}
                  getOptionLabel={(option: any) => {
                    return option.first_name + " " + option.last_name;
                  }}
                  getSelectedValue={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    props.formik.setFieldValue(
                      "client_first_name",
                      value.split(" ")[0]
                    );
                    props.formik.setFieldValue(
                      "client_last_name",
                      value.split(" ")[1]
                    );
                  }}
                  defaultValue={{
                    first_name: client_first_name,
                    last_name: client_last_name
                  }}
                />
                {/* <AutoCOMPLETE /> */}
          {/* </Grid>

              <Grid item xs={3}>
                <FormControl className={classes.SelectInput}>
                  <Select
                    disabled
                    value={payments}
                    onChange={handleOnChange}
                    variant="outlined"
                    id="store_id"
                    name="store_id"
                    MenuProps={{
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          marginTop: 55
                        }
                      }
                    }}
                  >
                    {payment.data?.payments.map((payment: Payment) => (
                      <MenuItem key={payment.id} value={payment.id}>
                        {payment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <SaleBill
                sale_id={props.formik.values.sale_id}
                store_id={props.formik.values.store_id}
                formik={props.formik}
              /> */}
          {/* <Grid item xs={12} className={classes.ServerError}>
                {props.serverError}
              </Grid>
            </Grid>
          </form>  */}
        </div>
      </div>
    </div>
  );
};
