import React, { useState } from "react";
import { SnackBar } from "components/SnackBar/SnackBar";

import { useMutation, useQuery } from "react-query";
import * as API from "../../api/Api";
import {
  SalesHistory,
  // SalesHistoryDelete,
  // SalesHistoryEdit,
  SalesHistoryResponse,
  exChangeSalesHistory,
  SaleItem
} from "../../api/Api";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
import { useSalesHistoryFormik } from "components/Forms/SalesHistory/lib/useSalesHistory";
import { salesHistoryColumns } from "components/Forms/SalesHistory/salesHistoryColumns";
import { SalesHistoryForm } from "components/Forms/SalesHistory/SalesHistoryForm";
import { FilterByStore } from "components/Filters/FilterByStore";
import { useUIContext } from "lib/context/UIContext/UIContext";
import { FilterByDate } from "components/Filters/FilterByDate";
import SaleTable from "components/SaleHistoryTable/SaleTable/SaleTable";
import { SaleContext } from "lib/context/SaleContext/SaleContext";
import { addDays, endOfDay, startOfDay } from "date-fns";
import LoadTable from "components/LoadingComponent/LoadTable";

export const SaleHistory = () => {
  const uiCtx = useUIContext();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);
  const [store_id, setStore_id] = useState("");
  const [search, setSearch] = useState("");
  const [modalData, setModalData] = useState<SalesHistory>();
  const [selectedModalType, setSelectedModalType] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [displayRefund, setDisplayRefund] = useState(true);
  const [starting_date, setStarting_date] = useState("");
  const [ending_date, setEnding_date] = useState("");

  const [checkedRefund, setCheckedRefund] = useState<Array<SaleItem>>([]);
  const [rowData, setRowData] = useState<SalesHistory[]>();

  const startDay = startOfDay(addDays(new Date(), 0))
    .toLocaleString("en-GB")
    .replace(",", "");
  const endDay = endOfDay(new Date()).toLocaleString("en-GB").replace(",", "");
  const { data, isLoading, refetch, isFetching } = useQuery<
    SalesHistoryResponse,
    Error
  >(
    ["saleHistory", page, rows, store_id, search, starting_date, ending_date],
    async () => {
      return await API.getAllSalesHistory({
        store_id: store_id || "",
        page: page,
        rows: rows,
        search: search || "",
        starting_date: starting_date || startDay,
        ending_date: ending_date || endDay
      });
    },
    { keepPreviousData: true, staleTime: 1000 }
  );
  const productSale = checkedRefund?.map((products) => {
    return {
      sku_barcode: products.sku_barcode,
      quantity: products.quantity,
      unit_id: products.unit_id,
      returned: true
    };
  });
  const refundEditQuantity = (item: SaleItem, quantity: string) => {
    const total = parseFloat(item.price) * parseFloat(quantity);
    setCheckedRefund((prev) => {
      return prev?.map((product) => {
        return product.index === item.index
          ? {
              ...product,
              quantity: quantity,
              amount_total_per_product: total.toString()
            }
          : product;
      });
    });
  };
  const handleCheckRefund = (item: SaleItem) => {
    const findeIndex = checkedRefund.find(
      (element) => element.index === item.index
    );

    if (findeIndex) {
      return setCheckedRefund((old) =>
        old.filter((oldVal) => oldVal.index !== item.index)
      );
    }
    setCheckedRefund((old) => {
      return [...old, item];
    });
  };

  const exChange = useMutation((exChange: exChangeSalesHistory) =>
    API.exChange(exChange)
  );

  const getRow = (row: SalesHistory[]) => {
    setRowData(row);
  };

  const handleOnClick = (type: string, row: SalesHistory) => {
    uiCtx.toggleModal();
    setSelectedModalType(type);
    setModalData(row);
    setDisplayRefund(true);
    setCheckedRefund([]);
    setServerError("");
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };
  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = rowData;
  }
  const editFormik = useSalesHistoryFormik({
    initialValues: {
      sale_id: editFormInitialValues.sale_id,
      generated_sale_id: editFormInitialValues.generated_sale_id,
      created_at: editFormInitialValues.created_at,
      client_first_name: editFormInitialValues.client_first_name,
      client_last_name: editFormInitialValues.client_last_name,
      payment_type: editFormInitialValues.payment_name,
      amount_total: editFormInitialValues.amount_total,
      status: editFormInitialValues.status,
      store_id: editFormInitialValues.store_id,
      discount: editFormInitialValues.discount
    },
    onSubmit: async (values, formikHelper) => {
      try {
        exChange.mutate(
          {
            buyer_id: editFormInitialValues.client_id,
            store_id: editFormInitialValues.store_id,
            cash_register_id: editFormInitialValues.cash_register_id,
            payment_id: editFormInitialValues.payment_id,
            discount: editFormInitialValues.discount,
            sale_id: editFormInitialValues.sale_id,
            saleData: productSale
          },

          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch {}
    }
  });

  const filteredCategoryData = data?.allSales.sale.map(
    (salesHistory: SalesHistory) => {
      return {
        sale_id: salesHistory.sale_id,
        generated_sale_id: salesHistory.generated_sale_id,
        created_at: salesHistory.created_at.slice(0, 10),
        client:
          salesHistory.client_first_name + " " + salesHistory.client_last_name,
        payment_type: salesHistory.payment_name,
        total: salesHistory.amount_total + "$",
        status: salesHistory.status,
        store_id: salesHistory.store_id,
        actions: (
          <>
            <EditIcon onClick={() => handleOnClick("edit", salesHistory)} />
          </>
        )
      };
    }
  );

  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;
  return (
    <div className="Category">
      <SaleContext.Provider
        value={{ refundEditQuantity, handleCheckRefund, checkedRefund }}
      >
        {isLoading ? (
          "Loading..."
        ) : (
          <>
            <SaleTable
              tableTitle="Sales History"
              columns={salesHistoryColumns}
              rows={filteredCategoryData}
              selectedRow={getRow}
              setValue={setSearch}
              displayCreate={false}
              editForm={
                <SalesHistoryForm
                  title="Edit Sale"
                  formik={editFormik}
                  serverError={serverError}
                  displayRefund={displayRefund}
                  setDisplayRefund={setDisplayRefund}
                />
              }
              editFormik={editFormik}
              isSaving={displayRefund}
              refetchTable={refetch}
              page={page}
              isLastPage={!data?.allSales.hasMore}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
              handleRowsPerPage={handleRowsPerPage}
              isFetching={isFetching}
              filterByCategory={
                <>
                  <FilterByDate
                    className="Form-control2"
                    setStartDate={(value: string) => setStarting_date(value)}
                    setEndDate={(value: string) => setEnding_date(value)}
                  />
                  <FilterByStore
                    setStore={(value: string) => setStore_id(value)}
                  />
                </>
              }
              selectType={selectedModalType}
              rowData={modalData}
              openConfirmModal={openConfirmModal}
              // deleteData={deleteData}
              handleCloseModal={handleCloseModal}
            />
            <SnackBar
              openSnackBar={openSnackBar}
              closeSnackBar={() => setOpenSnackBar(false)}
              snackBarMessage="Your changes have been successfully saved"
              snackBarType="success"
            />
          </>
        )}
      </SaleContext.Provider>
    </div>
  );
};
