import React, { useState, useEffect } from "react";
import { useLocalStorage } from "@rehooks/local-storage";

import * as API from "../../../api/Api";
import { LoginRegisterResponse } from "../../../api/Api";

import { AuthContextType, AuthContext } from "./AuthContext";
import { HandleLoadingState } from "components/HandleLoadingState/HandleLoadingState";
import { setHeaderAuthorization } from "lib/helpers/setHeaderAuthorization";
import { useErrorHandler } from "lib/hooks/useErrorHandler";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

const LOCAL_STORAGE_KEY = "NUMINCO_DASHBOARD";

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [authToken] = useLocalStorage(LOCAL_STORAGE_KEY);

  const [user, setUser] = useState<API.User | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] =
    useState<string | { [key: string]: string } | undefined>(undefined);

  const errorhandler = useErrorHandler();

  useEffect(() => {
    checkAuthentication();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    if (!authToken) {
      return;
    }
    try {
      setLoading(true);

      //-----------------------user/me request-----
      setHeaderAuthorization(authToken);
      const user = await API.getUserDetails();

      if (!user) {
        return;
      }
      setUser({
        id: user.user.id,
        first_name: user.user.first_name,
        last_name: user.user.last_name
      });
      //-----------------------user/me request-----
    } catch (e) {
      errorhandler.handleError(e);
      setError(errorhandler.error);
    } finally {
      setLoading(false);
    }
  };

  const login = (response: LoginRegisterResponse) => {
    if (!response) {
      return;
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(response.token));
    setHeaderAuthorization(response.token);
    setUser({
      id: response.user.id,
      first_name: response.user.first_name,
      last_name: response.user.last_name
    });
  };

  const logout = () => {
    // deleteFromStorage(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setUser(undefined);
    setHeaderAuthorization(null);
  };

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    error: error,
    user: user,
    login: login,
    logout: logout,
    checkAuthentication
  };

  return (
    <HandleLoadingState loading={loading}>
      <AuthContext.Provider value={context}>
        {props.children}
      </AuthContext.Provider>
    </HandleLoadingState>
  );
};
