import React from "react";
import { IconButton, TextField } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useEnhancedTableStyles } from "../EnhancedTable/useEnhancedTableStyles";

export interface EnhancedTableFooterProps {
  page?: number;
  isFetching?: boolean;
  isLastPage?: boolean;
  handleNextPage?: any;
  handleRowsPerPage?: any;
  handlePreviousPage?: any;
}

export const EnhancedTableFooter = (props: EnhancedTableFooterProps) => {
  const classes = useEnhancedTableStyles();

  return (
    <div className={"tableFooter"}>
      <div>
        Rows per page:
        <TextField
          className={"rowsPerPage"}
          select
          id="parent_id"
          name="parent_id"
          variant="standard"
          SelectProps={{
            native: true
          }}
          InputProps={{ classes }}
          onChange={(e) => {
            props.handleRowsPerPage(e.target.value);
          }}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
        </TextField>
        <IconButton
          aria-label="back"
          onClick={() => {
            props.handlePreviousPage();
          }}
          disabled={props.page === 1 || props.isFetching}
        >
          <ChevronLeftIcon />
        </IconButton>
        Page: {props.page}
        <IconButton
          aria-label="next"
          onClick={() => {
            props.handleNextPage();
          }}
          disabled={props.isLastPage || props.isFetching}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
};
