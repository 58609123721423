export const categoryColumns = [
  { id: "name", numeric: true, disablePadding: false, label: "Name" },
  { id: "slug", numeric: true, disablePadding: false, label: "Slug" },
  {
    id: "parent_id",
    numeric: false,
    disablePadding: true,
    label: "Parent category"
  },
  { id: "actions", numeric: true, disablePadding: false, label: "Actions" }
];
