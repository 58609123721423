import { apiRequest } from "./Api";

export interface Settings {
  id: number;
  key: string;
  slug_key: string;
  content: object;
  created_at: number;
  updated_at: object;
  deleted_at: object;
  media: object;
}

export interface SettingsResponse {
  settings: Settings[];
}
interface SettingsParams {
  id: string;
}

export interface EditGeneralSettings {
  settingId: any;
  content: any;
}

export const getAllSettings = async () => {
  return apiRequest<undefined, SettingsResponse>(
    "get",
    `api/admin/settings/all`
  );
};

export const getPageById = async (data: SettingsParams) =>
  apiRequest<undefined, SettingsResponse>(
    "get",
    `api/admin/settings/${data.id}`
  );
export const editPageById = async (data: any) =>
  apiRequest<undefined, EditGeneralSettings>(
    "put",
    `api/admin/settings/update`,
    data
  );
