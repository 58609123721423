import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./LoadingComponent.scss";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadChart() {
  return (
    <SkeletonTheme highlightColor="#d5dce3" baseColor="white">
      <div className="Skeleton_chart">
        <Skeleton width={"8vw"} height={"230px"} />
        <Skeleton width={"8vw"} height={"400px"} />
        <Skeleton width={"8vw"} height={"450px"} />
        <Skeleton width={"8vw"} height={"390px"} />
        <Skeleton width={"8vw"} height={"500px"} />
        <Skeleton width={"8vw"} height={"200px"} />
        <Skeleton width={"8vw"} height={"300px"} />
      </div>
    </SkeletonTheme>
  );
}
