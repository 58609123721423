import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useUIContext } from "../../lib/context/UIContext/UIContext";

import { SidebarRoute } from "../../routes/SidebarRoutes";

import { getParentObject } from "../../lib/helpers/getParentObject";

import { FirstLevelSidebar } from "./FirstLevelSidebar/FirstLevelSidebar";
import { SecondLevelSidebar } from "./SecondLevelSidebar/SecondLevelSidebar";

import classNames from "classnames";

import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

//styles
import "./Sidebar.scss";

export const Sidebar = () => {
  const uiCtx = useUIContext();
  const { pathname } = useLocation();
  const [activeObject, setActiveObject] = useState<SidebarRoute | undefined>(
    () => getParentObject(pathname)
  );

  useEffect(() => {
    setActiveObject(getParentObject(pathname));
  }, [pathname]);

  const handleCurrentPathObject = (path: string) => {
    setActiveObject(getParentObject(path));
  };

  const handleReturnToFirstLevel = () => {
    setActiveObject(getParentObject("dashboard"));
  };

  return (
    <div className="Sidebar">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={uiCtx.toggleDrawer}
        edge="start"
      >
        {uiCtx.isDrawerOpen ? (
          <ChevronLeftIcon className="CheveronLeftIcon" />
        ) : (
          <ChevronRightIcon className="CheveronRightIcon" />
        )}
      </IconButton>
      <div
        className={classNames("Sidebar__drawer", {
          closed: !uiCtx.isDrawerOpen
        })}
      >
        <FirstLevelSidebar
          setCurrentObjectPath={handleCurrentPathObject}
          returnToFirstLevel={handleReturnToFirstLevel}
        />
      </div>
      {/* TODO: Add transition to second level sidebar */}
      {activeObject && activeObject.hasSecondDrawer && (
        <div
          className={classNames("Sidebar__drawer-second", {
            closed: !uiCtx.isDrawerOpen
          })}
        >
          <SecondLevelSidebar
            activeObject={activeObject}
            setCurrentObjectPath={handleCurrentPathObject}
            returnToFirstLevel={handleReturnToFirstLevel}
          />
        </div>
      )}
    </div>
  );
};
