import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as API from "../../api/Api";

import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useLoginFormik } from "./lib/useLoginFormik";

import { Input } from "../shared/Input/Input";
import { TranslateErrorText } from "../shared/TranslateErrorText/TranslateErrorText";

import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { ErrorHandler } from "components/shared/ErrorHandler/ErrorHandler";
import { useLoginFormStyles } from "./useLoginFormStyles";

export interface LoginFields {
  email: string;
  password: string;
}

export const LoginForm = () => {
  const classes = useLoginFormStyles();
  const { t } = useTranslation();
  const authCtx = useAuthContext();

  const errorhandler = useErrorHandler();

  const formik = useLoginFormik({
    onSubmit: async (values) => {
      try {
        const res = await API.login({
          email: values.email,
          password: values.password
        });
        authCtx.login(res);
      } catch (e) {
        errorhandler.handleError(e);
      }
    }
  });

  return (
    <div className={classes.LoginForm}>
      <p className={classes.LoginForm__title}>{t("signin.title")}</p>
      <ErrorHandler error={errorhandler} />
      <form onSubmit={formik.handleSubmit}>
        <Input
          className={classes.LoginForm__input}
          id="email"
          name="email"
          label={t("signin.email")}
          variant="filled"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={
            (formik.touched.email && !!formik.errors.email) ||
            !!errorhandler.error
          }
          helperText={
            formik.touched.email && (
              <TranslateErrorText message={formik.errors.email} />
            )
          }
        />
        <Input
          className={classes.LoginForm__input}
          id="password"
          name="password"
          label={t("signin.password")}
          variant="filled"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={
            (formik.touched.password && !!formik.errors.password) ||
            !!errorhandler.error
          }
          helperText={
            formik.touched.password && (
              <TranslateErrorText message={formik.errors.password} />
            )
          }
          autoComplete={"off"}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.LoginForm__submit}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircularProgress className={classes.LoginForm__loading} />
          ) : (
            t("signin.submit")
          )}
        </Button>
      </form>
      <NavLink to="/register">{t("signin.register")}</NavLink>
    </div>
  );
};
