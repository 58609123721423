import React, { useState } from "react";

import { SnackBar } from "components/SnackBar/SnackBar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMutation, useQuery } from "react-query";
import * as API from "../../api/Api";
import {
  CustomerMutate,
  Customers,
  CustomersDelete,
  CustomersResponse
} from "../../api/Api";
import { useCustomerFormik } from "components/Forms/Customers/lib/useCustomers";
import { CustomersColumns } from "components/Forms/Customers/CustomersColumns";
import { CustomerForm } from "components/Forms/Customers/CustomerForm";
import { useUIContext } from "lib/context/UIContext/UIContext";
import SaleTable from "components/SaleHistoryTable/SaleTable/SaleTable";
import LoadTable from "components/LoadingComponent/LoadTable";
export const Customer = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState<Customers[]>();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [serverError, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);
  const [search, setSearch] = useState("");
  const [modalData, setModalData] = useState<Customers>();
  const [selectedModalType, setSelectedModalType] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<{
    rowName: string;
    rowId: string;
  }>({
    rowName: "",
    rowId: ""
  });

  const { data, isLoading, refetch, isFetching } = useQuery<
    CustomersResponse,
    Error
  >(
    ["customers", page, rows, search],

    async () => {
      return await API.getAllCustomers({
        page: page,
        rows: rows
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );
  const createCustomer = useMutation((createCustomer: CustomerMutate) =>
    API.createCustomer(createCustomer)
  );

  const editCustomer = useMutation((editCustomer: CustomerMutate) =>
    API.editCustomer(editCustomer)
  );

  const deleteCustomer = useMutation((deleteCustomer: CustomersDelete) =>
    API.deleteCustomers(deleteCustomer)
  );

  // getROw is used to get clicked row data and use these data in edit form
  const getRow = (row: Customers[]) => {
    setRowData(row);
  };
  const handleOnClick = (type: string, row: Customers) => {
    uiCtx.toggleModal();
    setSelectedModalType(type);
    setModalData(row);
  };
  const confirmModalProps = (
    closeModal: boolean,
    rowName: string,
    rowId: string
  ) => {
    setOpenConfirmModal(closeModal);
    setDeleteData({
      rowName,
      rowId
    });
  };
  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };

  const createFormik = useCustomerFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        createCustomer.mutate(
          {
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            email: values.email,
            address: values.address,
            discount: values.discount,
            is_business: values.is_business,
            business_number: values.business_number,
            tax_number: values.tax_number
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch (e) {}
    }
  });

  // editFormInitialValues returns array of row values used for initialValues in edit form
  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = Object.values(rowData);
  }

  const editFormik = useCustomerFormik({
    initialValues: {
      id: editFormInitialValues[0],
      first_name: editFormInitialValues[1],
      last_name: editFormInitialValues[2],
      phone_number: editFormInitialValues[4] || "-",
      email: editFormInitialValues[3],
      address: editFormInitialValues[8] || "-",
      discount: editFormInitialValues[16],
      is_business: editFormInitialValues[5] === "Not a Business" ? false : true,
      business_number: editFormInitialValues[6] || "-",
      tax_number: editFormInitialValues[7] || "-"
    },
    onSubmit: async (values, formikHelper) => {
      
      try {
        editCustomer.mutate(
          {
            buyer_id: values.id,
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            email: values.email,
            address: values.address,
            discount: values.discount,
            is_business: values.is_business,
            business_number: values.business_number,
            tax_number: values.tax_number
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(error.response.data.message);
            }
          }
        );
      } catch {}
    }
  });

  // filteredCategoryData populates table rows
  const filteredCategoryData = data?.buyers?.map((customers: Customers) => {
    return {
      id: customers.id,
      first_name: customers.first_name,
      last_name: customers.last_name,
      phone_number: customers.phone_number || "-",
      email: customers.email,
      address: customers.address || "-",
      discount: customers.discount,
      is_business: customers.is_business ? "Is Business" : "Not a Business",
      business_number: customers.business_number || "-",
      tax_number: customers.tax_number || "-",
      actions: (
        <>
          <EditIcon onClick={() => handleOnClick("edit", customers)} />
          <DeleteIcon
            color="secondary"
            onClick={() =>
              confirmModalProps(true, customers.first_name, customers.id)
            }
          />
        </>
      )
    };
  });

  //handleRowsPerPage, handlePreviousPage and handleNextPage methods are used for pagination
  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;

  return (
    <div className="Category">
      <>
        <SaleTable
          tableTitle="Customers"
          columns={CustomersColumns}
          rows={filteredCategoryData}
          selectedRow={getRow}
          deleteMutation={deleteCustomer}
          setValue={setSearch}
          editForm={
            <CustomerForm
              title="Edit Customer"
              formik={editFormik}
              serverError={serverError}
            />
          }
          createForm={
            <CustomerForm
              title="Create Customer"
              formik={createFormik}
              serverError={serverError}
            />
          }
          createFormik={createFormik}
          editFormik={editFormik}
          isSaving={editCustomer.isLoading}
          refetchTable={refetch}
          page={page}
          isLastPage={!data?.hasMore}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPage={handleRowsPerPage}
          isFetching={isFetching}
          selectType={selectedModalType}
          rowData={modalData}
          openConfirmModal={openConfirmModal}
          deleteData={deleteData}
          handleCloseModal={handleCloseModal}
          setSelectType={setSelectedModalType}
        />
        <SnackBar
          openSnackBar={openSnackBar}
          closeSnackBar={() => setOpenSnackBar(false)}
          snackBarMessage="Your changes have been successfully saved"
          snackBarType="success"
        />
      </>
    </div>
  );
};
