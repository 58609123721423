import Axios from "axios";

export async function apiRequest<D = {}, R = unknown>(
  method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch",
  path: string,
  input?: D,
  options?: any
) {
  const res = await Axios.request<R>({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: method,
    data: input,
    headers: options
  });
  return res.data;
}

export * from "./User";
export * from "./Employees";
export * from "./Stores";
export * from "./Roles";
export * from "./Products";
export * from "./Units";
export * from "./Taxes";
export * from "./Variations";
export * from "./Categories";
export * from "./Prices";
export * from "./Customers";
export * from "./SalesHistory";
export * from "./Payments";
export * from "./Dashboard";
export * from "./SettingsEcommerce";
