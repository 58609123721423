import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React from "react";

interface SnackBarProps {
  openSnackBar: boolean;
  closeSnackBar: () => void;
  snackBarMessage: string;
  snackBarType: Color;
}

export const SnackBar = (props: SnackBarProps) => {
  return (
    <Snackbar
      open={props.openSnackBar}
      autoHideDuration={5000}
      onClose={props.closeSnackBar}
    >
      <Alert
        variant="filled"
        onClose={props.closeSnackBar}
        severity={props.snackBarType}
      >
        {props.snackBarMessage}
      </Alert>
    </Snackbar>
  );
};
