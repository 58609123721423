import { useProductFormik } from "components/Forms/Products/lib/useProductFormik";
import { productColumns } from "components/Forms/Products/productColumns";
import { ProductForm } from "components/Forms/Products/ProductForm";
import SaleTable from "components/SaleHistoryTable/SaleTable/SaleTable";
import { SnackBar } from "components/SnackBar/SnackBar";
import { useUIContext } from "lib/context/UIContext/UIContext";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as API from "../../api/Api";
import {
  Product,
  ProductCreate,
  ProductResponse,
  ProductUpdate
} from "../../api/Api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadTable from "components/LoadingComponent/LoadTable";

export const Products = () => {
  const uiCtx = useUIContext();
  const [rowData, setRowData] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [, setServerError] = useState("");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(5);
  const [search, setSearch] = useState("");
  const [modalData, setModalData] = useState<any>();
  const [selectedModalType, setSelectedModalType] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<{
    rowName: string;
    rowId: string;
  }>({
    rowName: "",
    rowId: ""
  });
  const { data, isLoading, refetch, isFetching } = useQuery<
    ProductResponse,
    Error
  >(
    ["products", page, rows, search],
    async () => {
      return await API.getAllProducts({
        page: page,
        rows: rows,
        search: search || ""
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  const createProduct = useMutation((createProduct: ProductCreate) =>
    API.createProduct(createProduct)
  );

  const updateProduct = useMutation((updateProduct: ProductUpdate) =>
    API.updateProduct(updateProduct)
  );

  const deleteProduct = useMutation((deleteProduct: any) =>
    API.deleteProduct(deleteProduct)
  );

  const getRow = (row: {}) => {
    setRowData(row);
  };
  const handleOnClick = (type: string, row: Product) => {
    uiCtx.toggleModal();
    setSelectedModalType(type);
    setModalData(row);
  };
  const confirmModalProps = (
    closeModal: boolean,
    rowName: string,
    rowId: string
  ) => {
    setOpenConfirmModal(closeModal);
    setDeleteData({
      rowName,
      rowId
    });
  };
  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };
  const createFormik = useProductFormik({
    onSubmit: async (values, formikHelper) => {
      try {
        let formData = new FormData();
        formData.append("file", JSON.stringify(values.media));
        createProduct.mutate(
          {
            name: values.product_name,
            tax_id: values.tax,
            sku_barcode: values.sku_barcode,
            category_id: values.category_name,
            retail_price: values.retail_price,
            supply_price: values.supply_price,
            image: formData,
            unitData: {
              unit_id: values.unit.toString(),
              discount: values.discount.toString()
            },
            stores: [{ store_id: "79762054-3f17-40de-8834-99464617d047" }],
            use_price: true,
            variations: values.variations,
            sku_code: values.sku_code,
            description: values.description
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch (e) {}
    }
  });

  let editFormInitialValues: any = [];
  if (rowData !== undefined) {
    editFormInitialValues = rowData;
  }
  const editFormik = useProductFormik({
    initialValues: {
      id: editFormInitialValues.id,
      product_name: editFormInitialValues.product_name,
      sku_barcode: editFormInitialValues.sku_barcode,
      category_name: editFormInitialValues.category_id,
      unit: editFormInitialValues.unit_id,
      discount: "0",
      tax: editFormInitialValues.tax_id,
      retail_price: editFormInitialValues.retail_price,
      supply_price: editFormInitialValues.supply_price,
      variations: editFormInitialValues.variations,
      sku_code: editFormInitialValues.sku_code,
      description: editFormInitialValues.description,
      media: editFormInitialValues.media
    },

    onSubmit: async (values, formikHelper) => {
      try {
        updateProduct.mutate(
          {
            product_id: editFormInitialValues.id,
            name: values.product_name,
            tax_id: values.tax,
            sku_barcode: values.sku_barcode,
            category_id: values.category_name,
            unitData: {
              unit_id: values.unit.toString(),
              discount: values.discount.toString()
            },

            retail_price: values.retail_price,
            supply_price: values.supply_price,
            variations: values.variations,
            sku_code: values.sku_code,
            description: values.description
          },
          {
            onSuccess: async () => {
              refetch();
              formikHelper.resetForm();
              uiCtx.toggleModal();
              setOpenSnackBar(true);
            },
            onError: (error: any) => {
              setServerError(
                Object.values(error.response.data.errors).toString()
              );
            }
          }
        );
      } catch (e) {}
    }
  });

  const filteredProductData = data?.products?.map((product: Product) => {
    return {
      id: product.id,
      product_name: product.product_name,
      sku_barcode: product.sku_barcode,
      category_name: product.category_name,
      retail_price: `${product.retail_price} €`,
      supply_price: `${product.supply_price} €`,
      description: product.description,
      actions: (
        <>
          <EditIcon onClick={() => handleOnClick("edit", product)} />
          <DeleteIcon
            color="secondary"
            onClick={() =>
              confirmModalProps(true, product.category_name, product.id)
            }
          />
        </>
      )
    };
  });

  const handleRowsPerPage = (e: any) => {
    setRows(e);
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };
  if (isLoading) return <LoadTable />;
  return (
    <div className="Products">
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <SaleTable
            tableTitle="Products"
            columns={productColumns}
            rows={filteredProductData}
            selectedRow={getRow}
            setValue={setSearch}
            deleteMutation={deleteProduct}
            createForm={
              <ProductForm title="Create Product" formik={createFormik} />
            }
            editForm={<ProductForm title="Edit Product" formik={editFormik} />}
            createFormik={createFormik}
            editFormik={editFormik}
            isSaving={createProduct.isLoading || updateProduct.isLoading}
            refetchTable={refetch}
            page={page}
            isLastPage={!data?.hasMore}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPage={handleRowsPerPage}
            isFetching={isFetching}
            setSelectType={setSelectedModalType}
            selectType={selectedModalType}
            rowData={modalData}
            openConfirmModal={openConfirmModal}
            deleteData={deleteData}
            handleCloseModal={handleCloseModal}
          />
          <SnackBar
            openSnackBar={openSnackBar}
            closeSnackBar={() => setOpenSnackBar(false)}
            snackBarMessage="Your changes have been successfully saved"
            snackBarType="success"
          />
        </>
      )}
    </div>
  );
};
