import React from "react";

import { LoginForm } from "../../components/LoginForm/LoginForm";
import { useLoginStyles } from "./useLoginStyles";

export const Login = () => {
  const classes = useLoginStyles();
  return (
    <div className={classes.Login}>
      <LoginForm />
    </div>
  );
};
