import { useTabContext } from "lib/context/TabContext.tsx/TabContext";
import React from "react";
import { useTabStyle } from "./useTabStyle";
import cs from "classnames";

interface Props {
  children: React.ReactNode;
  id: string;
}

export const TabPanel = ({ children, id }: Props) => {
  const { activeId } = useTabContext();
  return <div className="TabPanel">{activeId === id && children}</div>;
};

export const Tab = ({ children, id }: Props) => {
  const { activeId } = useTabContext();
  const { handleClick } = useTabContext();
  const classes = useTabStyle();
  const selectTab = () => {
    handleClick(id);
  };

  return (
    <button
      className={cs(classes.Tab, activeId === id && classes.Tab__active)}
      onClick={selectTab}
    >
      {children}
    </button>
  );
};
