import { apiRequest } from "./Api";

export interface ProductType {
  product_id: string;
  product_name: string;
  sku_barcode: string;
  unit: string;
  store: string;
  store_name?: string;
  supply_price: string;
  mark_up: string;
  untaxed_retail_price: string;
  tax: string;
  retail_price: string;
}

export interface PriceUpdate {
  products: [
    {
      product_id: string;
      retail_price: string;
    }
  ];
}

export interface PriceResponse {
  allStores: ProductType[];
}

interface PriceParams {
  rows: number;
  page: number;
  store_id?: string;
}

export interface AllPricesResponse {
  prices: {
    hasMore: boolean;
    products: ProductType[];
  };
}

export const getAllPrices = async (data: PriceParams) =>
  apiRequest<undefined, AllPricesResponse>(
    "get",
    `api/admin/product/prices?rows=${data.rows}&page=${data.page}${
      data.store_id && `&store_id=${data.store_id}`
    }`
  );

export const updatePrice = async (data: PriceUpdate) =>
  apiRequest<PriceUpdate, PriceResponse>(
    "put",
    "api/admin/product/change-price",
    data
  );
