import React, { useState } from "react";
import { UIContext, UIContextType } from "./UIContext";

interface UIContextProviderProps {
  children: React.ReactNode | null;
}

export const UIContextProvider = (props: UIContextProviderProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const context: UIContextType = {
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    toggleDrawer,

    isModalOpen,
    toggleModal
  };

  return (
    <UIContext.Provider value={context}>{props.children}</UIContext.Provider>
  );
};
