import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

export interface PriceFields {
  product_id: string;
  product_name: string;
  sku_barcode: string;
  unit: string;
  store: string;
  supply_price: string;
  mark_up: string;
  untaxed_retail_price: string;
  tax_rate: string;
  retail_price: string;
}

interface UsePriceFormOptions {
  initialValues?: PriceFields;
  onSubmit: (
    values: PriceFields,
    formikHelpers: FormikHelpers<PriceFields>
  ) => Promise<any>;
}

export const usePriceFormik = (options: UsePriceFormOptions) => {
  const PriceSchema = Yup.object().shape({
    mark_up: Yup.number()
      .typeError("Please enter only numbers")
      .required("Mark up is required")
  });

  return useFormik({
    initialValues: {
      product_id: "",
      product_name: "",
      sku_barcode: "",
      unit: "",
      store: "",
      supply_price: "",
      mark_up: "",
      untaxed_retail_price: "",
      tax_rate: "",
      retail_price: ""
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: PriceSchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type PriceFormik = ReturnType<typeof usePriceFormik>;
