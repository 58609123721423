import React from "react"
import { Route, RouteProps, useLocation, Redirect } from "react-router-dom"

import { useAuthContext } from "../lib/context/AuthContext/AuthContext"
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext"

import { removeLangFromUrl } from "../lib/helpers/removeLangFormUrl"

export const PrivateRoute = (props: RouteProps) => {
  const authCtx = useAuthContext()
  const langCtx = useLanguageContext()
  const location = useLocation()

  if (!authCtx.isAuthenticated) {
    const url = removeLangFromUrl(location.pathname)
    return (
      <Redirect
        to={`${langCtx.getLangPrefix()}login?redirect=${encodeURIComponent(
          url
        )}`}
      />
    )
  }

  return <Route {...props} />
}
