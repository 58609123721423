import { makeStyles } from "@material-ui/core";

export const useTabStyle = makeStyles((theme) => ({
  Tab: {
    border: "none",
    margin: "20px",
    fontSize: "16px",
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "#0d1940 solid"
    }
  },
  Tab_ul: {
    display: "flex",
    flexDirection: "row"
  },
  Tab__active: {
    borderBottom: "#0d1940 solid"
  }
}));
