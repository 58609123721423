import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { EmployeeFields } from "../EmployeeForm";

interface UseEmployeeFormOptions {
  initialValues?: EmployeeFields;
  onSubmit: (
    values: EmployeeFields,
    formikHelpers: FormikHelpers<EmployeeFields>
  ) => Promise<any>;
}

export const useEmployeeFormik = (options: UseEmployeeFormOptions) => {
  const EmployeeSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required!"),
    last_name: Yup.string().required("Last name is required!"),
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required!"),
    role_id: Yup.string().required("Role is required!"),
    store_id: Yup.string().required("Store is required!"),
    pos_pin: Yup.string()
      .min(4, "Pos pin must have 4 characters")
      .max(4, "Pos pin must have 4 characters")
      .required("Pos pin is required!"),
    status: Yup.string().required("Status is required!")
  });

  return useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      role_id: "",
      store_id: "",
      pos_pin: "",
      status: "1"
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EmployeeSchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type EmployeeFormik = ReturnType<typeof useEmployeeFormik>;
