import React from "react";
import { Redirect, Switch } from "react-router-dom";

import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";

import { AppRoutes, RouteType } from "./Routes";
import { RestrictedRoute } from "./RestrictedRoute";
import { RestrictedHeader } from "../layout/RestrictedHeader/RestrictedHeader";

export const RestrictedRoutes = () => {
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  const restrictedRoutes = AppRoutes.filter(
    (appRoutes) => appRoutes.type === RouteType.RESTRICTED
  );

  return (
    <>
      <RestrictedHeader />
      <Switch>
        {restrictedRoutes.map((route, index) => {
          return (
            <RestrictedRoute
              {...route}
              path={`${langPrefix}/${route.path}`}
              key={`${route.path}-${index}`}
            />
          );
        })}

        <Redirect to={`/login`} />
      </Switch>
    </>
  );
};
