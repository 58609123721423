import React from "react";

import { useTranslation } from "react-i18next";
import { UseErrorHandler } from "lib/hooks/useErrorHandler";
import { useErrorHandlerStyles } from "./useErrorHandlerStyles";

interface Props {
  error: UseErrorHandler;
}

export const ErrorHandler = (props: Props) => {
  const { t } = useTranslation();
  const error = props.error;
  const classes = useErrorHandlerStyles();
  const invalidCredentials =
    error.error === "Invalid credentials!"
      ? t("signin.wrongcredentials")
      : error.error;
  return (
    <div className="ErrorHandler">
      <p className={classes.ErrorHandlerError}>{invalidCredentials}</p>
    </div>
  );
};
