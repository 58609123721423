import React, { useState } from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { Input } from "components/shared/Input/Input";
import { useCustomerFormStyles } from "./useCustomersFormStyles";

interface CustomerFormProps {
  title: string;
  formik: any;
  serverError: string;
}

export interface CustomerFields {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  discount: string;
  address: string;
  is_business: boolean;
  business_number: string;
  tax_number: string;
}

export const CustomerForm = (props: CustomerFormProps) => {
  const classes = useCustomerFormStyles();
  const [state, setState] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(!state);
  };

  return (
    <div className={classes.CustomerForm}>
      <h1 className={classes.title}>{props.title}</h1>
      <form
        onSubmit={props.formik.handleSubmit}
        className={classes.CustomerForm__form}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__firstNameInput"
              id="first_name"
              name="first_name"
              label="First Name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.first_name}
              error={
                props.formik.touched.first_name &&
                !!props.formik.errors.first_name
              }
              helperText={
                props.formik.touched.first_name &&
                props.formik.errors.first_name
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__lastNameInput"
              id="last_name"
              name="last_name"
              label="Last Name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.last_name}
              error={
                props.formik.touched.last_name &&
                !!props.formik.errors.last_name
              }
              helperText={
                props.formik.touched.last_name && props.formik.errors.last_name
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__phoneNumberInput"
              id="phone_number"
              name="phone_number"
              label="Phone Number"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.phone_number}
              error={
                props.formik.touched.phone_number &&
                !!props.formik.errors.phone_number
              }
              helperText={
                props.formik.touched.phone_number &&
                props.formik.errors.phone_number
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__emailInput"
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.email}
              error={props.formik.touched.email && !!props.formik.errors.email}
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__addressInput"
              id="address"
              name="address"
              label="Address"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.address}
              error={
                props.formik.touched.address && !!props.formik.errors.address
              }
              helperText={
                props.formik.touched.address && props.formik.errors.address
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__discountInput"
              id="discount"
              name="discount"
              label="Discount"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.discount}
              error={
                props.formik.touched.discount && !!props.formik.errors.discount
              }
              helperText={
                props.formik.touched.discount && props.formik.errors.discount
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={state}
                  onChange={(e) => {
                    handleChange(e);
                    props.formik.setFieldValue("is_business", !state);
                  }}
                  name="checkedA"
                />
              }
              label="Is Business"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__businessNumberInput"
              id="business_number"
              name="business_number"
              label="Business Number"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.business_number}
              error={
                props.formik.touched.business_number &&
                !!props.formik.errors.business_number
              }
              helperText={
                props.formik.touched.business_number &&
                props.formik.errors.business_number
              }
              disabled={!state}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="CustomerForm__taxNumberInput"
              id="tax_number"
              name="tax_number"
              label="Tax Number"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.tax_number}
              error={
                props.formik.touched.tax_number &&
                !!props.formik.errors.tax_number
              }
              helperText={
                props.formik.touched.tax_number &&
                props.formik.errors.tax_number
              }
              disabled={!state}
            />
          </Grid>
          <Grid item xs={12} className={classes.ServerError}>
            {props.serverError}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
