import React from "react";
import "./LoadingComponent.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { CircularProgress } from "@material-ui/core";

export default function LoadPieChart() {
  return (
    <div className="Skeleton_Pie">
      <CircularProgress
        style={{ width: "350px", height: "350px", marginTop: "7%" }}
      />
    </div>
  );
}
