import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./LoadingComponent.scss";
export default function LoadTable() {
  return (
    <SkeletonTheme highlightColor="#d5dce3" baseColor="white">
      <div className="Skeleton_title">
        <Skeleton width={"7vw"} height={40} />
        <div className="Skeleton_filter">
          <Skeleton width={"14vw"} height={40} />
          <Skeleton width={"14vw"} height={40} />
          <Skeleton width={"14vw"} height={40} />
        </div>
      </div>

      <div className="Skeleton_table">
        <Skeleton count={6} width={"16vw"} height={50} />
        <Skeleton count={6} width={"16vw"} height={50} />
        <Skeleton count={6} width={"8vw"} height={50} />
        <Skeleton count={6} width={"8vw"} height={50} />
        <Skeleton count={6} width={"8vw"} height={50} />
        <Skeleton count={6} width={"8vw"} height={50} />
      </div>
    </SkeletonTheme>
  );
}
