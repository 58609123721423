export const priceColumns = [
  {
    id: "product_name",
    numeric: false,
    disablePadding: true,
    label: "Item"
  },
  {
    id: "sku_barcode",
    numeric: true,
    disablePadding: false,
    label: "SKU barcode"
  },
  { id: "unit", numeric: true, disablePadding: false, label: "Unit" },
  {
    id: "store",
    numeric: true,
    disablePadding: false,
    label: "Store"
  },
  {
    id: "supply_price",
    numeric: true,
    disablePadding: false,
    label: "Supply price"
  },
  {
    id: "mark_up",
    numeric: true,
    disablePadding: false,
    label: "Mark-up %"
  },
  {
    id: "untaxed_retail_price",
    numeric: true,
    disablePadding: false,
    label: "Retail price"
  },
  {
    id: "tax_rate",
    numeric: true,
    disablePadding: false,
    label: "Tax rate"
  },
  {
    id: "retail_price",
    numeric: true,
    disablePadding: false,
    label: "Retail price (Inc. Tax)"
  },
  { id: "actions", numeric: true, disablePadding: false, label: "Actions" }
];
