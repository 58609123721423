import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import * as API from "../../api/Api";
import { Settings, SettingsResponse } from "../../api/Api";
import "./FilterByDate.scss";
interface FilterByPageProps {
  onChange?: (e: any) => void;
  page: string;
  className?: string;
  Link?: string;
}

export const FilterByPage = (props: FilterByPageProps) => {
  const { data } = useQuery<SettingsResponse, Error>(
    "pages",
    async () => {
      return await API.getAllSettings();
    },
    { refetchOnWindowFocus: false }
  );

  // const conditions = ["link", "Color", "Question", "Answer"];
  // const pages = data?.settings;
  // pages?.filter((item) => {
  //   conditions.forEach((condition) => {
  //     if (item.key.includes(condition)) {
  //       let index = pages?.indexOf(item);
  //       pages.splice(index, 1);
  //     }
  //   });
  // });

  // console.log(data)
  // useEffect(() => {
  //   alert("test");
  // }, []);

  // const Link = data?.settings.filter((key) =>
  //   key.slug_key.includes("link") ? key : null
  // );
  // const logo = data?.settings.filter(
  //   (key) => key.slug_key.includes("logo") && !key.slug_key.includes("color")
  // );
  // const color = data?.settings.filter((key) => key.slug_key.includes("color"));
  return (
    <>
      <FormControl className={props.className}>
        <Select
          value={props.page}
          onChange={props.onChange}
          variant="outlined"
          id="store_id"
          name="store_id"
          MenuProps={{
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                marginTop: 50
              }
            }
          }}
        >
          <MenuItem>Select</MenuItem>
          {data?.settings.map((settings: Settings) => (
            <MenuItem key={settings.id} value={settings.id}>
              {settings.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
