import React, { useState } from "react";

import { SnackBar } from "components/SnackBar/SnackBar";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutationResult
} from "react-query";

import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirmModalStyles } from "./useConfirmModalStyles";
import { Modal, Backdrop, Fade, Button } from "@material-ui/core";
import { Color } from "@material-ui/lab";

interface ConfirmModalProps {
  open?: boolean;
  close?: (() => void | undefined) | undefined;
  deleteData?: { rowName: string; rowId: string };
  refetchTable?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<any, Error>>;
  deleteMutation?: UseMutationResult<any, unknown, any, unknown>;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const classes = useConfirmModalStyles();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState<Color>("info");
  const handleClose = () => {
    if (!props.close) return;
    props.close();
  };
  if (!props.open) return <p></p>;

  return (
    <div className="modal">
      <Modal
        className={classes.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <h2>
              Are you sure you want to delete {props.deleteData?.rowName}?
            </h2>
            <div className={classes.buttonArea}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  props.deleteMutation &&
                    props.deleteMutation.mutate(
                      {
                        id: props.deleteData?.rowId
                      },
                      {
                        onSuccess: async () => {
                          handleClose();
                          if (props.refetchTable) {
                            props.refetchTable();
                          }

                          setSnackBarMessage(
                            "Your changes have been successfully saved"
                          );
                          setSnackBarType("success");
                          setOpenSnackBar(true);
                        },
                        onError: (error: any) => {
                          setSnackBarMessage(error.response.data.message);
                          setSnackBarType("error");
                          setOpenSnackBar(true);
                        }
                      }
                    );
                }}
              >
                Delete
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="default"
                onClick={handleClose}
              >
                Not now
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <SnackBar
        openSnackBar={openSnackBar}
        closeSnackBar={() => setOpenSnackBar(false)}
        snackBarMessage={snackBarMessage}
        snackBarType={snackBarType}
      />
    </div>
  );
}
