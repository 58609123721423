import { RouteProps } from "react-router-dom";
import { RouteType } from "./Routes";
import { SaleHistory as SalesHistory } from "../pages/SaleHistory/SaleHistory";
import { CashRegister } from "../pages/CashRegister/CashRegister";
import { Customer as Customers } from "../pages/Customer/Customer";
import { Products } from "../pages/Products/Products";
import { Categories } from "../pages/Category/Categories";
import { Price } from "../pages/Price/Price";
import { PurchaseList } from "../pages/PurchaseList/PurchaseList";
import { Supplier } from "../pages/Supplier/Supplier";
import { Sale } from "../pages/Sale/Sale";
import { Purchase } from "../pages/Purchase/Purchase";
import { Product } from "../pages/Product/Product";
import { Subjects } from "../pages/Subjects/Subjects";
import { UsersList } from "../pages/UserList/UsersList";
import { Role } from "../pages/Role/Role";
import { General } from "../pages/Settings/General/General";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Stores } from "../pages/Settings/Stores/Stores";
import { Users } from "../pages/Settings/Users/Users";
import { Features } from "../pages/Settings/Features/Features";
import { PaymentType } from "../pages/Settings/PaymentType/PaymentType";
import { Taxes } from "../pages/Settings/Taxes/Taxes";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";
import StoreIcon from "@material-ui/icons/Store";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PaymentIcon from "@material-ui/icons/Payment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { SettingsEcommerce } from "pages/SettingsEcommerce/SettingsEcommerce";

export interface SidebarRoute extends RouteProps {
  id: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  hasSecondDrawer?: boolean;
  exact?: boolean;
  children?: any;
  route: {
    type?: RouteType;
    path?: string;
    component?: () => JSX.Element;
    icon?: OverridableComponent<SvgIconTypeMap>;
  };
}

export const SidebarRoutes: SidebarRoute[] = [
  {
    id: "sidebar-dashboard",
    title: "sidebar.dashboard",
    icon: DashboardIcon,
    children: [],
    route: {
      type: RouteType.PRIVATE,
      path: "dashboard",
      component: Dashboard
    }
  },
  // {
  //   id: "my-profile",
  //   title: "sidebar.profile",
  //   icon: DashboardIcon,
  //   excludeSidebar: true,
  //   children: [],
  //   route: {
  //     type: RouteType.PRIVATE,
  //     path: "my-profile",
  //     component: Profile
  //   }
  // },
  {
    id: "sidebar-sale",
    title: "sidebar.sale",
    icon: MonetizationOnOutlinedIcon,
    route: {
      type: RouteType.PRIVATE,
      path: "sale"
    },
    children: [
      {
        id: "sidebar-child-salehistory",
        title: "sidebar.salehistroy",
        route: {
          type: RouteType.PRIVATE,
          path: "sale-history",
          component: SalesHistory
        }
      },
      {
        id: "sidebar-child-cashregister",
        title: "sidebar.cashregister",
        route: {
          type: RouteType.PRIVATE,
          path: "cash-register",
          component: CashRegister
        }
      },
      {
        id: "sidebar-child-costumer",
        title: "sidebar.customer",
        route: {
          type: RouteType.PRIVATE,
          path: "customer",
          component: Customers
        }
      }
    ]
  },
  {
    id: "sidebar-products",
    title: "sidebar.products",
    icon: LocalOfferOutlinedIcon,
    route: {
      type: RouteType.PRIVATE,
      path: "products"
    },
    children: [
      {
        id: "sidebar-child-products",
        title: "sidebar.products",
        route: {
          type: RouteType.PRIVATE,
          path: "products",
          component: Products
        }
      },
      {
        id: "sidebar-child-productcateogry",
        title: "sidebar.productcateogry",
        route: {
          type: RouteType.PRIVATE,
          path: "category",
          component: Categories
        }
      },
      {
        id: "sidebar-child-productprice",
        title: "sidebar.productprice",
        route: {
          type: RouteType.PRIVATE,
          path: "price",
          component: Price
        }
      }
    ]
  },
  {
    id: "sidebar-purchase",
    title: "sidebar.purchase",
    icon: ShoppingBasketOutlinedIcon,
    route: {
      type: RouteType.PRIVATE,
      path: "purchase"
    },
    children: [
      {
        id: "sidebar-child-purchaselist",
        title: "sidebar.purchaselist",
        route: {
          type: RouteType.PRIVATE,
          path: "purchase-list",
          component: PurchaseList
        }
      },
      {
        id: "sidebar-child-purchasesuppliers",
        title: "sidebar.purchasesuppliers",
        route: {
          type: RouteType.PRIVATE,
          path: "supplier",
          component: Supplier
        }
      }
    ]
  },
  {
    id: "sidebar-subjects",
    title: "sidebar.subjects",
    icon: StorefrontOutlinedIcon,
    route: {
      type: RouteType.PRIVATE,
      path: "subjects"
    },
    children: [
      {
        id: "sidebar-child-subjectcustomers",
        title: "sidebar.subjectcustomers",
        route: {
          type: RouteType.PRIVATE,
          path: "customer",
          component: Customers
        }
      },
      {
        id: "sidebar-child-subjectsuppliers",
        title: "sidebar.subjectsuppliers",
        route: {
          type: RouteType.PRIVATE,
          path: "supplier",
          component: Supplier
        }
      }
    ]
  },
  //#TODO Reports
  // {
  //   id: "sidebar-reports",
  //   title: "sidebar.reports",
  //   icon: AssessmentOutlinedIcon,
  //   route: {
  //     type: RouteType.PRIVATE,
  //     path: "reports"
  //   },
  //   children: [
  //     {
  //       id: "sidebar-child-reportsale",
  //       title: "sidebar.reportsale",
  //       route: {
  //         type: RouteType.PRIVATE,
  //         path: "sale",
  //         component: Sale
  //       }
  //     },
  //     {
  //       id: "sidebar-child-reportpurchase",
  //       title: "sidebar.reportpurchase",
  //       route: {
  //         type: RouteType.PRIVATE,
  //         path: "purchase",
  //         component: Purchase
  //       }
  //     },
  //     {
  //       id: "sidebar-child-reportproduct",
  //       title: "sidebar.reportproduct",
  //       route: {
  //         type: RouteType.PRIVATE,
  //         path: "product",
  //         component: Product
  //       }
  //     },
  //     {
  //       id: "sidebar-child-reportsubject",
  //       title: "sidebar.reportsubject",
  //       route: {
  //         type: RouteType.PRIVATE,
  //         path: "subjects",
  //         component: Subjects
  //       }
  //     }
  //   ]
  // },
  {
    id: "sidebar-users",
    title: "sidebar.users",
    icon: PeopleAltOutlinedIcon,
    route: {
      type: RouteType.PRIVATE,
      path: "users"
    },
    children: [
      {
        id: "sidebar-child-userslist",
        title: "sidebar.userslist",
        route: {
          type: RouteType.PRIVATE,
          path: "user-list",
          component: UsersList
        }
      },
      //#TODO User roles
      // {
      //   id: "sidebar-child-usersrole",
      //   title: "sidebar.usersrole",
      //   route: {
      //     type: RouteType.PRIVATE,
      //     path: "role",
      //     component: Role
      //   }
      // }
    ]
  },
  {
    id: "settings",
    title: "sidebar.settings",
    icon: SettingsOutlinedIcon,
    hasSecondDrawer: true,
    route: {
      type: RouteType.PRIVATE,
      path: "settings",
      component: General
    },
    children: [
      {
        id: "sidebar-child-settingsGeneral",
        title: "sidebar.settingsGeneral",
        icon: SettingsOutlinedIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "general",
          component: General
        }
      },
      {
        id: "sidebar-child-settingsStores",
        title: "sidebar.settingsStores",
        icon: StoreIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "stores",
          component: Stores
        }
      },
      {
        id: "sidebar-child-settingsUsers",
        title: "sidebar.settingsUsers",
        icon: PeopleAltIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "users",
          component: Users
        }
      },
      {
        id: "sidebar-child-settingsFeatures",
        title: "sidebar.settingsFeatures",
        icon: FormatListBulletedIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "features",
          component: Features
        }
      },
      {
        id: "sidebar-child-settingsPaymentType",
        title: "sidebar.settingsPaymentType",
        icon: PaymentIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "payment-type",
          component: PaymentType
        }
      },
      {
        id: "sidebar-child-settingsTaxes",
        title: "sidebar.settingsTaxes",
        icon: AccountBalanceIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "taxes",
          component: Taxes
        }
      },
      {
        id: "sidebar-child-settingsEcommerce",
        title: "Settings E-commerce",
        icon: FormatColorTextIcon,
        route: {
          type: RouteType.PRIVATE,
          path: "settingsEcommerce",
          component: SettingsEcommerce
        }
      }
    ]
  }
];
