import React from "react";
import { Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useUIContext } from "lib/context/UIContext/UIContext";
import { useNoDataMessageStyles } from "./useNoDataMessage.";

interface NoDataMessageProps {
  message: string;
  buttonMessage: string;
}

export const NoDataMessage = (props: NoDataMessageProps) => {
  const uiCtx = useUIContext();
  const classes = useNoDataMessageStyles();
  return (
    <div className={classes.root}>
      <div className={"NoDataMessage"}>
        <div className="NoDataMessage__info">
          <InfoIcon className="NoDataMessage__info_logo" color="primary" />
          <p className={"NoDataMessage__info_message"}>{props.message}</p>
        </div>
        <Button
          className={"NoDataMessage__create"}
          variant="contained"
          color="primary"
          onClick={() => {
            uiCtx.toggleModal();
            //   setSelectedModalType("create");
          }}
        >
          {props.buttonMessage}
        </Button>
      </div>
    </div>
  );
};
