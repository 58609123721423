import React from "react";
import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";
import { TaxFields } from "../TaxForm";
interface UseTaxFormOptions {
    initialValues?: TaxFields;
    onSubmit: (
      values: TaxFields,
      formikHelpers: FormikHelpers<TaxFields>
    ) => Promise<any>;
  }

  export const useTaxFormik = (options:UseTaxFormOptions) => {
    const  TaxSchema = Yup.object().shape({
        //TODO: Add validations
      });
      return useFormik({
        initialValues: {
          id: "",
          name: "",
          percentage: "",
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: TaxSchema,
        ...options,
        onSubmit: async (values, formikHelpers) => {
          await options.onSubmit(values, formikHelpers);
        }
      });
}
export type TaxFormik = ReturnType<typeof useTaxFormik>;
