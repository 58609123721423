import { useFormik, FormikHelpers } from "formik";

import * as Yup from "yup";

import { CategoryFields } from "../CategoryForm";

interface UseCategoryFormOptions {
  initialValues?: CategoryFields;
  onSubmit: (
    values: CategoryFields,
    formikHelpers: FormikHelpers<CategoryFields>
  ) => Promise<any>;
}

export const useCategoryFormik = (options: UseCategoryFormOptions) => {
  const CategorySchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
  });

  return useFormik({
    initialValues: {
      name: "",
      slug: "",
      parent_id: null
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CategorySchema,
    ...options,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type CategoryFormik = ReturnType<typeof useCategoryFormik>;
