import React from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { Input } from "components/shared/Input/Input";
import { useTaxStyle } from "./useTaxStyle";

interface TaxProps {
  title: string;
  formik: any;
  serverError: string;
}
export interface TaxFields {
  id: string;
  name: string;
  percentage: string;
}
export const TaxForm = (props: TaxProps) => {
  const classes = useTaxStyle();
  return (
    <div className={classes.Tax}>
      <h1 className={classes.Tax__title}>{props.title}</h1>

      <form className={classes.Tax__form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              className="Tax__nameInput"
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.name}
              error={props.formik.touched.name && !!props.formik.errors.name}
              helperText={props.formik.touched.name && props.formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="tax__percentageInput"
              id="percentage"
              name="percentage"
              label="percentage"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.percentage}
              error={
                props.formik.touched.percentage &&
                !!props.formik.errors.percentage
              }
              helperText={
                props.formik.touched.percentage &&
                props.formik.errors.percentage
              }
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
