import { makeStyles } from "@material-ui/core";
import { Dashboard } from "./Dashboard";

export const useDashBoardStyles = makeStyles((theme) => ({
  Dashboard_h5: {
    color: "#0d1940",
    fontSize: "30px"
  },
  Dashboard_h6: {
    fontSize: "25px",
    margin: "10px"
  },
  CardContent: {
    padding: "0px"
  },
  Dashboard_subtitle: {
    color: "#acb5c3",
    fontSize: "14px",
    fontWeight: "bold"
  },
  Dashboard_Info: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 20px 0px"
  },
  Dashboard_Card: {
    display: "flex",
    justifyContent: "space-between"
  },
  Dashboard_Chart: {},
  Box: {
    margin: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  Card: {
    width: "24%"
  },
  CardMedia: {
    marginBottom: "20px"
  },
  Icon: {
    fontSize: "35px"
  },

  Filter_Box: {
    minWidth: "240px",
    minHeigh: "40px"
  },
  Dashboard__filter: {
    display: "flex"
  }
}));
