import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactQueryDevtools } from "react-query/devtools";

//Partials
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import { useAuthContext } from "./lib/context/AuthContext/AuthContext";

import { RestrictedRoutes } from "./routes/RestrictedRoutes";
import { AuthenticatedRoutes } from "./routes/AuthenticatedRoutes";

//Styles
import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1a3078"
    },
    secondary: {
      main: "#E10600"
    }
  }
});

function App() {
  const history = useHistory();
  const authCtx = useAuthContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    return history.listen((location) => {
      const urlLang = location.pathname.startsWith("/en/") ? "en" : "sq";

      if (urlLang === "en" && i18n.language !== "en") {
        i18n.changeLanguage("en");
      } else if (urlLang === "sq" && i18n.language !== "sq") {
        i18n.changeLanguage("sq");
      }
    });
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          {authCtx.isAuthenticated ? (
            <AuthenticatedRoutes />
          ) : (
            <RestrictedRoutes />
          )}
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
