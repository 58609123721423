import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./LoadingComponent.scss";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadDashboardCart() {
  return (
    <SkeletonTheme highlightColor="#d5dce3" baseColor="white">
      <div className="Skeleton_cart">
        <Skeleton width={"19vw"} height={90} />
        <Skeleton width={"19vw"} height={90} />
        <Skeleton width={"19vw"} height={90} />
        <Skeleton width={"19vw"} height={90} />
      </div>
    </SkeletonTheme>
  );
}
