import React from "react";

import { Grid, TextField } from "@material-ui/core";
import { Input } from "../../shared/Input/Input";
import { useStoreFormStyles } from "./useStoreFormStyles";

interface StoreFormProps {
  title: string;
  formik: any;
  serverError: string;
}

export interface StoreFields {
  name: string;
  address: string;
  phone: string;
  email: string;
  negative_inventory: boolean;
}

export const StoreForm = (props: StoreFormProps) => {
  const classes = useStoreFormStyles();
  return (
    <div className={classes.StoreForm}>
      <h1 className={classes.title}>{props.title}</h1>
      <form
        onSubmit={props.formik.handleSubmit}
        className={classes.StoreForm__form}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              className="StoreForm__nameInput"
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.name}
              error={props.formik.touched.name && !!props.formik.errors.name}
              helperText={props.formik.touched.name && props.formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="StoreForm__addressInput"
              id="address"
              name="address"
              label="Address"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.address}
              error={
                props.formik.touched.address && !!props.formik.errors.address
              }
              helperText={
                props.formik.touched.address && props.formik.errors.address
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="StoreForm__phoneInput"
              id="phone"
              name="phone"
              label="Phone"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.phone}
              error={props.formik.touched.phone && !!props.formik.errors.phone}
              helperText={
                props.formik.touched.phone && props.formik.errors.phone
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              className="StoreForm__emailInput"
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.email}
              error={props.formik.touched.email && !!props.formik.errors.email}
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
            ></Input>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.StoreForm__negativeInventoryInput}
              select
              id="negative_inventory"
              name="negative_inventory"
              label="Negative inventory"
              variant="outlined"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.negative_inventory}
              error={
                props.formik.touched.negative_inventory &&
                !!props.formik.errors.negative_inventory
              }
              helperText={
                props.formik.touched.negative_inventory &&
                props.formik.errors.negative_inventory
              }
              SelectProps={{
                native: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            >
              <option value=""></option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </TextField>
          </Grid>
          <Grid item xs={12} className={classes.ServerError}>
            {props.serverError}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
