import { apiRequest } from "./Api";

export interface Result {
  created_at: Date;
  sum: number;
  date_part: number;
  first_name: string;
  last_name: string;
  amount_total: string;
}

export interface Customer {
  count: string;
}

export interface KPI {
  amount_tax: string;
  amount_total: string;
  amount_untaxed: string;
  avg: string;
}

export interface Rapport {
  kpi: KPI;
  newCostumers: Customer;
  results: {
    sales: Result[];
    hasMore: boolean;
  };
}

export interface RapportResponse {
  raport: Rapport;
}
export interface RapportParams {
  starting_date: string;
  ending_date: string;
  store_id?: string;
  type?: string;
  page?: number;
  rows?: number;
}

export const getSaleRapport = async (data: RapportParams) => {
  return apiRequest<undefined, RapportResponse>(
    "get",
    `api/sale/raports?store_id=${data.store_id}&type=${data.type}&starting_date=${data.starting_date}&ending_date=${data.ending_date}&page=${data.page}&rows=${data.rows}`
  );
};
