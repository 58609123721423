import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography
} from "@material-ui/core";
import { useDashBoardStyles } from "./useDashboardStyle";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import DonutSmallOutlinedIcon from "@material-ui/icons/DonutSmallOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import { KPI } from "api/Dashboard";
import LoadDashboardCart from "components/LoadingComponent/LoadDashboardCart";

interface DashboardCartProps {
  kpi: KPI | undefined;
  newCostumers: string | undefined;
  loading: boolean;
}

export const DashboardCart = (props: DashboardCartProps) => {
  const classes = useDashBoardStyles();
  if (props.loading) return <LoadDashboardCart />;
  if (!props.kpi?.avg) return <p></p>;
  const avg = props.kpi?.avg.toString();
  return (
    <div className={classes.Dashboard_Card}>
      <Card className={classes.Card}>
        <Box className={classes.Box}>
          <CardContent className={classes.CardContent}>
            <Typography variant="h5" className={classes.Dashboard_h5}>
              €{props.kpi?.amount_total}
            </Typography>
            <Typography
              className={classes.Dashboard_subtitle}
              variant="subtitle1"
              id="tableTitle"
            >
              SALES (EX.TAXES)
            </Typography>
          </CardContent>
          <CardMedia className={classes.CardMedia}>
            <PaymentOutlinedIcon className={classes.Icon} color="action" />
          </CardMedia>
        </Box>
      </Card>
      <Card className={classes.Card}>
        <Box className={classes.Box}>
          <CardContent className={classes.CardContent}>
            <Typography variant="h5" className={classes.Dashboard_h5}>
              46.5%
            </Typography>
            <Typography
              className={classes.Dashboard_subtitle}
              variant="subtitle1"
              id="tableTitle"
            >
              GROSS PROFIT
            </Typography>
          </CardContent>
          <CardMedia className={classes.CardMedia}>
            <DonutSmallOutlinedIcon className={classes.Icon} color="action" />
          </CardMedia>
        </Box>
      </Card>
      <Card className={classes.Card}>
        <Box className={classes.Box}>
          <CardContent className={classes.CardContent}>
            <Typography variant="h5" className={classes.Dashboard_h5}>
              {avg ? `${parseFloat(avg).toFixed(2)}%` : ""}
            </Typography>
            <Typography
              className={classes.Dashboard_subtitle}
              variant="subtitle1"
              id="tableTitle"
            >
              AVERAGE SALE VALUE
            </Typography>
          </CardContent>
          <CardMedia className={classes.CardMedia}>
            <ShoppingCartOutlinedIcon className={classes.Icon} color="action" />
          </CardMedia>
        </Box>
      </Card>
      <Card className={classes.Card}>
        <Box className={classes.Box}>
          <CardContent className={classes.CardContent}>
            <Typography variant="h5" className={classes.Dashboard_h5}>
              {props.newCostumers}
            </Typography>
            <Typography
              className={classes.Dashboard_subtitle}
              variant="subtitle1"
              id="tableTitle"
            >
              NEW CUSTOMERS
            </Typography>
          </CardContent>
          <CardMedia className={classes.CardMedia}>
            <PeopleOutlineOutlinedIcon
              className={classes.Icon}
              color="action"
            />
          </CardMedia>
        </Box>
      </Card>
    </div>
  );
};
