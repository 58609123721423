import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  IconButton,
  Menu,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";

import { useRouter } from "../../lib/hooks/useRouter";

export type LanguageType = "sq" | "en";

const removeLangFromURL = (url: string) => {
  return url.replace("/en", "");
};

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = useState(() => i18n.language);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (lang: string | unknown) => {
    if (typeof lang !== "string") {
      return;
    }

    setLanguage(lang);

    const currentLanguage = i18n.language,
      newPrefix = currentLanguage === "sq" ? "/en" : "",
      newPath =
        removeLangFromURL(router.location.pathname) + router.location.search;
    i18n.changeLanguage(currentLanguage === "sq" ? "en" : "sq");
    router.history.push(newPrefix + newPath);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        className="Settings__menu"
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <p className="Settings__menu-title">{t("settings.title")}</p>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-language-native-simple">
            {t("settings.label")}
          </InputLabel>
          <Select
            native
            value={language}
            inputProps={{
              name: "language",
              id: "outlined-language-native-simple"
            }}
            label={t("settings.label")}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value={"sq"}>Shqip</option>
            <option value={"en"}>English</option>
          </Select>
        </FormControl>
      </Menu>
    </>
  );
};
