import React from "react";

import { SidebarRoute } from "../../../routes/SidebarRoutes";
import { SidebarList } from "../SidebarList/SidebarList";

import { Drawer } from "@material-ui/core";

import "./SecondLevelSidebar.scss";

interface Props {
  activeObject?: SidebarRoute;
  setCurrentObjectPath: (id: string) => void;
  returnToFirstLevel: () => void;
}

export const SecondLevelSidebar = (props: Props) => {
  const { activeObject, setCurrentObjectPath, returnToFirstLevel } = props;
  const secondLevel = activeObject?.children.map((x: SidebarRoute) => {
    return {
      ...x,
      route: {
        ...x.route,
        path: activeObject.route.path + "/" + x.route.path
      }
    };
  });

  return (
    <Drawer
      variant="permanent"
      color="secondary"
      className="Sidebar__secondDrawer"
    >
      <SidebarList
        showPrevButton
        list={secondLevel}
        setCurrentObjectPath={(path) => setCurrentObjectPath(path)}
        returnToFirstLevel={returnToFirstLevel}
      />
    </Drawer>
  );
};
