import { makeStyles } from "@material-ui/core";

export const useProductFormStyles = makeStyles({
  ProductForm: {
    width: "100vw"
  },
  ProductForm__form: {
    width: "50%",
    margin: "50px auto"
  },
  title: {
    fontSize: "23px",
    margin: "0 auto 30px",
    width: "fit-content"
  },
  ServerError: {
    color: "red"
  },
  Input: {
    padding: "0 10px !important"
  },
  SelectInput: {
    paddingBottom: "20px",
    width: "100%"
  },
  Switcher: {
    margin: "-7.5px 0 0 -5px"
  },
  OptionalDetails: {
    marginTop: "40px"
  },
  AddUnitButton: {
    marginTop: "-15px",
    marginBottom: "15px"
  },
  DisabledInput: {
    background: "red"
  },
  FileUpload: {
    marginTop: "10px"
  }
});
