import React, { useState } from "react";
import cs from "classnames";
import { DropzoneArea } from "material-ui-dropzone";

import { useTranslation } from "react-i18next";

import "./FileUpload.scss";

interface FileUpload {
  className?: string;
  onFileSelect: (file: any[]) => void;
  onFileDelete: (file: File) => void;
}

//TODO: Fix dropzone text make it display none when img added && fix dropzone position
export const FileUpload = (props: FileUpload) => {
  const { t } = useTranslation();
  const [hasFile, setHasFile] = useState(false);
  // let dropzoneText = t("companySettings.dropzone");
  const [dropzoneText, setDropzoneText] = useState<string>(
    t("companySettings.dropzone")
  );
  const onChangeDrop = (files: File[]) => {
    if (files.length !== 0) {
      setHasFile(true);
      props.onFileSelect(files);
      setDropzoneText("");
      return;
    }
    setDropzoneText(t("companySettings.dropzone"));
    setHasFile(false);
  };

  return (
    <div className="FileUpload test">
      <DropzoneArea
        dropzoneClass={cs(hasFile && "active")}
        acceptedFiles={["image/*"]}
        onChange={onChangeDrop}
        dropzoneText={dropzoneText}
        onDelete={(files) => {
          props.onFileDelete(files);
        }}
        filesLimit={1}
      />
    </div>
  );
};
