import { makeStyles } from "@material-ui/core";

export const useCategoryFormStyles = makeStyles((theme) => ({
  CategoryForm: {
    width: "25vw"
  },
  CategoryForm__form: {
    width: "85%",
    margin: "auto"
  },
  title: {
    fontSize: "23px",
    margin: "0 auto 30px",
    width: "fit-content"
  },
  SelectInput: {
    paddingBottom: "20px",
    width: "100%"
  },
  ServerError: {
    color: "red"
  }
}));
