import { makeStyles } from "@material-ui/core";

export const useCustomerFormStyles = makeStyles((theme) => ({
  CustomerForm: {
    width: "25vw"
  },
  CustomerForm__form: {
    width: "80%",
    margin: "auto"
  },
  title: {
    fontSize: "23px",
    margin: "0 auto 30px",
    width: "fit-content"
  },
  ServerError: {
    color: "red"
  }
}));
