import React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import "./FilterByDate.scss";
const Color = [
  {
    name: "Red",
    value: "123545566"
  },
  {
    name: "Blue",
    value: "123456566"
  },
  {
    name: "Red",
    value: "123455666"
  }
];
export const FilterColor = () => {
  const [age, setAge] = React.useState("");

  const handleOnChange = (e: any) => {
    setAge(e.target.value);
  };
  return (
    <FormControl className="Form-control-3">
      <Select
        value={age}
        onChange={handleOnChange}
        variant="outlined"
        id="store_id"
        name="store_id"
        displayEmpty
        MenuProps={{
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              marginTop: 50
            }
          }
        }}
      >
        <MenuItem value="">Select Color</MenuItem>
        {Color.map((color) => (
          <MenuItem key={color.value} value={color.value}>
            {color.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
