export const paymentColums = [
  {
    key: "name",
    header: "Name"
  },
  {
    key: "type",
    header: "Type"
  },
  {
    key: "actions",
    header: "Actions"
  }
];
